import React, { createContext } from 'react';
import { languages } from '../constants/translations';

export const TranslationContext = createContext((key: string) => key);

type ProviderProps = {
  locale?: string;
  children: any;
};

export const capitalize = (text: string) =>
  text
    .split(' ')
    .map(s => s.charAt(0).toLocaleUpperCase() + s.substring(1))
    .join(' ');

/**
 * Method to create a translationContext so that translations can be requested from anywhere in the project.
 */
export const TranslationProvider = ({
  locale = 'en-US',
  children,
}: ProviderProps) => {
  const highLevel = locale.split('-')[0];

  /**
   * Method to request a string in the selected locale.
   * @param {string} key - a constant that represents a string to check for in the translation files.
   * @returns {string} translatedString - the translated string available in the locale's translation files(defaults to English if none is found)
   */
  const getTranslation = (key: string) => {
    // default to english if translation can't be found
    if (
      !(highLevel in languages) ||
      !(
        languages[highLevel][highLevel].strings[key] ||
        languages[highLevel][locale].strings[key]
      )
    )
      return languages.en.en.strings[key] || languages.en['en-US'].strings[key];

    return (
      languages[highLevel][highLevel].strings[key] ||
      languages[highLevel][locale].strings[key]
    );
  };

  return (
    <TranslationContext.Provider value={getTranslation}>
      {children}
    </TranslationContext.Provider>
  );
};
