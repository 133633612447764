import React, { createContext, useReducer } from 'react';
import { cloneDeep } from 'lodash-es';

import {
  AudiencePickerState,
  audienceTargetingReducer,
  audiencePickerInitialState,
} from '../state/audienceTargetingReducer';
import {
  addAudienceGroupAction,
  addAudienceToGroupAction,
  removeAudienceFromGroupAction,
  removeAudienceGroupAction,
  selectAudienceGroupAction,
  changeIntersetAction,
  changeIntrasetAction,
  bulkAddAudienceToGroupAction,
} from '../state/actions';
import {
  Audience,
  AudienceGroupIndex,
  SegmentGroupIndex,
  TargetingGroupIndex,
} from '../models';
import { ThemeProvider } from '@amzn/storm-ui';
import { useTheme } from 'styled-components';
import { AudienceGroup, TargetingGroup } from '../models/AudienceTargeting';
import { convertAudienceObjectToInclude } from '../utils/audienceTargetingConverter';

export interface IAudienceTargetingContext {
  addAudienceToGroup: (audience: Audience) => void;
  bulkAddAudienceToGroup: (audience: Array<Audience>) => void;
  removeAudienceFromGroup: (segmentToRemove: SegmentGroupIndex) => void;
  addAudienceGroup: (indexToAddGroup: TargetingGroupIndex) => void;
  removeAudienceGroup: (indexToRemoveGroup: AudienceGroupIndex) => void;
  selectAudienceGroup: (indexToSelectGroup: AudienceGroupIndex) => void;
  intersetOperator: (
    term: string,
    audienceToUpdate: AudienceGroupIndex
  ) => void;
  intrasetOperator: (
    term: string,
    audienceToUpdate: AudienceGroupIndex
  ) => void;
  selectedAudienceGroup: AudienceGroup;
  state: AudiencePickerState;
}

export const AudienceTargetingContext = createContext({});

type ProviderProps = {
  initialState?: AudiencePickerState;
  children: any;
};

export const AudienceTargetingProvider = ({
  initialState = audiencePickerInitialState,
  children,
}: ProviderProps) => {
  const [state, dispatch] = useReducer(audienceTargetingReducer, initialState);
  const targetingGroup: TargetingGroup =
    state.audienceTargeting.targetingGroups[
      state.selectedGroup.targetingGroupIndex
    ];
  const targetingTypeGroup: AudienceGroup[] =
    targetingGroup[state.selectedGroup.targetingGroupType];
  const selectedAudienceGroup: AudienceGroup =
    targetingTypeGroup[state.selectedGroup.audienceGroupIndex];

  const addAudienceToGroup = (audience: Audience) => {
    const audienceClone: any = cloneDeep(audience);
    convertAudienceObjectToInclude(audienceClone);
    dispatch(addAudienceToGroupAction(audienceClone));
  };

  const bulkAddAudienceToGroup = (audienceList: Array<Audience>) => {
    const audienceListClone = cloneDeep(audienceList);
    audienceListClone.forEach((audience: Audience) => {
      convertAudienceObjectToInclude(audience);
    });

    dispatch(bulkAddAudienceToGroupAction(audienceListClone));
  };

  const removeAudienceFromGroup = (segmentToRemove: SegmentGroupIndex) => {
    dispatch(removeAudienceFromGroupAction(segmentToRemove));
  };

  const addAudienceGroup = (indexToAddGroup: TargetingGroupIndex) => {
    dispatch(addAudienceGroupAction(indexToAddGroup));
  };

  const removeAudienceGroup = (indexToRemoveGroup: AudienceGroupIndex) => {
    dispatch(removeAudienceGroupAction(indexToRemoveGroup));
  };

  const selectAudienceGroup = (indexToSelectGroup: AudienceGroupIndex) => {
    dispatch(selectAudienceGroupAction(indexToSelectGroup));
  };

  const intersetOperator = (
    term: string,
    audienceToUpdate: AudienceGroupIndex
  ) => {
    dispatch(changeIntersetAction(term, audienceToUpdate));
  };

  const intrasetOperator = (
    term: string,
    audienceToUpdate: AudienceGroupIndex
  ) => {
    dispatch(changeIntrasetAction(term, audienceToUpdate));
  };

  const value: IAudienceTargetingContext = {
    addAudienceGroup,
    removeAudienceGroup,
    addAudienceToGroup,
    bulkAddAudienceToGroup,
    removeAudienceFromGroup,
    selectAudienceGroup,
    intersetOperator,
    intrasetOperator,
    selectedAudienceGroup,
    state,
  };

  const theme = useTheme();

  return (
    <>
      {/* @ts-ignore */}
      <ThemeProvider theme={theme}>
        <AudienceTargetingContext.Provider value={value}>
          {children}
        </AudienceTargetingContext.Provider>
      </ThemeProvider>
    </>
  );
};
