import { omit } from 'lodash-es';
import { TypedPatch, MFEBootstrap } from '@mfe/state-management';
import {
  LineItemPageState,
  LineItemProposalPageState,
  PageType,
} from 'src/model/LineItemPageState';
import useLineItemProposalPageState from './useLineItemProposalPageState';

const LINE_ITEM_PATCH_BASE_PATH = 'lineItemV1';
const PROPOSAL_PATCH_BASE_PATH = 'proposalV1';

export type StatePatchApplier = (
  patches: TypedPatch.TypedPatch<LineItemPageState>[]
) => void;

/**
 * Helper function for getting the page type from the page state.
 * @param lineItemProposalPageState page state for the line item/proposal create/edit pages
 * @returns LINE_ITEM if on a line item create/edit page, PROPOSAL
 * if on a proposal create/edit page, or UNKNOWN if unable
 * to determine.
 */
function getPageType(
  lineItemProposalPageState: LineItemProposalPageState
): PageType {
  if (lineItemProposalPageState.lineItemV1) return PageType.LINE_ITEM;
  else if (lineItemProposalPageState.proposalV1) return PageType.PROPOSAL;

  console.warn('No page type found');
  return PageType.UNKNOWN;
}

/**
 * Custom React hook to expose an abstracted line item page state for reads
 * and writes. It abstracts the line item vs. proposal page logic by
 * exposing a single line item model via the lineItemV1 property of
 * the LineItemPageState. Consumers of this hook can read and write to the
 * lineItemV1 property knowing that this hook will represent that data
 * as either the line item or proposal model, depending on which
 * page type this hook is used on.
 *
 * At its core, this hook is a wrapper around the useLineItemProposalPageState
 * hook, where all line item vs. proposal data is abstracted via a single
 * lineItemV1 page state property.
 * @param pageState MFE page state that stores line item/proposal page data
 * @param subscribeToUpdates Flag on whether to subscribe the returned LineItemPageState
 * to updates made to the page state
 * @returns An array of size 2 with the object types at the following array indices:
 * 0: LineItemPageState - a read-only object that can optionally be subscribed
 * to updates to the page state. If on a proposal page, the lineItemV1 property
 * will contain the proposal data.
 * 1: StatePatchApplier - a function that updates the page state via patches.
 * If on a proposal page, patches to the lineItemV1 property will be dispatched
 * to the underlying proposal model.
 */
function useAbstractedLineItemPageState(
  pageState: MFEBootstrap.PageState<LineItemProposalPageState>,
  subscribeToUpdates?: boolean
): [LineItemPageState, StatePatchApplier] {
  const [globalState, applyStatePatches] = useLineItemProposalPageState(
    pageState,
    subscribeToUpdates
  );
  const pageType: PageType = getPageType(globalState);
  const abstractedLineItemPageState: LineItemPageState = {
    ...globalState,
    lineItemV1: globalState.lineItemV1 || globalState.proposalV1,
    pageType,
  };

  // Note - this should be the ONLY location in this package where we manipulate the
  // path of a TypedPatch directly. It is not advised to do this in general, but is
  // necessary to do so here in order to abstract line item vs. proposal patches
  const applyAbstractedStatePatches = (
    patches: TypedPatch.TypedPatch<LineItemPageState>[]
  ): void => {
    if (pageType === PageType.PROPOSAL)
      patches.forEach((patch) => {
        if (patch.path && patch.path.includes(LINE_ITEM_PATCH_BASE_PATH))
          patch.path = patch.path.replace(
            LINE_ITEM_PATCH_BASE_PATH,
            PROPOSAL_PATCH_BASE_PATH
          );
      });

    applyStatePatches(patches);
  };

  return [
    // @ts-ignore
    omit(abstractedLineItemPageState, ['proposalV1', 'proposalValidationV1']),
    applyAbstractedStatePatches,
  ];
}

export default useAbstractedLineItemPageState;
