import { TranslatorString } from 'src/Localization/LocalizationConfig';
import {
  AGE_AND_GENDER_1P_SUB_CATEGORIES,
  CONTEXTUAL_CATEGORIES,
  DEMOGRAPHIC_1P_CATEGORY,
  IN_MARKET_LIFESTYLE_1P_CATEGORIES,
  THIRD_PARTY_CATEGORIES,
} from 'src/utils/MFEConstants';
import { Audience } from '@amzn/d16g-audience-picker-component';

export const disable3PForKindle = (data: any) => {
  if (
    THIRD_PARTY_CATEGORIES.includes(data.category) ||
    CONTEXTUAL_CATEGORIES.includes(data.category)
  )
    return TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_AUDIENCE_CANNOT_BE_SELECTED;
};

/**
 * Get the disable message for Age and Gender first-party Demographic audiences.
 * @param audience the audience to check
 * @returns the disable message if the audience is age or gender first-party demographic and undefined otherwise.
 */
export const disableDemographicForVideoAmazonDeal = (audience: Audience) => {
  if (
    DEMOGRAPHIC_1P_CATEGORY === audience.category &&
    AGE_AND_GENDER_1P_SUB_CATEGORIES.includes(audience.subCategory)
  )
    return TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_AMAZON_DEMOGRAPHIC_AUDIENCE_CANNOT_BE_SELECTED;
};
/**
 * Get the disable message for in-market/lifestyle first-party Demographic audiences.
 * @param audience the audience to check
 * @returns the disable message if the audience is in-market, lifestyle, interest, or life event first-party demographic and undefined otherwise.
 */
export const disableInMktLifestyleForVideoAmazonDeal = (audience: Audience) => {
  if (IN_MARKET_LIFESTYLE_1P_CATEGORIES.includes(audience.category))
    return TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_AMAZON_IN_MARKET_LIFESTYLE_AUDIENCE_CANNOT_BE_SELECTED;
};
