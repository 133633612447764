import { FeeState } from "src/store/FeeStore";
import { AudienceDisplayFee, ThirdPartyAudienceDisplayFee } from "src/model";

/**
 * Retrieves all audiences from the Fee store.
 *
 * @param {Object} state - The state of the entire store
 * @returns {Map} A Map containing all audiences from both audienceFeeIndex and thirdPartyAudienceFeeIndex.
 */
export function retrieveAllAudiences(state: FeeState): Map<string, AudienceDisplayFee> {
    const audienceFeeIndex = state?.audienceFeeIndex || [];
    const thirdPartyAudienceFeeIndex = (state?.thirdPartyAudienceFeeIndex || []) as Map<string, AudienceDisplayFee>;
    return new Map([...audienceFeeIndex, ...thirdPartyAudienceFeeIndex]);
}

/* Actions */

/**
 * Invalidates all fees in the Fee store
 *
 * @param {Object} state - The state of the entire store
 */
export function invalidateAllFees(state) {
    state.invalidateAudienceIndex();
    state.invalidateTechnologyFee();
    state.invalidatePerformancePlusFee();
    state.invalidateContextualFees();
}

/**
 * Set loading state for all fees in the Fee store
 *
 * @param {Object} state - The state of the entire store
 */
export function setLoadingStateForAllFees(state, isLoading: boolean) {
    state.setAudienceFeeLoadingState(isLoading);
    state.setTechnologyFeeLoadingState(isLoading);
    state.setPerformancePlusFeeLoadingState(isLoading);
    state.setContextualFeesLoadingState(isLoading);
}