export enum LineItemTypes {
    STANDARD_DISPLAY = 'AAP_CPM',
    MOBILE_APP = 'AAP_MOBILE_APP',
    MOBILE_WEB = 'AAP_MOBILE_WEB',
    VIDEO = 'AAP_VIDEO_CPM',
    OTT_VIDEO = 'OTT_VIDEO',
    AUDIO = 'AAP_AUDIO_CPM',
    AUDIO_GUARANTEED = 'AUDIO_GUARANTEED',
    PODCAST = 'PODCAST_NONGUARANTEED',
    DIGITAL_OUT_OF_HOME = 'DIGITAL_OUT_OF_HOME',
    DISPLAY = 'AAP_DISPLAY_CPM',
}