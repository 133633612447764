import styled from 'styled-components';
import { defaultTheme } from '@amzn/storm-ui';

const AudienceSummaryContainer = styled.div`
  padding: 10px 20px;
  border-bottom: 1px solid ${defaultTheme.palette.gray[100]};
  font-family: Amazon Ember;
  font-size: 13px;
`;

const AudienceSummaryContainerNoBorder = styled.div`
  padding: 10px 20px 0;
  font-family: Amazon Ember;
  font-size: 13px;
`;

const LogicalOperator = styled.span`
  background-color: ${defaultTheme.palette.mercury};
  padding: 2px 8px;
  margin-top: 10px;
  border-radius: 8px;
  display: inline-block;
`;

const AudienceSummaryTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: centre;
  padding-bottom: 10px;
`;

export const Styled = {
  LogicalOperator,
  AudienceSummaryContainer,
  AudienceSummaryTitle,
  AudienceSummaryContainerNoBorder,
};
