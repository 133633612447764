import axios, { AxiosError, AxiosInstance } from 'axios';
import { AudienceSegmentsResponse } from "src/model";
import { PricingApiData } from "src/model/ApiData.model";
import {PricingApiHelper} from "src/utils/PricingApiHelper";

export interface FilterResponse {
  category: string;
  audienceCount: number;
}

export const AD_TYPE = 'DSP';
export const RETRY_INTERVAL = 3000;
export const REQUEST_TIMEOUT = 30000;

export const constructHeaders = (apiData: PricingApiData) => {
  const { clientId, csrfToken, entityId, marketplaceId } = apiData;
  return {
    'amazon-advertising-api-clientid': clientId,
    'amazon-advertising-api-csrf-data': clientId,
    'amazon-advertising-api-csrf-token': csrfToken,
    'amazon-advertising-api-advertiserid': entityId,
    'amazon-advertising-api-marketplaceid': marketplaceId,
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Origin': '*',
    'x-amz-request-csrf-token': true,
    accept: '*/*',
  };
};

/**
 * The client holds the front-end calls to the respective API endpoints
 * @param {ApiData}  apiData
 */
export class AudienceDataClient {
  private readonly client: AxiosInstance;

  private static readonly MAX_RESULTS: number = 250;

  public advertiserId: string;
  public headers: {};
  public endpoint: string;

  public constructor(apiData: PricingApiData) {
    const {
      advertiserId,
      baseURL,
      endpoint = 'audiences/list',
      headers,
    } = apiData;
    this.advertiserId = advertiserId || "";
    this.headers = headers || constructHeaders(apiData);
    this.endpoint = endpoint;
    this.client = axios.create({
      baseURL,
      withCredentials: true,
      timeout: REQUEST_TIMEOUT,
    });
  }

  /**
   * API endpoint to request audiences to populate the table.
   * @param {Array<string>} audienceId - list of audiences to fetch
   * @param {string} nextToken - response from the API with a token to know to request the next page of data
   * @returns {AudienceSegmentsResponse} audienceSegmentsResponse
   */
  public async getAudienceById(
    audienceId: Array<string>,
    nextToken?: string
  ): Promise<AudienceSegmentsResponse> {
    try {
      // on rejected call, use retry logic:
      this.client?.interceptors?.response?.use(undefined, err => {
        const { config, response } = err;
        if (!config || !config.retries) return Promise.reject(err);
        // retry on status 429
        if (!(response?.status == 429)) return Promise.reject(err);

        config.retries -= 1;
        const delayRetryRequest = new Promise<void>(resolve => {
          setTimeout(() => {
            console.error(`Fetching audience for audienceId ${audienceId}`);
            resolve();
          }, RETRY_INTERVAL);
        });
        return delayRetryRequest.then(() => this.client(config));
      });

      const response: any = await this.client
        .post<AudienceSegmentsResponse>(
          this.endpoint,
          {
            adType: AD_TYPE,
            filters: [{ field: 'audienceId', values: audienceId }],
          },
          {
            // @ts-ignore
            retries: 3,
            headers: this.headers,
            params: {
              advertiserId: this.advertiserId,
              nextToken,
              maxResults: AudienceDataClient.MAX_RESULTS,
              canTarget: true,
            },
          }
        )
        .then(resp => resp.data);

      if (response.audiences) {
        try {
          PricingApiHelper.getInstance()?.getFeeStore()?.getState()?.observeAudiencesForPricing(response.audiences);
        } catch {
          console.error("Pricing Api Helper is not initialized");
        }
      }

      return {
        audiences: response.audiences,
        matchCount: response.matchCount,
        nextToken: response.nextToken,
      };
    } catch (e) {
      const error = e as AxiosError<any>;
      if (error && error.toJSON) console.error(error.toJSON());
      return { audiences: [], matchCount: 0, nextToken: "", isFail: true };
    }
  }
}
