import { AudienceTargetingGroup } from 'src/MFE/lineItem/model/AudienceTargetingGroup';

let isAudienceIncompatible: boolean | undefined;
let initialState: AudienceTargetingGroup[];

export function setIsAudienceIncompatible(value: boolean) {
  isAudienceIncompatible = value;
}
export function getIsAudienceIncompatible(): boolean {
  return isAudienceIncompatible;
}
export function setInitialState(value: AudienceTargetingGroup[]) {
  initialState = value;
}
export function getInitialState(): AudienceTargetingGroup[] {
  return initialState;
}
