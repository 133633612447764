import React, { useContext } from 'react';
import { AudienceSummary } from '../../AudienceSummary/AudienceSummary';
import { NEW_AUDIENCE } from '../../../constants/translations';
import { SummaryViewIcon } from '@amzn/d16g-rodeo-website-shared-components';
import { TranslationContext } from '../../../state/translationContext';
import { SummaryViewSectionTitle } from '../../../constants/audience';
import { IAudienceTargetingContext } from '../../../state/audienceTargetingContext';
import { useAudienceTargeting } from '../../../state/useAudienceTargeting';

export const NewAudience = () => {
  const getTranslation = useContext(TranslationContext);
  const TRANS_NEW_AUDIENCE = getTranslation(NEW_AUDIENCE);
  const { state }: IAudienceTargetingContext = useAudienceTargeting();

  return (
    <div>
      {state?.audienceTargeting?.targetingGroups[0]?.include.length && (
        <AudienceSummary
          audienceSegments={state.audienceTargeting.targetingGroups[0].include}
          title={TRANS_NEW_AUDIENCE}
          sectionTitle={SummaryViewSectionTitle.INCLUDING}
          categories={[]}
          id="new_include_audience"
          icon={SummaryViewIcon.ACCEPTED}
          expandable={false}
          border={false}
        />
      )}
      {state?.audienceTargeting?.targetingGroups[0]?.exclude.length && (
        <AudienceSummary
          audienceSegments={
            state?.audienceTargeting?.targetingGroups[0]?.exclude
          }
          sectionTitle={SummaryViewSectionTitle.EXCLUDING}
          categories={[]}
          id="new_exclude_audience"
          icon={SummaryViewIcon.REJECTED}
          expandable={false}
          border={false}
        />
      )}
    </div>
  );
};
