import KatalLogger from '@amzn/katal-logger';
import {
  BETA_IDENTIFIER,
  GAMMA_IDENTIFIER,
  JP_IDENTIFIER,
  NA_IDENTIFIER,
  PROD_IDENTIFIER,
} from 'src/constants/url-identification';
import {
  ENV_BETA,
  ENV_GAMMA,
  ENV_PROD,
  ENV_DEV,
} from 'src/constants/environments';
import { REGION_EU, REGION_JP, REGION_NA } from 'src/constants/regions';
import { LOGGER_ENDPOINT_MAP } from 'src/constants/endpoints';

export const getKatalLogger = () => {
  const url: string = window.location.href;
  let environment: string;
  let region: string;

  switch (true) {
    case url.includes(BETA_IDENTIFIER):
      environment = ENV_BETA;
      break;
    case url.includes(GAMMA_IDENTIFIER):
      environment = ENV_GAMMA;
      break;
    case url.includes(PROD_IDENTIFIER):
      environment = ENV_PROD;
      break;
    default:
      environment = ENV_DEV;
  }

  const urlMatch = (element: string) => url.includes(element);
  switch (true) {
    case JP_IDENTIFIER.some(urlMatch):
      region = REGION_JP;
      break;
    case NA_IDENTIFIER.some(urlMatch):
      region = REGION_NA;
      break;
    default:
      region = REGION_EU;
  }

  return new KatalLogger({
    url: LOGGER_ENDPOINT_MAP[region][environment],
  });
};
