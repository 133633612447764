import { Text } from '@amzn/storm-ui';
import styled from 'styled-components';

export const TargetedAudienceSummaryContainer = styled.div`
  padding: 0 20px 10px;
  border-bottom: 1px solid #e7e7e7;
`;

export const SummaryText = styled(Text)`
  padding-bottom: 7px;
`;

export const SizeContents = styled.div`
  color: #232f3f;
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
`;
