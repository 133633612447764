import React from 'react';
import styled from 'styled-components';
import { Text } from '@amzn/storm-ui';
import { AudienceOperatorEnum } from '../../constants/audience';
import { Audience as AudienceType } from 'models/Audience';

interface AudienceProps {
  audience: AudienceType;
  isNotFirstSegment: boolean;
  intrasetOperator: 'OR' | 'AND';
}

const AudienceRow = styled.div`
  padding-top: 3px;
`;
const BoldText = styled.span`
  font-weight: 600;
`;

function Audience({
  audience,
  isNotFirstSegment,
  intrasetOperator,
}: AudienceProps): JSX.Element {
  const Operator = () => (
    <BoldText> {AudienceOperatorEnum[intrasetOperator]} </BoldText>
  );

  const Not = (isNot: boolean | undefined) => {
    if (isNot) return <BoldText>not </BoldText>;
    return <></>;
  };

  const GroupInfo = () => (
    <AudienceRow key={audience.audienceId}>
      <Text>
        {isNotFirstSegment ? Operator() : ''}
        {Not(audience.not)}
        {audience.audienceName}
      </Text>
    </AudienceRow>
  );

  return <GroupInfo />;
}

export default Audience;
