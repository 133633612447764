import styled from 'styled-components';
import { Card, TextButton } from '@amzn/storm-ui';

export const PredicateGroupNodeContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 11px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;

export const RemoveIcon = styled(TextButton)`
  color: gray;
  &:hover {
    color: black;
  }
`;

export const NodeList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const PredicateGroupPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  height: 100%;
`;

export const PredicateCardsContainer = styled.div`
  overflow: hidden auto;
  flex-grow: 1;
  height: 100%;
`;

export const PredicateCardsList = styled.div`
  padding: 0px 15px 0 15px;
  height: 100%;
  max-height: 75vh;
`;

export const PredicateGroupCard = styled(Card)<{
  isSelected: boolean;
  onClick: () => void;
}>`
  ${({ isSelected }) =>
    isSelected &&
    ` 
    box-shadow: inset 0 0 0 3px rgb(50, 98, 149);
   `}
  background-color: ${({ isSelected }) =>
    isSelected ? 'rgb(234, 243, 254)' : '#F6F6F6'};
  margin-top: 18px;
  display: block;
  border-radius: 8px;
  min-height: 200px;
  height: fit-content;
  cursor: pointer;
`;

export const PredicateGroupTitleStyle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const EmptyGroupMessageContainer = styled.div`
  font-style: oblique;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
