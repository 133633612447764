import React, { ReactElement } from 'react';
import { Button, TextButton } from '@amzn/storm-ui';
import { FooterProps } from 'src/model/ConfirmationModal';
import { LocalizedText } from 'src/Localization/LocalizationText';

const ModalFooter = ({
  cancelButtonTranslatorString,
  onCancel,
  confirmButtonTranslatorString,
  onConfirm,
  testId,
}: FooterProps): ReactElement => (
  <>
    <TextButton onClick={onCancel} data-test-id={`${testId}-cancel-btn`}>
      <LocalizedText translatorString={cancelButtonTranslatorString} />
    </TextButton>
    <Button onClick={onConfirm} primary data-test-id={`${testId}-confirm-btn`}>
      <LocalizedText translatorString={confirmButtonTranslatorString} />
    </Button>
  </>
);

export default ModalFooter;
