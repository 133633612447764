import { Fee } from "src/model";
import { EMPTY_FEE } from "src/constants";

export interface PerformancePlusProps {
    performancePlusFee: Fee | null | undefined
}

export interface PerformancePlusState extends PerformancePlusProps {
    setPerformancePlusFee: (performancePlusFee: Fee) => void
    invalidatePerformancePlusFee: () => void,
    setPerformancePlusFeeLoadingState: (isLoading: boolean) => void
}

export const createPerformancePlusSlice = (set): PerformancePlusState => ({
    performancePlusFee: null,
    setPerformancePlusFee: (performancePlusFee: Fee) => set({performancePlusFee: performancePlusFee}),
    invalidatePerformancePlusFee: () => set({performancePlusFee: EMPTY_FEE}),
    setPerformancePlusFeeLoadingState: (isLoading: boolean) =>
        set((state) => ({performancePlusFee: {...state.performancePlusFee, isLoading}})),
})