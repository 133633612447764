import {
    EMPTY_FEE,
    LineItemTypes,
    THIRD_PARTY_CATEGORIES
} from "src/constants";
import {
    FeeTypes,
    getImpressionSupplyType,
    hasPVASource,
    hasSTVSources,
} from "src/utils/supplyTypeUtils";
import {
    AudienceGroup,
    CustomElementSegment,
    AudienceDisplayFee,
    LineItemProposalPageState,
    Fee
} from "src/model";
import {createFeeStore, FeeProps, FeeStore} from "src/store/FeeStore";
import {
    PricingEngineFeeCategory,
    PricingEngineSupplySourceType,
    PricingEngineDeviceType,
    PricingEnginePlatform,
    PricingEngineScenariosMediaType,
    PricingEngineSegmentCategory,
    PricingEngineContextualTactic,
    PricingEnginePerformancePlusTactic,
} from "@amzn/d16g-pricing-engine-api-type-script-client/dist-cjs/models";
import {
    PricingEngineScenarioAudience,
    ItemToPriceV2,
    PricingEngineComputeScenariosInput,
    PricingEngineDeal,
    PricingEngineComputeScenariosOutput,
} from "@amzn/d16g-pricing-engine-api-type-script-client/dist-types/models";
import { ApiData, Audience } from "@amzn/d16g-pricing-shared-client-library";
import { ClientV2Cached } from "@amzn/d16g-pricing-shared-client-library/dist/src/api/ClientV2Cached";
import { COMBINED_AUDIENCES_CATEGORY } from "src/constants/combined-audiences";
import { CombinedAudienceClient } from "src/api/CombinedAudienceClient";
import { PricingApiData } from "src/model/ApiData.model";
import { getCombinedAudienceDisplayFee } from "src/utils/AudienceFeeUtil";
import { invalidateAllFees, retrieveAllAudiences, setLoadingStateForAllFees } from "src/store/selectors/selectors";
import { Deal } from "@amzn/d16g-state-management-interfaces/dist/v1/LineItem";
import { dealTypeToPengDealType, hashString } from "src/utils/helpers";

const ITEMS_TO_PRICE_CHUNK_SIZE = 3000;

interface ActivePricingRequest {
    promise: Promise<PricingEngineComputeScenariosOutput>,
    abortController: AbortController
}

/**
 * A helper class for managing pricing-related API functionality
 * whose main function is to assist in building API requests
 * and store fees from the pricing api response.
 */
export class PricingApiHelper {
    private activePricingRequests: ActivePricingRequest[];
    private client: ClientV2Cached;
    private combinedAudienceClient: CombinedAudienceClient
    private feeStore: FeeStore;
    private apiData: PricingApiData;
    private lastRequestCached: string | undefined;

    private static instance: PricingApiHelper | null;

    /**
     * Initializes the PricingApiHelper singleton instance.
     * @param {ApiData} apiData - The API data required for initialization.
     * @param {FeeProps} initialFeeState - The initial state of the fee store
     */
    public static initialize(apiData: PricingApiData, initialFeeState?: Partial<FeeProps>) {
        if (!this.instance) {
            this.instance = new PricingApiHelper(apiData, initialFeeState);
        }
    }

    /**
     * Retrieves the singleton instance of PricingApiHelper.
     * @returns {PricingApiHelper} The singleton instance of PricingApiHelper.
     * @throws {Error} If the PricingApiHelper instance has not been initialized.
     */
    public static getInstance(): PricingApiHelper {
        if (!this.instance) {
            throw new Error(
                'Singleton class (PricingApiHelper) has not been initialized. Call initialize() first.'
            );
        }
        return this.instance;
    }

    /**
     * Retrieves the fee store from the instance of PricingApiHelper.
     * @returns {FeeStore} The singleton instance of PricingApiHelper.
     */
    public getFeeStore(): FeeStore {
        return this.feeStore;
    }

    /**
     * Resets the singleton instance of PricingApiHelper to null.
     * Used mostly for testing purposes.
     */
    public static reset(): void {
        this.instance = null;
    }

    /**
     * Private constructor for the PricingApiHelper class.
     * @param {ApiData} apiData - The API data required for initialization.
     * @param {FeeProps} initialFeeState - The fee state to initialize the store with
     */
    private constructor(apiData: PricingApiData, initialFeeState?: Partial<FeeProps>) {
        this.activePricingRequests = [];
        ClientV2Cached.initialize(apiData as ApiData);
        CombinedAudienceClient.initialize(apiData);
        this.client = ClientV2Cached.getInstance();
        this.combinedAudienceClient = CombinedAudienceClient.getInstance();
        this.feeStore = createFeeStore(initialFeeState);
        this.apiData = apiData;
    }

    /**
     * Requests pricing information for the given page state.
     *
     * @param {LineItemProposalPageState} pageState - The page state for which to request pricing.
     * @throws {Error} If the `pageState` parameter is invalid or if the `feeStore` property is not set.
     * @returns {void}
     */
    public requestPricingForLineItemPageState(pageState: LineItemProposalPageState): void {
        if (!pageState || !(pageState?.lineItemV1 || pageState?.proposalV1) || !this.feeStore) {
            throw new Error('Invalid page state passed to PricingApiHelper -- buildPricingRequestBodyFromPageState');
        }

        // build the request bodies for pricing
        const pricingRequests = this.buildPricingRequestBodyFromPageState(pageState);

        this.requestPricing(pricingRequests, pageState);
    }

    /**
     * Requests pricing information for the given page state.
     *
     * @param {any} pageState - The page state for which to request pricing.
     * @throws {Error} If the `pageState` parameter is invalid or if the `feeStore` property is not set.
     * @returns {void}
     */
    public requestPricingForBulkEditPageState(pageState: any): void {
        if (!pageState || !(pageState?.orderLineItemBulkEditState) || !this.feeStore) {
            throw new Error('Invalid page state passed to PricingApiHelper -- buildPricingRequestBodyFromPageState');
        }

        // build the request bodies for pricing
        const pricingRequests = this.buildPricingRequestBodyForBulkEdit();

        this.requestPricing(pricingRequests, pageState);
    }


    /**
     * Updates the fee state with the pricing response.
     * @param {LineItemProposalPageState} pageState - The page state for which to request pricing.
     * @param {PricingEngineComputeScenariosOutput[]} pricingRequests - An array of pricing engine compute scenarios output objects.
     * @returns {void}
     */
    public requestPricing(pricingRequests, pageState): void {
        if (!this.updateLastPricingRequestCache(pricingRequests)) {
            // If the current request and the last request are the same then do nothing.
            return;
        }

        // invalidate if the price requests are different from that last request
        invalidateAllFees(this.feeStore.getState());

        // aborting active requests
        if (this.activePricingRequests?.length > 0) {
            this.activePricingRequests.forEach((activePricingRequest) => {
                activePricingRequest.abortController.abort();
            });
        }

        // Make pricing requests
        this.activePricingRequests = pricingRequests.map((pricingRequest) => {
            const abortController = new AbortController;
            return {
                promise: this.client.getPricingResponse(pricingRequest, abortController.signal),
                abortController: abortController
            };
        });

        // handle pricing response
        Promise.all(this.activePricingRequests.map((request) => request.promise))
            .then((pricingOutputs) => {
                this.updateFeeStateWithPricingResponse(pricingOutputs);
                // clear the active pricing requests
                this.activePricingRequests = [];
            })
            .catch((error) => {
                console.error(error);
                // clear the active pricing requests
                this.activePricingRequests = [];
                // set the loading state to false for all requested fees
                setLoadingStateForAllFees(this.feeStore.getState(), false);
            });

        const currentImpressionSupplyType = getImpressionSupplyType(pageState?.lineItemV1);
        if (currentImpressionSupplyType !== this.feeStore.getState().thirdPartyImpressionFeeSupplyType) {
            this.feeStore.getState().updateThirdPartyImpressionSupplyType(currentImpressionSupplyType ?? FeeTypes.WEB);
        }
    }

    /**
     * Updates the fee state with the pricing response.
     *
     * @param {PricingEngineComputeScenariosOutput[]} pricingResponses - An array of pricing engine compute scenarios output objects.
     * @param {FeeState} feeState - The fee state object.
     * @returns {void}
     */
    public updateFeeStateWithPricingResponse(pricingResponses: PricingEngineComputeScenariosOutput[]): void {
        let audienceDisplayFees = new Map<string, AudienceDisplayFee>();
        let combinedAudiences: PricingEngineScenarioAudience[] = [];
        let performancePlusFee: Fee;
        let techDisplayFee: Fee;

        pricingResponses?.forEach((pricingResponse) => {
            pricingResponse?.pricingScenarios?.forEach((pricingScenario) => {
                if (pricingScenario?.feeCategory === PricingEngineFeeCategory.AUDIENCE_1P) {
                    if (THIRD_PARTY_CATEGORIES.includes(pricingScenario?.audience?.category || "")) {
                        console.error("Third party audience fee found in pricing response.");
                    }
                    if (pricingScenario?.audience?.category === COMBINED_AUDIENCES_CATEGORY) {
                        combinedAudiences.push(pricingScenario.audience);
                    }
                    if (pricingScenario?.audience?.category === PricingEngineSegmentCategory.PERFORMANCE_PLUS) {
                        performancePlusFee = {
                            value: pricingScenario?.priceRange?.max?.amount || null,
                            currency: pricingScenario?.priceRange?.max?.currency || null,
                            range: pricingScenario?.priceRange || null,
                            isLoading: false,
                        }
                    }
                    if (pricingScenario?.audience?.id) {
                        const audienceDisplayFee: AudienceDisplayFee = {
                            value: pricingScenario?.priceRange?.max?.amount || null,
                            currency: pricingScenario?.priceRange?.max?.currency || null,
                            range: pricingScenario?.priceRange || null,
                            isLoading: false,
                            audience: pricingScenario.audience
                        }

                        audienceDisplayFees.set(pricingScenario.audience.id, audienceDisplayFee);
                    }
                }

                if (pricingScenario?.feeCategory === PricingEngineFeeCategory.TECH) {
                    if (techDisplayFee) {
                        console.error("More than one TECH fee found in pricing response.")
                        return;
                    }
                    techDisplayFee = {
                        value: pricingScenario?.priceRange?.max?.amount || null,
                        currency: pricingScenario?.priceRange?.max?.currency || null,
                        range: pricingScenario?.priceRange || null,
                        isLoading: false,
                    }
                }

                if (pricingScenario?.feeCategory === PricingEngineFeeCategory.CONTEXTUAL) {
                    const contextualFee = {
                        value: pricingScenario?.priceRange?.max?.amount || null,
                        currency: pricingScenario?.priceRange?.max?.currency || null,
                        range: pricingScenario?.priceRange || null,
                        isLoading: false,
                    };

                    switch(pricingScenario?.contextual?.tactic) {
                        case PricingEngineContextualTactic.CATEGORY:
                            this.feeStore.getState().setContextualCategoryFee(contextualFee);
                            break;
                        case PricingEngineContextualTactic.ENTERTAINMENT:
                            this.feeStore.getState().setContextualEntertainmentFee(contextualFee);
                            break;
                        case PricingEngineContextualTactic.KEYWORD:
                            this.feeStore.getState().setContextualKeywordFee(contextualFee);
                            break;
                        case PricingEngineContextualTactic.PRODUCT:
                            this.feeStore.getState().setContextualProductFee(contextualFee);
                            break;
                        case PricingEngineContextualTactic.RELATED_PRODUCT:
                            this.feeStore.getState().setContextualRelatedProductFee(contextualFee);
                            break;
                        default:
                            console.error("Invalid contextual tactic found in pricing scenario response: ", pricingScenario)
                    }
                }
            })
        })

        // @ts-ignore
        if (techDisplayFee) {
            this.feeStore.getState().setTechnologyFee(techDisplayFee);
        }
        // @ts-ignore
        if (performancePlusFee) {
            this.feeStore.getState().setPerformancePlusFee(performancePlusFee);
        }

        if (audienceDisplayFees.size > 0) {
            this.feeStore.getState().updateAudienceFeeIndex(audienceDisplayFees);
        }

        // Must set combined audiences after updating the non-combined audiences
        // so that we can search the underlying audiences in the index
        if (combinedAudiences.length > 0) {
            this.setCombinedAudienceDisplayFees(combinedAudiences);
        }
    }

    /**
     * Builds the pricing request body from the given page state and fee state.
     *
     * @param {LineItemProposalPageState} pageState - The page state object.
     * @param {FeeState} feeState - The fee state object.
     * @returns {PricingEngineComputeScenariosInput[]} - An array of pricing engine compute scenarios input objects.
     * @throws {Error} - If the page state or fee state is invalid.
     */
    private buildPricingRequestBodyFromPageState(pageState: LineItemProposalPageState): PricingEngineComputeScenariosInput[] {
        const requestBodyContext = this.extractRequestBodyContextFromPageState(pageState);
        const requestBodyItemsToPrice = this.extractRequestBodyItemsToPriceFromPageState(pageState);
        return this.splitRequestBodyItemsIntoChunks(requestBodyItemsToPrice, ITEMS_TO_PRICE_CHUNK_SIZE)
            .map((chunk) => ({
                ...requestBodyContext,
                clientId: undefined,  // this will be filled by tada
                apiScope: undefined,  // this will be filled by tada
                itemsToPrice: chunk,
            }));
    }

    /**
     * Builds the pricing request body from the given page state and fee state.
     *
     * @returns {PricingEngineComputeScenariosInput[]} - An array of pricing engine compute scenarios input objects.
     * @throws {Error} - If the page state or fee state is invalid.
     */
    private buildPricingRequestBodyForBulkEdit(): PricingEngineComputeScenariosInput[] {
        const requestBodyContext = this.extractRequestBodyContextForBulkEdit();
        const requestBodyItemsToPrice = this.extractRequestBodyItemsToPriceForBulkEdit();
        return this.splitRequestBodyItemsIntoChunks(requestBodyItemsToPrice, ITEMS_TO_PRICE_CHUNK_SIZE)
            .map((chunk) => ({
                ...requestBodyContext,
                clientId: undefined,  // this will be filled by tada
                apiScope: undefined,  // this will be filled by tada
                itemsToPrice: chunk,
            }));
    }

    /**
     * Extracts the items to price from the page state.
     * @param - The page state object.
     * @returns {ItemToPriceV2[]} - An array of items to price.
     */
    private extractRequestBodyItemsToPriceForBulkEdit(): ItemToPriceV2[] {
        const audienceItemsToPrice: ItemToPriceV2[] = [];
        this.feeStore.getState().audienceFeeIndex?.forEach((value, key) => {

            // if audience is missing, do not price it.
            if (!value.audience) {
                console.error("Audience attribute is missing for audience fee: ", key);
                return;
            }

            audienceItemsToPrice.push({
                feeCategory: PricingEngineFeeCategory.AUDIENCE_1P,
                audience: {
                    provider: value.audience.provider,
                    id: key,
                    negativeTarget: false, // hard code as we do not handle negative prices yet.
                    subCategory: value.audience.subCategory,
                    category: value.audience.category
                },
            });
        });
        return audienceItemsToPrice;
    }

    /**
     * Extracts the request body context from the page state.
     * @returns {Partial<PricingEngineComputeScenariosInput>} - The request body context.
     */
    private extractRequestBodyContextForBulkEdit(): Partial<PricingEngineComputeScenariosInput> {
        return {
            country: this.apiData?.country,  // TODO: Will eventually come from page state at the line level
            adGroupId: this.apiData?.lineItemId,
            campaignId: this.apiData?.orderId,
            dspEntityId: this.apiData.entityId,
            currency: this.apiData.currencyOfMarketplace,  // TODO: will currency be used over country?
            dspAdvertiserId: this.apiData.advertiserId,
        }
    }

    /**
     * Extracts the request body context from the page state.
     * @param {LineItemProposalPageState} pageState - The page state object.
     * @returns {Partial<PricingEngineComputeScenariosInput>} - The request body context.
     */
    private extractRequestBodyContextFromPageState(pageState: LineItemProposalPageState): Partial<PricingEngineComputeScenariosInput> {
      const lineItemPageState = pageState?.lineItemV1 || pageState?.proposalV1;

      return {
          country: this.apiData?.country,  // TODO: Will eventually come from page state at the line level
          adGroupId: lineItemPageState?.cfId,
          campaignId: lineItemPageState?.campaignId,
          dspEntityId: pageState?.context?.entity?.value,
          currency: this.apiData?.currencyOfMarketplace,  // TODO: will currency be used over country?
          dspAdvertiserId: pageState?.context?.advertiser?.value,
          deals: this.extractDealsFromPageState(pageState),
      }
    }

    /**
     * Extracts the items to price from the page state.
     * @param {LineItemProposalPageState} pageState - The page state object.
     * @returns {ItemToPriceV2[]} - An array of items to price.
     */
    private extractRequestBodyItemsToPriceFromPageState(pageState: LineItemProposalPageState): ItemToPriceV2[] {
        const extractionFunctions = [
            this.extractAudienceItemsToPriceFromPageState.bind(this),
            this.extractTechFeeItemsToPriceFromPageState.bind(this),
            this.extractPerformancePlusItemsToPriceFromPageState.bind(this),
            this.extractContextualTargetingItemsToPriceFromPageState.bind(this),
        ];

        const itemsToPriceArrays = extractionFunctions.map(fn => fn(pageState));
        return itemsToPriceArrays.flat();
    }

    /**
     * Extracts the performance plus items to price from the page state.
     * If no tactics exist on page state, no P+ price will be requested.
     * @param {LineItemProposalPageState} pageState - The page state object.
     * @returns {ItemToPriceV2[]} - An array of items to price.
     */
    private extractPerformancePlusItemsToPriceFromPageState(pageState: LineItemProposalPageState): ItemToPriceV2[] {
        const DEVICE_TYPES = this.extractDeviceTypesFromPageState(pageState);
        const SUPPLY_SOURCE_TYPES = this.extractSupplySourceTypesFromPageState(pageState);
        const MEDIA_TYPES = this.extractMediaTypesFromPageState(pageState);
        const PLATFORMS = this.extractPlatformsFromPageState(pageState);
        const PERFORMANCE_TACTIC = this.extractPerformancePlusTacticFromPageState(pageState);
        // TODO: Add isO&O flag
        const performancePlusItemsToPrice: ItemToPriceV2[] = [];

        if (PERFORMANCE_TACTIC) {
            performancePlusItemsToPrice.push({
                feeCategory: PricingEngineFeeCategory.AUDIENCE_1P,
                performancePlusTactic: PERFORMANCE_TACTIC,
                audience: {
                    provider: "AMAZON_AUDIENCE",
                    id: "",
                    negativeTarget: false, // hard code as we do not handle negative prices yet.
                    category: "PERFORMANCE_PLUS"
                },
                deviceTypes: DEVICE_TYPES,
                supplySourceTypes: SUPPLY_SOURCE_TYPES,
                mediaTypes: MEDIA_TYPES,
                platforms: PLATFORMS,
            });
        }
        return performancePlusItemsToPrice;
    }

    /**
     * Extracts the audience items to price from the page state.
     * @param {LineItemProposalPageState} pageState - The page state object.
     * @param {FeeState} feeState - The fee state object.
     * @returns {ItemToPriceV2[]} - An array of audience items to price.
     */
    private extractAudienceItemsToPriceFromPageState(pageState: LineItemProposalPageState): ItemToPriceV2[] {
        const lineItemPageState = pageState.lineItemV1 || pageState.proposalV1;
        const AudienceGroups = lineItemPageState?.segmentTargeting?.builder as AudienceGroup[];
        const DEVICE_TYPES = this.extractDeviceTypesFromPageState(pageState);
        const SUPPLY_SOURCE_TYPES = this.extractSupplySourceTypesFromPageState(pageState);
        const MEDIA_TYPES = this.extractMediaTypesFromPageState(pageState);
        const PLATFORMS = this.extractPlatformsFromPageState(pageState);
        // TODO: Add is O&O flag
        const audienceItemsToPrice: ItemToPriceV2[] = [];

        // index any fees to be picked up for pricing the request
        this.indexAudienceFees(AudienceGroups);

        this.feeStore.getState().audienceFeeIndex?.forEach((value, key) => {

            // if audience is missing, do not price it.
            if (!value.audience) {
                console.error("Audience attribute is missing for audience fee: ", key);
                return;
            }

            audienceItemsToPrice.push({
                feeCategory: PricingEngineFeeCategory.AUDIENCE_1P,
                audience: {
                    provider: value.audience.provider,
                    id: key,
                    negativeTarget: false, // hard code as we do not handle negative prices yet.
                    subCategory: value.audience.subCategory,
                    category: value.audience.category
                },
                deviceTypes: DEVICE_TYPES,
                supplySourceTypes: SUPPLY_SOURCE_TYPES,
                mediaTypes: MEDIA_TYPES,
                platforms: PLATFORMS,
            });
        });

        return audienceItemsToPrice;
    }

    /**
     * Extracts the audience items to price from the page state.
     * @param {LineItemProposalPageState} pageState - The page state object.
     * @param {FeeState} feeState - The fee state object.
     * @returns {ItemToPriceV2[]} - An array of audience items to price.
     */
    private extractContextualTargetingItemsToPriceFromPageState(pageState: LineItemProposalPageState): ItemToPriceV2[] {
        const DEVICE_TYPES = this.extractDeviceTypesFromPageState(pageState);
        const SUPPLY_SOURCE_TYPES = this.extractSupplySourceTypesFromPageState(pageState);
        const MEDIA_TYPES = this.extractMediaTypesFromPageState(pageState);
        const PLATFORMS = this.extractPlatformsFromPageState(pageState);
        // TODO: Add is O&O flag

        return Object.keys(PricingEngineContextualTactic).map((tactic) => ({
                feeCategory: PricingEngineFeeCategory.CONTEXTUAL,
                contextual: {
                    tactic: PricingEngineContextualTactic[tactic]
                },
                deviceTypes: DEVICE_TYPES,
                supplySourceTypes: SUPPLY_SOURCE_TYPES,
                mediaTypes: MEDIA_TYPES,
                platforms: PLATFORMS,
        }))
    }

    /**
     * Extracts the tech fee items to price from the page state.
     * @param {LineItemProposalPageState} pageState - The page state object.
     * @returns {ItemToPriceV2[]} - An array of tech fee items to price.
     */
    private extractTechFeeItemsToPriceFromPageState(pageState: LineItemProposalPageState): ItemToPriceV2[] {
        return [{
            feeCategory: PricingEngineFeeCategory.TECH,
            deviceTypes: this.extractDeviceTypesFromPageState(pageState),
            supplySourceTypes: this.extractSupplySourceTypesFromPageState(pageState),
            mediaTypes: this.extractMediaTypesFromPageState(pageState),
            platforms: this.extractPlatformsFromPageState(pageState),
        }]
    }

    /**
     * Extracts the deals from the page state.
     * @param {LineItemProposalPageState} pageState - The page state object.
     * @returns {PricingEngineDeal[] | undefined} - An array of deals, or undefined if no deals are found.
     */
    private extractDealsFromPageState(pageState: LineItemProposalPageState): PricingEngineDeal[] {
        const lineItemPageState = pageState.lineItemV1 || pageState.proposalV1;
        // @ts-ignore
        const selectedPageStateDeals: Set<Deal> = new Set(
            [
                // @ts-ignore
                ...(lineItemPageState?.dealSectionView?.deals || []),
                // @ts-ignore
                ...(lineItemPageState?.dealSectionView?.supplyPackageDeals || [])
            ]
        );
        let dealsToPrice: PricingEngineDeal[] = [];

        selectedPageStateDeals?.forEach((deal) => {
            dealsToPrice.push({
                dealId: deal.dealId,
                dealType: dealTypeToPengDealType(deal.dealType),
                supplySourceId: deal.supplySourceId
            });
        })

        return dealsToPrice;
    }

    /**
     * Extracts the Performance Plus tactic from the given page state.
     *
     * @param {LineItemProposalPageState} pageState - The page state object containing line item or proposal data.
     * @returns {PricingEnginePerformancePlusTactic | null} The extracted Performance Plus tactic, or null if not found.
     */
    private extractPerformancePlusTacticFromPageState(pageState: LineItemProposalPageState): PricingEnginePerformancePlusTactic | null {
        const lineItemPageState = pageState.lineItemV1 ?? pageState.proposalV1;

        if (!lineItemPageState?.targetingDetails?.automatedTactic) {
            return null;
        }

        return PricingEnginePerformancePlusTactic[lineItemPageState.targetingDetails.automatedTactic] ?? null;
    }

    /**
     * Extracts the applicable media types from the given page state.
     *
     * @param pageState the LineItemProposalPageState object containing the page state information
     * @return an array of PricingEngineScenariosMediaType objects representing the applicable media types, or null if the page state is invalid
     */
    private extractMediaTypesFromPageState(pageState: LineItemProposalPageState): PricingEngineScenariosMediaType[] | undefined {
      const lineItemPageState = pageState?.lineItemV1 || pageState?.proposalV1;

      if (!lineItemPageState || !lineItemPageState?.type) return;

      let applicableMediaTypes: PricingEngineScenariosMediaType[] = [];

      switch (lineItemPageState?.type) {
          case LineItemTypes.AUDIO:
          case LineItemTypes.AUDIO_GUARANTEED:
          case LineItemTypes.PODCAST:
              applicableMediaTypes.push(PricingEngineScenariosMediaType.STREAMING_AUDIO);
              break;
          case LineItemTypes.VIDEO:
              applicableMediaTypes.push(PricingEngineScenariosMediaType.VIDEO);
              break;
          case LineItemTypes.OTT_VIDEO:
              applicableMediaTypes.push(PricingEngineScenariosMediaType.OTT_VIDEO);
              break;
          default:
              applicableMediaTypes.push(PricingEngineScenariosMediaType.DISPLAY);
              break;
      }

      return applicableMediaTypes.length > 0 ? applicableMediaTypes : undefined;
    }

    /**
     * Extracts the applicable supply source types from the given page state.
     *
     * @param {LineItemProposalPageState} pageState - The page state object.
     * @returns {PricingEngineSupplySourceType[] | undefined} - An array of applicable supply source types, or undefined if no supply source types are found.
     */
    private extractSupplySourceTypesFromPageState(pageState: LineItemProposalPageState): PricingEngineSupplySourceType[] | undefined {
        const lineItemPageState = pageState?.lineItemV1 || pageState?.proposalV1;

        let supplySourceTypes: PricingEngineSupplySourceType[] = [];

        switch (lineItemPageState?.type) {
            case LineItemTypes.VIDEO:
                if (hasPVASource(lineItemPageState))
                    supplySourceTypes.push(PricingEngineSupplySourceType.PVA);
                if (hasSTVSources(lineItemPageState))
                    supplySourceTypes.push(PricingEngineSupplySourceType.STV);
                break;
        }

        return supplySourceTypes.length > 0 ? supplySourceTypes : undefined;
    }

    /** TODO: Determine correct device type selection after consolidation effort has concluded,
     * Right now we do not use device type for pricing, but we will need to in the future.
     */
    private extractDeviceTypesFromPageState(pageState: LineItemProposalPageState): PricingEngineDeviceType[] | undefined {
        return undefined;
    }

    /**
     * Extracts the applicable platforms from the given page state.
     *
     * @param {LineItemProposalPageState} pageState - The page state object.
     * @returns {PricingEnginePlatform[] | undefined} - An array of applicable platforms, or undefined if no platforms are found.
     */
    private extractPlatformsFromPageState(pageState: LineItemProposalPageState): PricingEnginePlatform[] | undefined {
        const lineItemPageState = pageState?.lineItemV1 || pageState?.proposalV1;

        let applicablePlatforms: PricingEnginePlatform[] = [];

        switch (lineItemPageState?.type) {
            case LineItemTypes.MOBILE_APP:
                applicablePlatforms.push(PricingEnginePlatform.APP);
                break;
            case LineItemTypes.MOBILE_WEB:
                applicablePlatforms.push(PricingEnginePlatform.WEB);
                break;
            case LineItemTypes.AUDIO:
                if (lineItemPageState?.audioDeviceMobileEnvironmentView?.app)
                    applicablePlatforms.push(PricingEnginePlatform.APP);
                if (lineItemPageState?.audioDeviceMobileEnvironmentView?.web)
                    applicablePlatforms.push(PricingEnginePlatform.WEB);
                break;
            case LineItemTypes.VIDEO:
            case LineItemTypes.DISPLAY:
                if (lineItemPageState?.deviceMobileEnvironmentView?.app)
                    applicablePlatforms.push(PricingEnginePlatform.APP);
                if (lineItemPageState?.deviceMobileEnvironmentView?.web)
                    applicablePlatforms.push(PricingEnginePlatform.WEB);
                break;
        }

        return applicablePlatforms.length > 0 ? applicablePlatforms : undefined;
    }

    /**
     * Splits the items array into smaller chunks of a specified size.
     * @param {any[]} items - The array of items to be split.
     * @param {number} chunkSize - The maximum size of each chunk.
     * @returns {any[][]} - An array of arrays, where each inner array is a chunk of the original items array.
     */
    private splitRequestBodyItemsIntoChunks(
        items: any[],
        chunkSize: number
    ): any[][] {
        if (chunkSize >= items.length) {
            return [items];
        }
        // reduce can more performant than for loops in some cases
        return items.reduce((chunks: any[][], _, index) => {
            if (index % chunkSize === 0) {
                chunks.push(items.slice(index, index + chunkSize));
            }
            return chunks;
        }, []);
    }

    /**
     * Indexes the audience fees in the given fee state.
     * If audience is already in the index, it will be skipped.
     * @param {AudienceGroup[]} audienceGroups - An array of audience groups.
     * @returns {void}
     */
    private indexAudienceFees(
        audienceGroups: AudienceGroup[]
    ): void {
        const unIndexedFees: CustomElementSegment[] = [];

        audienceGroups?.forEach((audienceGroup) => {
            audienceGroup?.segments?.forEach((segment) => {
                const id = segment.audienceId || segment.canonicalId;
                if (!retrieveAllAudiences(this.feeStore.getState()).has(id)) {
                    unIndexedFees.push(segment);
                    if (segment.category === COMBINED_AUDIENCES_CATEGORY) {
                        // request underlying audiences
                        const caSegment = {
                            ...segment,
                            audienceId: segment.audienceId || segment.canonicalId,
                        } as unknown as Audience;

                        // Observing pricing for each combined audinece's underlying audiencs could cause a lot of continues requesting
                        // this solution assumes there are only a limited number of combined audiences that can be selected.
                        this.combinedAudienceClient.getUnderlyingAudiences(caSegment, true).promise.then(
                            (audiences) => {
                                this.feeStore.getState().observeAudiencesForPricing(audiences as unknown as CustomElementSegment[]);
                            }
                        )
                    }
                }
            });
        });
        if (unIndexedFees.length > 0) {
            this.feeStore.getState().observeAudiencesForPricing(unIndexedFees);
        }
    }

    /**
     * Sets the combined audience display fees for the given array of PricingEngineScenarioAudience objects.
     *
     * @param {PricingEngineScenarioAudience[]} combinedAudiences - An array of PricingEngineScenarioAudience objects.
     * @returns {void}
     */
    private setCombinedAudienceDisplayFees = (combinedAudiences: PricingEngineScenarioAudience[]): void => {
        combinedAudiences.forEach((ca) => {
            const caAudience = {
                ...ca,
                audienceId: ca.id,
            } as unknown as Audience;
            this.combinedAudienceClient.getUnderlyingAudiences(caAudience, false)
                .promise.then((audiences) => {
                const firstPartyAudiences = audiences
                    .filter((audience) => !THIRD_PARTY_CATEGORIES.includes(audience.category))
                    .map((audience) => this.feeStore.getState().audienceFeeIndex.get(audience.audienceId) ?? EMPTY_FEE);

                const thirdPartyAudiences = audiences
                    .filter((audience) => THIRD_PARTY_CATEGORIES.includes(audience.category))
                    .map((audience) => this.feeStore.getState().thirdPartyAudienceFeeIndex.get(audience.audienceId) ?? EMPTY_FEE);

                const combinedAudiencesDisplayFee = getCombinedAudienceDisplayFee(ca, firstPartyAudiences, thirdPartyAudiences);
                this.feeStore.getState().updateAudienceFromIndex(combinedAudiencesDisplayFee);
            });
        });
    };

    /**
     * Updates the cache for the last pricing request if the new request is different than the last.
     *
     * @param {PricingEngineComputeScenariosInput[]} pricingRequests - An array of pricing requests.
     * @returns {boolean} Returns false if the current pricing request is the same as the last cached request, true otherwise.
     */
    private updateLastPricingRequestCache(pricingRequests: PricingEngineComputeScenariosInput[]): boolean {
        // Convert the pricingRequests object to a JSON string
        const pricingRequestsJSON = JSON.stringify(pricingRequests);

        // Create a non-cryptographic hash of the JSON string for cache key
        const hash = hashString(pricingRequestsJSON);

        // check if current request hash is different from last request hash
        if (hash === this.lastRequestCached) {
            return false;
        } else {
            this.lastRequestCached = hash;
            return true;
        }
    }
}