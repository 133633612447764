export interface Filter {
  field: string;
  values: (string | undefined)[];
}

export interface FilterSet {
  [key: string]: Filter;
  audienceName: Filter;
  category: Filter;
  audienceId: Filter;
}

export enum FilterNames {
  audienceName = 'audienceName',
  category = 'category',
  audienceId = 'audienceId',
}

export interface DropdownFilterItem {
  value: string;
  label: string;
}

export enum AudienceCategory {
  'InMarket' = 'In-market',
  'Interest' = 'Interest',
  'Lifestyle' = 'Lifestyle',
  'LifeEvent' = 'Life event',
  'AdvertiserAudiences' = 'Advertiser audiences',
  'Retargeted' = 'Retargeted',
  'CustomBuilt' = 'Custom-built',
  'Demographic' = 'Demographic',
  'Lookalike' = 'Lookalike',
  'ThirdParty' = 'Third-party',
  'Contextual' = 'Contextual',
  'Device' = 'Device',
}

export interface AudienceCategoryDropdownItem {
  value: AudienceCategory;
  label: AudienceCategory;
}
