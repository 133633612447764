import styled from 'styled-components';
import { defaultTheme, Icon, Text } from '@amzn/storm-ui';

const AudienceSegmentContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0px;
  white-space: nowrap;
`;

const AudienceLeftInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  margin: 4px 0;
`;

const CloseButton = styled(Icon)`
  margin-top: 1px;
  margin-left: 10px;
  color: ${defaultTheme.palette.dustyGray};
  cursor: pointer;
`;

const AudienceRightInfo = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`;

const AudienceNameText = styled(Text)`
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;

export const AudienceSegmentComponentStyled = {
  AudienceSegmentContainer,
  AudienceLeftInfo,
  AudienceRightInfo,
  CloseButton,
  AudienceNameText,
};
