import React, { useContext } from 'react';
import styled from 'styled-components';
import { defaultTheme, Text } from '@amzn/storm-ui';
import { capitalize, TranslationContext } from '../../state/translationContext';
import {
  ERROR_LOADING_ROWS,
  NO_AUDIENCES_FOUND,
} from '../../constants/translations';
const NoRowsOverlayContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: ${defaultTheme.palette.dustyGray};
`;

type noRowsOverlayProps = {
  /**
   * Whether the API call for a list of audiences succeeded or failed
   */
  succeeded?: boolean;
};

/**
 * Overlay on the audience table to show either an error message or a message that no audiences were found.
 */
export const NoRowsOverlay = ({ succeeded }: noRowsOverlayProps) => {
  const getTranslation = useContext(TranslationContext);

  return (
    <div data-testid={'no-rows-overlay-component'}>
      <NoRowsOverlayContainer>
        <Text type="h4">
          {succeeded
            ? capitalize(getTranslation(NO_AUDIENCES_FOUND))
            : getTranslation(ERROR_LOADING_ROWS)}
        </Text>
      </NoRowsOverlayContainer>
    </div>
  );
};
