import { useState } from 'react';
import { ModalProps } from 'src/model/ConfirmationModal';

type VoidFunction = () => void;

type UseWarningModalReturn = ModalProps & {
  showModal: (onConfirm?: VoidFunction, onCancel?: VoidFunction) => void;
  hideModal: VoidFunction;
};

export default function useWarningModal(): UseWarningModalReturn {
  const [isOpen, setIsOpen] = useState(false);
  const [completionCallbacks, setCompletionCallbacks] = useState<{
    onConfirm?: VoidFunction;
    onCancel?: VoidFunction;
  }>({});

  const showModal: UseWarningModalReturn['showModal'] = (
    onConfirm,
    onCancel
  ) => {
    setIsOpen(true);
    setCompletionCallbacks({ onCancel, onConfirm });
  };
  const hideModal = () => setIsOpen(false);

  const onCancel = () => {
    hideModal();
    completionCallbacks.onCancel?.();
  };
  const onConfirm = () => {
    hideModal();
    completionCallbacks.onConfirm?.();
  };

  return {
    isOpen,
    onCancel,
    onConfirm,
    hideModal,
    showModal,
  };
}
