import { useEffect } from 'react';
import useAbstractedLineItemPageState from 'src/utils/hooks/useAbstractedLineItemPageState';
import { MFEBootstrap, TypedPatch } from '@mfe/state-management';
import { Common } from '@mfe/state-management-interfaces';
import {
  LineItemProposalPageState,
  LineItemPageState,
} from 'src/model/LineItemPageState';
import { ComponentIds } from 'src/utils/MFEConstants';
import { BulkEditPageState } from 'src/model/BulkEditPageState';
import useAbstractedBulkEditPageState from 'src/utils/hooks/useAbstractedBulkEditPageState';

/**
 * Hook for managing the form fields, such as current state, validations, save functionality.
 *
 * A key goal of this hook is to provide abstraction between the pageState property and the form field in the MFE.
 * Ideally, consumer of this hook only needs to provide the location of the field in the page state, default value, new value on update, and what validations to run,
 * and this hook will take care of the rest.
 *
 * @param propertyExtractor - function to return property from line item page state
 * @param initialValue - value of the field to be used during initialization.
 * @param mfeId - The ID of the MFE utilizing this hook
 * @param pageState - global page state
 */
export default function useFormField<PropertyType>(
  propertyExtractor: (lineItemModel: any) => any,
  mfeId: ComponentIds,
  pageState: MFEBootstrap.PageState<LineItemProposalPageState>,
  initialValue: PropertyType = undefined
): [
  { value: PropertyType; errors: Array<string> },
  (newValue: PropertyType) => void
] {
  const [lineItemPageState, applyAbstractedStatePatches] =
    useAbstractedLineItemPageState(pageState, true);

  const currentFieldValue = propertyExtractor(
    lineItemPageState.lineItemV1
  ) as PropertyType;

  useEffect(() => {
    if (currentFieldValue !== undefined && currentFieldValue !== null) return;

    if (initialValue === undefined || initialValue === null) return;

    const defaultPatch = new TypedPatch.TypedAddPatch(
      (state: Common.PathOf<Common.DeepPartial<LineItemPageState>>) =>
        propertyExtractor(state.lineItemV1) as Common.Path<PropertyType>,
      initialValue
    );
    applyAbstractedStatePatches([defaultPatch]);
  }, [initialValue]);

  // Applies state patches for both the new field value
  // and any validation errors that it causes
  const setFieldValue = (newValue: PropertyType): void => {
    const statePatches: TypedPatch.TypedPatch<LineItemPageState>[] = [];

    statePatches.push(
      new TypedPatch.TypedReplacePatch(
        (state: Common.PathOf<Common.DeepPartial<LineItemPageState>>) =>
          propertyExtractor(state.lineItemV1) as Common.Path<PropertyType>,
        newValue
      )
    );

    applyAbstractedStatePatches(statePatches);
  };

  return [{ value: currentFieldValue, errors: [] }, setFieldValue];
}

export function useBulkEditFormField<PropertyType>(
  propertyExtractor: (bulkEditModel: any) => any,
  mfeId: ComponentIds,
  pageState: MFEBootstrap.PageState<BulkEditPageState>,
  initialValue: PropertyType = undefined
): [
  { value: PropertyType; errors: Array<string> },
  (newValue: PropertyType) => void
] {
  const [bulkEditPageState, applyAbstractedStatePatches] =
    useAbstractedBulkEditPageState(pageState, true);

  const currentFieldValue = propertyExtractor(
    bulkEditPageState.orderLineItemBulkEditState
  ) as PropertyType;

  useEffect(() => {
    if (initialValue === undefined || initialValue === null) return;

    const defaultPatch = new TypedPatch.TypedAddPatch(
      (state: Common.PathOf<Common.DeepPartial<BulkEditPageState>>) =>
        propertyExtractor(
          state.orderLineItemBulkEditState
        ) as Common.Path<PropertyType>,
      initialValue
    );
    applyAbstractedStatePatches([defaultPatch]);
  }, []);

  // Applies state patches for both the new field value
  // and any validation errors that it causes
  const setFieldValue = (newValue: PropertyType): void => {
    const statePatches: TypedPatch.TypedPatch<BulkEditPageState>[] = [];

    statePatches.push(
      new TypedPatch.TypedReplacePatch(
        (state: Common.PathOf<Common.DeepPartial<BulkEditPageState>>) =>
          propertyExtractor(
            state.orderLineItemBulkEditState
          ) as Common.Path<PropertyType>,
        newValue
      )
    );

    applyAbstractedStatePatches(statePatches);
  };

  return [{ value: currentFieldValue, errors: [] }, setFieldValue];
}
