const defaultResources = require('../../translations/translation.json');
const ar = require('../../translations/translation-ar.json');
const de = require('../../translations/translation-de.json');
const en = require('../../translations/translation-en.json');
const es = require('../../translations/translation-es.json');
const fr = require('../../translations/translation-fr.json');
const hi = require('../../translations/translation-hi.json');
const it = require('../../translations/translation-it.json');
const ja = require('../../translations/translation-ja.json');
const ko = require('../../translations/translation-ko.json');
const nl = require('../../translations/translation-nl.json');
const pl = require('../../translations/translation-pl.json');
const pt = require('../../translations/translation-pt.json');
const sv = require('../../translations/translation-sv.json');
const ta = require('../../translations/translation-ta.json');
const th = require('../../translations/translation-th.json');
const tr = require('../../translations/translation-tr.json');
const vi = require('../../translations/translation-vi.json');
const zh = require('../../translations/translation-zh.json');

// Map of locale and resources.
// Add more resources to the map when we add more locales.
export const resourcesMap = new Map([
  ['defaultResources', defaultResources],
  ['ar-AE', ar],
  ['de-DE', de],
  ['en-AE', en],
  ['en-AU', en],
  ['en-CA', en],
  ['en-GB', en],
  ['en-IN', en],
  ['en-SG', en],
  ['en-US', en],
  ['en-ZA', en],
  ['es-CL', es],
  ['es-ES', es],
  ['es-MX', es],
  ['fr-BE', fr],
  ['fr-CA', fr],
  ['fr-FR', fr],
  ['hi-IN', hi],
  ['it-IT', it],
  ['ja-JP', ja],
  ['ko-KR', ko],
  ['nl-BE', nl],
  ['nl-NL', nl],
  ['pl-PL', pl],
  ['pt-BR', pt],
  ['sv-SE', sv],
  ['ta-IN', ta],
  ['th-TH', th],
  ['tr-TR', tr],
  ['vi-VN', vi],
  ['zh-CN', zh],
  ['zh-TW', zh],
]);
