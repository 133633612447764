import styled from 'styled-components';
import { Icon, defaultTheme, Text } from '@amzn/storm-ui';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 20px !important;
  border-bottom: 1px solid ${defaultTheme.palette.gray[100]};
`;

const AlertText = styled(Text)`
  padding-top: 1px;
`;

const AlertContainer = styled.div`
  display: flex;
  align-items: start;
`;

const AlertIcon = styled(Icon)`
  cursor: pointer;
  color: orange;
  padding: 0 5px 0 0;
`;

export const NewAudienceAlertStyled = {
  Container,
  AlertText,
  AlertContainer,
  AlertIcon,
};
