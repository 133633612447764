import React from 'react';
import { Common } from '@mfe/state-management-interfaces';
import styled from 'styled-components';
import { AudienceOperatorEnum } from 'src/MFE/lineItem/summary/SummaryViewContainer';
import { LineItemV1 } from '@amzn/d16g-state-management-interfaces';
import { LocalizedText } from 'src/Localization/LocalizationText';
import { TranslatorString } from 'src/Localization/LocalizationConfig';

interface AudienceProps {
  audience: Common.DeepPartial<LineItemV1.Segment>;
  isNotFirstSegment: boolean;
  operator: string;
}

const AudienceRow = styled.div`
  padding-top: 3px;
`;
const BoldText = styled.span`
  font-weight: 600;
  padding: 3px;
`;

function Audience({ audience, isNotFirstSegment, operator }: AudienceProps) {
  const Operator = () => (
    <BoldText>
      <LocalizedText
        translatorString={AudienceOperatorEnum[operator.toUpperCase()]}
      />
    </BoldText>
  );

  const Not = (isNot: boolean) => {
    if (isNot)
      return (
        <BoldText>
          <LocalizedText
            translatorString={
              TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_NOT
            }
          />
        </BoldText>
      );
  };

  const GroupInfo = () => (
    <AudienceRow key={audience.id}>
      {isNotFirstSegment ? Operator() : ''}
      {Not(audience.not)}
      {audience.label}
    </AudienceRow>
  );

  return <GroupInfo />;
}

export default Audience;
