import {
  getFeeValueInMilli,
  LegacyAudienceGroup,
} from 'src/utils/AudienceTargetingConverters';
import { Common } from '@mfe/state-management-interfaces';
import { LineItemV1 } from '@amzn/d16g-state-management-interfaces';
import { AudienceFees } from 'src/MFE/lineItem/model/PageStateModels';
import {
  getActiveImpressionSupplyTypes,
  getImpressionSupplyType,
  ImpressionSupplyTypes,
} from 'src/utils/ImpressionSupplyType';
import { THIRD_PARTY_CATEGORIES } from 'src/utils/MFEConstants';
import {
  Audience,
  AudienceSegment,
  Fee,
} from '@amzn/d16g-audience-picker-component';
import { CombinedAudienceClient } from '@amzn/d16g-pricing-react-utils';

export const DEFAULT_SCALE = 100000;

/**
 * @function
 * Calculates the 1p fee for each active impression supply type
 * @param {LegacyAudienceGroup[]} segmentTargeting - Selected audiences in legacy format
 * @param {LineItemV1.LineItemModel} lineItemState - state of the line item selections
 * @return {Promise<AudienceFees>} - returns the fees to be set on the line item page state
 */
export const calculateAudienceFee = async (
  segmentTargeting: LegacyAudienceGroup[],
  lineItemState: Common.DeepPartial<LineItemV1.LineItemModel>
): Promise<AudienceFees> => {
  let max1PFee = 0;
  let max3PFee = 0;
  let currency;
  let supplyFees = [];
  const activeImpressionSupplyTypes: ImpressionSupplyTypes[] =
    getActiveImpressionSupplyTypes(lineItemState);
  const maxFeesByType = {};

  const allSegments = await CombinedAudienceClient.getInstance()
    .replaceCombinedAudiencesWithTheirUnderlyingAudiences(
      flattenLegacySegments(segmentTargeting) as Audience[],
      true
    )
    .then((audiences: any) =>
      audiences.map((audience) => {
        if (audience?.feeMilliCents === undefined)
          return {
            ...audience,
            feeMilliCents: getFeeValueInMilli(
              audience,
              getImpressionSupplyType(lineItemState)
            ),
          };

        return audience;
      })
    );

  allSegments.forEach((segment) => {
    if (!THIRD_PARTY_CATEGORIES.includes(segment.category)) {
      // calculate fees for each active supply type
      segment?.fees?.forEach((fee: Fee) => {
        // @ts-ignore
        if (activeImpressionSupplyTypes.includes(fee.impressionSupplyType)) {
          const feeInMillivalue = !isNaN(fee.amount) ? Number(fee.amount) : 0;
          maxFeesByType[fee.impressionSupplyType] = !isNaN(
            maxFeesByType[fee.impressionSupplyType]
          )
            ? Math.max(maxFeesByType[fee.impressionSupplyType], feeInMillivalue)
            : feeInMillivalue;
        }
      });
      // set the supply fees list
      supplyFees = Object.keys(maxFeesByType).map((type) => ({
        type,
        amount: maxFeesByType[type],
      }));
      // set max 1P fee for dataFee object
      const segmentFee = segment.feeMilliCents || 0;
      max1PFee = calculateNewMaxFee(max1PFee, segmentFee);
      // set the currency
      if (currency) {
        if (
          segment?.fees?.[0]?.currency &&
          segment?.fees?.[0]?.currency !== currency
        )
          console.log(
            'Found prices in the audience list with different currencies. ',
            [segment, currency]
          );
      } else currency = segment?.fees?.[0]?.currency;
    } else if (!segment?.not) {
      const segmentFee = segment.feeMilliCents;
      max3PFee = calculateNewMaxFee(max3PFee, segmentFee);
    }
  });

  return {
    audience1PFees: {
      millivalue: max1PFee / DEFAULT_SCALE,
      currency,
      supplyFees,
    },
    audience3PFees: max3PFee / DEFAULT_SCALE,
  };
};

export const calculateNewMaxFee = (currentMax: number, fee: number) => {
  const newMax = !isNaN(currentMax) ? currentMax : 0;
  const newFee = !isNaN(fee) ? fee : 0;
  return Math.max(newMax, newFee);
};

/**
 * @function
 * flattens the legacy audience selections into a single array of audiences in the format required for PENG API.
 * @param {LegacyAudienceGroup[]} legacyAudienceGroups - Selected audiences in legacy format.
 * @return {AudienceSegment[]} - returns an array of API compliant audiences.
 */
export const flattenLegacySegments = (
  legacyAudienceGroups: LegacyAudienceGroup[]
): AudienceSegment[] => {
  const flatAudiencesSegments = [];
  legacyAudienceGroups?.forEach((legacyAudienceGroup) => {
    legacyAudienceGroup?.segments?.forEach((segment) => {
      const flatSegment = {
        ...segment,
        audienceId: segment.canonicalId,
        negativeTarget: false, // hardcoded for now as we do not show negative prices on the UI today.
      };
      flatAudiencesSegments.push(flatSegment);
    });
  });

  return flatAudiencesSegments;
};
