import { Common } from '@mfe/state-management-interfaces';
import { LineItemV1 } from '@amzn/d16g-state-management-interfaces';

export interface AudienceTargetingGroup {
  dealBasedTargetingEnabled: boolean;
  intrasetOperator: string;
  operator: string;
  segments: Common.DeepPartial<LineItemV1.Segment>[];
}
export const AND = 'AND';
export const OR = 'OR';
