import React, { ReactElement } from 'react';
import { Text, Modal } from '@amzn/storm-ui';
import { ModalProps } from 'src/model/ConfirmationModal';
import ModalFooter from './ModalFooter';
import { LocalizedText } from 'src/Localization/LocalizationText';
import { TranslatorString } from 'src/Localization/LocalizationConfig';

/**
 * Presentational Confirmation Modal component.
 * Displays a modal asking the user to confirm or cancel
 * their request to remove a subsection.
 * @param props Component props
 * @returns Presentational Confirmation Modal component.
 */
const ClearAudience = (props: ModalProps): ReactElement => {
  const { isOpen, toggleElement, onCancel, onConfirm } = props;
  return (
    <>
      <Modal
        isOpen={isOpen}
        header={
          <LocalizedText
            translatorString={
              TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SAVE_CONFIRMATION
            }
          />
        }
        footer={
          <ModalFooter
            cancelButtonTranslatorString={
              TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_CANCEL
            }
            onCancel={onCancel}
            confirmButtonTranslatorString={
              TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SAVE_CHANGES
            }
            onConfirm={onConfirm}
            testId="clear-audience"
          />
        }
        onClose={onCancel}
        toggleEl={toggleElement}
      >
        <Text>
          <LocalizedText
            translatorString={
              TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_CLEAR_AUDIENCE_CONFIRM
            }
          />
        </Text>
      </Modal>
    </>
  );
};

export default ClearAudience;
