import React, { useContext } from 'react';

import { PaginationPageStatus } from '@amzn/storm-ui';
import {
  UnifiedDataTablePaginationContext,
  UnifiedDataTableApiContext,
} from '@amzn/unified-data-table-components/core';
import { getDefaultLabels } from '@amzn/unified-data-table-components/core/components/Pagination/Pagination.intl';
import { PaginationProps } from '@amzn/unified-data-table-components/core/components/Pagination/Pagination.interfaces';
import { FooterPanel } from './Footer.styled';

/**
 * Component to show the table pagination and handle the UDC table grid API for it.
 */
export const Footer: React.FC<PaginationProps> = (props: PaginationProps) => {
  const apiContext = useContext(UnifiedDataTableApiContext);
  const paginationContext = useContext(UnifiedDataTablePaginationContext);

  const { udcApi } = apiContext;
  const { pageSize, currentPage, totalPages, rowCount } = paginationContext;

  if (!udcApi) return <></>;

  const { labels = getDefaultLabels(udcApi), hidePageStatus } = props;

  if (hidePageStatus) return <></>;

  if (
    currentPage === undefined ||
    pageSize === undefined ||
    rowCount === undefined ||
    totalPages === undefined ||
    !labels.renderCurrentPageLabel
  )
    return <></>;

  const rowFrom = currentPage * pageSize + 1;
  const rowTo = Math.min((currentPage + 1) * pageSize, rowCount);

  if (rowCount <= 0) return <></>;

  const currentPageLabel = labels.renderCurrentPageLabel(
    pageSize,
    currentPage,
    totalPages,
    rowFrom,
    rowTo,
    rowCount
  );

  return (
    <FooterPanel
      data-testid={'suggestions-footer'}
      id="suggestions-footer-panel"
    >
      <PaginationPageStatus currentPageLabel={currentPageLabel} />
    </FooterPanel>
  );
};
