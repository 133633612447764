import { PROJECT_BASE_ID, ComponentIds } from 'src/utils/MFEConstants';

interface MfeHtmlIdPrefixMap {
  [key: string]: string;
}

const MFE_HTML_ID_PREFIX_MAP: MfeHtmlIdPrefixMap = {
  [ComponentIds.AUDIENCE_TARGETING]: 'audience-targeting',
};

/**
 * Generates an HTML ID for the given component.
 * Example input/outputs:
 * ('my-component') => 'd16g-rodeo-line-item--my-component'
 * ('my-component', GENERAL_SECTION) => 'd16g-rodeo-line-item--general-section-my-component'
 * @param componentName The name of the component to generate an ID for
 * @param mfeId         An  MFE ID to prefix the componentName
 * @returns string ID
 */
export const generateHtmlId = (
  componentName: string,
  mfeId: ComponentIds
): string =>
  `${PROJECT_BASE_ID}--${MFE_HTML_ID_PREFIX_MAP[mfeId]}-${componentName}`;
