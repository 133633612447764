// @ts-nocheck
/* eslint-disable no-prototype-builtins */

/**
 * When in shadowDOM events are retargeted and encapsulated in the shadow.
 * since the mouseenter, mouseleave events do not bubble at all so we meed to
 * manage these events manually. This function retargets custom mouse events to their
 * respective react counterparts (i.e. mouseenter -> onMouseEnter)
 * (NOTE: this should be fixed in react 17)
 * @param {HTMLElement}  element - The element you want to retarget event for.
 * @returns void
 */
export function retargetEvents(element: HTMLElement) {
  const events = ['onMouseEnter', 'onMouseLeave'];

  function dispatchEvent(event, eventType, nodeProps) {
    if (nodeProps[eventType]) nodeProps[eventType](event);
    else if (nodeProps.children && nodeProps.children.forEach)
      nodeProps.children.forEach(child => {
        child.props && dispatchEvent(event, eventType, child.props);
      });
  }

  function findReactHandlers(node) {
    let handlers;

    for (const key in node)
      if (
        node.hasOwnProperty(key) &&
        key.indexOf('_reactEventHandlers') !== -1
      ) {
        handlers = node[key];
        break;
      }

    return handlers;
  }

  events.forEach(eventType => {
    const transformedEventType = eventType.replace(/^on/, '').toLowerCase();
    element.addEventListener(transformedEventType, event => {
      const eventPath =
        event?.path || (event?.composedPath && event?.composedPath());
      for (const i in eventPath) {
        const node = eventPath[i];
        const internalComponent = findReactHandlers(node);
        if (internalComponent)
          dispatchEvent(event, eventType, internalComponent);

        if (node == element) break;
      }
    });
  });
}

/**
 * Checks whether an element is in shadow dom
 * @param {HTMLElement}  element - The element you want to check is in shadow
 * @returns boolean
 */
export function isInShadowDom(element): boolean {
  return element?.getRootNode() instanceof ShadowRoot;
}
