import {
  LineItemV1,
  FormPageStateV1,
} from '@amzn/d16g-state-management-interfaces';
import { RodeoLocalizationContext } from '@amzn/d16g-state-management-interfaces/dist/Common';
import { Validation, Common, MFEState } from '@mfe/state-management-interfaces';

/**
 * Type of page (Line Item vs. Proposal)
 */
export enum PageType {
  LINE_ITEM,
  PROPOSAL,
  UNKNOWN,
}

/**
 * Page state model for the Line Item/Proposal Create/Edit pages.
 * Supports only a lineItemV1.
 */
export interface LineItemPageState extends MFEState.MFEComponentPageState {
  lineItemV1?: Common.DeepPartial<LineItemV1.LineItemModel>;
  lineItemValidationV1?: Validation.ValidationDeepPartial<LineItemV1.LineItemModel>;
  readonly context: Common.DeepPartial<LineItemV1.LineItemContext>;
  readonly formSubmitEvent: string;
  readonly formSubmitErrors: Common.DeepPartial<FormPageStateV1.ErrorResponse>;
  readonly rodeoLocalizationContext?: Common.DeepPartial<RodeoLocalizationContext>;
  timezone: string;
  pageType?: PageType;
}

/**
 * Page state model for the Line Item/Proposal Create/Edit pages.
 * Supports both a lineItemV1 and a proposalV1.
 */
export interface LineItemProposalPageState extends LineItemPageState {
  proposalV1?: Common.DeepPartial<LineItemV1.LineItemModel>;
  proposalValidationV1?: Validation.ValidationDeepPartial<LineItemV1.LineItemModel>;
}
