import {
  IServerSideGetRowsParams,
  IServerSideDatasource,
} from 'ag-grid-community';
import { SuggestionsDataClient } from 'api/SuggestionsClient';
import { SUGGESTIONS_FAILED_MESSAGE_BODY } from '../../../constants/translations';

export const handleError = (error: any) => {
  // Handle network error and server unreachable
  if (!error?.response || error?.response?.status === 503)
    return SUGGESTIONS_FAILED_MESSAGE_BODY;
  // FIXME: Add error handling for all error scenarios once api starts sending errorCode
  return '';
};

export class SuggestionsDataSource implements IServerSideDatasource {
  private readonly dataClient: SuggestionsDataClient;
  private setSucceded: Function = () => {};
  private setErrorMessage: Function = () => {};

  public constructor(
    dataClient: SuggestionsDataClient,
    setSucceded: Function,
    setErrorMessage: Function
  ) {
    this.dataClient = dataClient;
    this.setSucceded = setSucceded;
    this.setErrorMessage = setErrorMessage;
  }

  async getRows({
    api,
    request,
    success,
    fail,
  }: IServerSideGetRowsParams): Promise<any> {
    let response = null;
    try {
      setTimeout(() => api?.showLoadingOverlay());

      response = await this.dataClient.getSuggestedAudiences(
        request?.filterModel?.category?.values
      );

      if (response.topAudiences) {
        this.setSucceded(!!response.topAudiences?.length);
        // inform successful request
        success({
          rowData: response.topAudiences,
          rowCount: response.topAudiences.length,
        });
        api.hideOverlay();
        // Hide overlay hides both loading and no rows overlay
        // So we need to show no rows overlay if there are no records
        // it is recommended to wrap no rows overlay with a setTimeout to avoid racing condition of Ag-grid default.
        // Documentation link: https://tropical.advertising.amazon.dev/tropical-storybook/index.html?path=/story/unified-data-table-common-features--empty-state
        if (!response?.topAudiences?.length)
          setTimeout(() => api.showNoRowsOverlay(), 100);
      } else {
        // inform fail request
        this.setSucceded(false);
        fail();
      }
      this.setErrorMessage('');
    } catch (error) {
      console.error('Error fetching rows for suggestions table: ', error);
      this.setSucceded(false);
      this.setErrorMessage(handleError(error));
      setTimeout(() => api.showNoRowsOverlay(), 100);
      fail();
    }
  }
}
