import React, { useState, useEffect, useContext } from 'react';
import { Dropdown, DropdownItem } from '@amzn/storm-ui';
import { DropdownFilterItem } from '⏎··/../models/FilterSet';
import { UnifiedDataTableApiContext } from '@amzn/unified-data-table-components/core';

type FilterDropDownProps = {
  /**
   * Callback method to get which option was selected from this list
   */
  getSelectedOption: (selectedOption: string) => void;
  /**
   * List of categories to be shown in the dropdown
   */
  allowedFilterCategories?: string[];
  /**
   * Whether to show the "All" filter or just the list of allowedFilterCategories
   */
  removeAllFilter?: string;
};

const ALL = 'All';

/**
 * Component to show a dropdown with categories that were set in the allowedFilterCategories prop.
 */
export const FilterDropdown = ({
  getSelectedOption,
  allowedFilterCategories,
  removeAllFilter,
}: FilterDropDownProps) => {
  const [filterChoice, setFilterChoice] = useState<string>('All');
  const apiContext = useContext(UnifiedDataTableApiContext);
  const { api } = apiContext;

  const DEFAULT_DROPDOWN_FILTER_ITEM: DropdownFilterItem = {
    label: removeAllFilter ?? ALL,
    value: removeAllFilter ?? ALL,
  };

  const createDropdownItems = (categories?: string[]): DropdownFilterItem[] => {
    const dropDownItem: DropdownFilterItem[] = removeAllFilter
      ? []
      : [DEFAULT_DROPDOWN_FILTER_ITEM];
    if (categories)
      categories.forEach((category: string) => {
        dropDownItem.push({
          label: category,
          value: category,
        });
      });

    return dropDownItem;
  };

  useEffect(() => {
    // If the component still exists but the categories change, make sure to reset back to default
    setFilterChoice(DEFAULT_DROPDOWN_FILTER_ITEM.value);
  }, [allowedFilterCategories]);

  const handleChange = (selectedOption: string) => {
    api?.paginationGoToFirstPage();
    setFilterChoice(selectedOption);

    // All is not a real option for the api it defaults to '', but that is not a valid value for the dropdown
    if (selectedOption === ALL) getSelectedOption('');
    else getSelectedOption(selectedOption);
  };

  return (
    <Dropdown
      onChange={handleChange}
      selectedValue={filterChoice}
      data-testid={'filter-dropdown-component'}
    >
      {createDropdownItems(allowedFilterCategories).map(({ label, value }) => (
        <DropdownItem value={value}>{label}</DropdownItem>
      ))}
    </Dropdown>
  );
};

export default FilterDropdown;
