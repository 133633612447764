import React, { useContext } from 'react';
import { Button, Snackbar, Text, Tooltip } from '@amzn/storm-ui';
import {
  AddFilterSelector,
  FilterBar,
  MultiSelectFilter,
  PanelGroup,
  UnifiedDataTableApiContext,
} from '@amzn/unified-data-table-components/core';
import { useUnifiedNotification } from '@amzn/unified-notification-components/core';

import { useAudienceTargeting } from '../../../state/useAudienceTargeting';
import { TranslationContext } from '../../../state/translationContext';

import {
  INCLUDE_AUDIENCE_ERROR,
  ADD_SUGGESTIONS_DISABLED_TOOLTIP,
  AUDIENCE_CATEGORY,
  BULK_INCLUDE,
  BULK_INCLUDE_DISABLED_TOOLTIP,
  ADD_AUDIENCE_ERROR,
  BULK_ADD,
  BULK_ADD_DISABLED_TOOLTIP,
  INCLUDE_SUGGESTIONS_DISABLED_TOOLTIP,
} from '../../../constants/translations';
import {
  CATEGORY_COL_ID,
  CATEGORY_FILTERS_VALUE_LABEL,
  NOTIFICATION_TIMEOUT,
} from '../../../constants/suggestion';

import {
  DisabledBtn,
  FilterContainer,
  HeaderPanel,
  TableHeaderContainer,
} from '../AudienceSuggestions.styled';
import { AudienceDataClient } from '../../../api';
import capitalize from 'lodash-es/capitalize';
import { isSelectedGroupExclude } from '../../../utils/audienceSuggestionUtils';
import {
  TaktLoggingContext,
  TaktLoggingContextType,
} from '../../../state/taktLoggingContext';

interface HeaderProps {
  selectedRows: any[];
  audienceDataClient: AudienceDataClient;
  useLegacy: boolean;
}

export const Header = ({
  selectedRows,
  audienceDataClient,
  useLegacy,
}: HeaderProps) => {
  const getTranslation = useContext(TranslationContext);
  const { state, bulkAddAudienceToGroup } = useAudienceTargeting();
  const extractAudienceId = () => selectedRows.map(row => row.id);

  const notificationApi = useUnifiedNotification();
  const apiContext = useContext(UnifiedDataTableApiContext);
  const { api } = apiContext;

  const { addToSuggestedSegments } = useContext<TaktLoggingContextType>(
    TaktLoggingContext
  );

  const handleError = () => {
    api?.hideOverlay();

    const id = 'addAudienceErrorSnackbar';
    notificationApi.addNotification(
      id,
      <Snackbar id={id} type="error">
        {getTranslation(
          useLegacy ? INCLUDE_AUDIENCE_ERROR : ADD_AUDIENCE_ERROR
        )}
      </Snackbar>
    );
    setTimeout(() => {
      notificationApi.hideNotification(id);
    }, NOTIFICATION_TIMEOUT);
  };

  const bulkAdd = async () => {
    try {
      api?.showLoadingOverlay();
      const response = await audienceDataClient.getAudienceById(
        extractAudienceId()
      );
      if (response?.audiences?.length) {
        bulkAddAudienceToGroup(response.audiences);

        selectedRows.map(selectedRow => {
          addToSuggestedSegments({
            canonicalId: selectedRow.id,
            affinity: selectedRow.insightMetrics.affinity,
            overlapPercentage: selectedRow.insightMetrics.overlapPercentage,
            forecastDailyReach: selectedRow.forecast.dailyReach,
          });
        });
      } else {
        console.error('Error occured while fetching audience object');
        handleError();
      }
    } catch (error) {
      console.error('Error occured while adding overlapping audience: ', error);
      handleError();
    }
  };

  const excludeSelected = isSelectedGroupExclude(
    state.audienceTargeting.targetingGroups[0],
    state.selectedGroup
  );

  const filterOptions = CATEGORY_FILTERS_VALUE_LABEL.map(
    ({ label, value }) => ({
      label: capitalize(getTranslation(label)),
      value,
    })
  );

  const filterGroups = [
    {
      colId: CATEGORY_COL_ID,
      filterName: capitalize(getTranslation(AUDIENCE_CATEGORY)),
      filterComponent: MultiSelectFilter,
      filterComponentProps: {
        filterOptions,
        hasSelectAll: true,
      },
    },
  ];

  return (
    <TableHeaderContainer id={'suggestions-header-panel'}>
      <FilterBar filterGroups={filterGroups} />
      <HeaderPanel>
        <FilterContainer>
          <AddFilterSelector filterGroups={filterGroups} />
        </FilterContainer>
        <PanelGroup align="start">
          {!selectedRows.length || excludeSelected ? (
            <Tooltip
              type="dark"
              trigger={
                <DisabledBtn data-testid="bulk-add-disabled">
                  <Text>
                    {getTranslation(useLegacy ? BULK_INCLUDE : BULK_ADD)}
                  </Text>
                </DisabledBtn>
              }
              message={
                excludeSelected
                  ? getTranslation(
                      useLegacy
                        ? INCLUDE_SUGGESTIONS_DISABLED_TOOLTIP
                        : ADD_SUGGESTIONS_DISABLED_TOOLTIP
                    )
                  : getTranslation(
                      useLegacy
                        ? BULK_INCLUDE_DISABLED_TOOLTIP
                        : BULK_ADD_DISABLED_TOOLTIP
                    )
              }
            />
          ) : (
            <Button onClick={bulkAdd} transparent={true}>
              {getTranslation(useLegacy ? BULK_INCLUDE : BULK_ADD)}
            </Button>
          )}
        </PanelGroup>
      </HeaderPanel>
    </TableHeaderContainer>
  );
};
