import { useContext, useMemo } from 'react';
import {AudienceDisplayFee, CustomElementSegment, DisplayFee, Fee, ThirdPartyAudienceDisplayFee} from "src/model";
import { FeeContext } from "src/context/FeeContext";
import { useStore } from "zustand";
import {
    EMPTY_DISPLAY_FEE,
    MISSING_FEE_STORE_CONTEXT,
} from "src/constants";
import {formatDisplayFee} from "src/utils/FeeUtil";
import {retrieveAllAudiences} from "src/store/selectors/selectors";

export default function useAudienceFee(audience?: CustomElementSegment) {
    const feeStore = useContext(FeeContext);

    if (!feeStore) {
        throw new Error(MISSING_FEE_STORE_CONTEXT);
    }

    const observedAudienceFee: DisplayFee | undefined = useStore(feeStore, (state) => {
        if (!audience || !state) {
            return EMPTY_DISPLAY_FEE as DisplayFee;
        }
        const id = audience.audienceId || audience.canonicalId;

        const allAudiences = retrieveAllAudiences(state);
        if (allAudiences.has(id)) {
            return formatDisplayFee(allAudiences.get(id) as unknown as AudienceDisplayFee | ThirdPartyAudienceDisplayFee);
        }

        return EMPTY_DISPLAY_FEE as DisplayFee;
    })

    const observeAudiencesForPricing = useStore(feeStore, (state) => state.observeAudiencesForPricing);

    return {
        observeAudiencesForPricing,
        observedAudienceFee
    };
}