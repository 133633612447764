import React, { useContext, useState } from 'react';
import { useAudienceTargeting } from '../../../state/useAudienceTargeting';
import {
  Audience,
  AudienceGroupIndex,
  TargetingGroupIndex,
} from '../../../models';
import AudienceGroupComponent from '../../../components/AudienceTargeting/AudienceGroup/AudienceGroupComponent';
import { AudienceGroup } from '../../../models/AudienceTargeting';
import { IAudienceTargetingContext } from '../../../state/audienceTargetingContext';
import { Text, Icon, TextButton } from '@amzn/storm-ui';
import { IncludeExcludeGroupComponentStyled } from './IncludeExcludeGroupComponent.styled';
import { TranslationContext } from '../../../state/translationContext';
import { ADDED_AUDIENCES, ADD } from '../../../constants/translations';
import {
  TaktLoggingContext,
  TaktLoggingContextType,
} from '../../../state/taktLoggingContext';

type IncludeExcludeProps = {
  /**
   * Index of the targeting group
   */
  targetingGroupIndex: TargetingGroupIndex;
  /**
   * Whether to show the "legacy" logic version of the picker, or the "include/exclude" logic version of the picker.
   */
  useLegacy?: boolean;
  /**
   * List of feeSupplyType(s) that dictate which fee to use from a given audience segment
   */
  feeSupplyType?: string[];
  /**
   * The maximum number of segments that can be added to a group before an error is shown and no more audiences can be added to that group.
   */
  maxSegmentsPerGroup?: number;
  /**
   * The maximum number of groups that can be added before the "add" button disappears.
   */
  maxIncludeGroups?: number;
};

/**
 * Container component for a list of audienceGroups
 */
const IncludeExcludeGroupComponent = ({
  targetingGroupIndex,
  feeSupplyType,
  useLegacy,
  maxSegmentsPerGroup,
  maxIncludeGroups,
}: IncludeExcludeProps) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const {
    state,
    removeAudienceFromGroup,
    removeAudienceGroup,
    addAudienceGroup,
    intersetOperator,
    intrasetOperator,
    selectAudienceGroup,
  }: IAudienceTargetingContext = useAudienceTargeting();

  const getTranslation = useContext(TranslationContext);

  const { removeFromSuggestedSegments } = useContext<TaktLoggingContextType>(
    TaktLoggingContext
  );

  if (!targetingGroupIndex) return null;

  if (
    !state?.audienceTargeting?.targetingGroups[
      targetingGroupIndex.targetingGroupIndex
    ]
  )
    return null;

  const audienceGroups: AudienceGroup[] =
    state?.audienceTargeting?.targetingGroups[
      targetingGroupIndex.targetingGroupIndex
    ][targetingGroupIndex.targetingGroupType];

  const showAddButton = (): boolean =>
    targetingGroupIndex.targetingGroupType !== 'exclude' &&
    (maxIncludeGroups ? audienceGroups.length < (maxIncludeGroups || 0) : true);

  const buildAudienceGroupComponent = (
    audienceGroup: AudienceGroup,
    index: number
  ) => {
    const audienceGroupIndex: AudienceGroupIndex = {
      targetingGroupType: targetingGroupIndex.targetingGroupType,
      targetingGroupIndex: targetingGroupIndex.targetingGroupIndex,
      audienceGroupIndex: index,
    };
    const targetingGroupSelected =
      state.selectedGroup.targetingGroupIndex ===
      audienceGroupIndex.targetingGroupIndex;
    const targetingTypeSelected =
      state.selectedGroup.targetingGroupType ===
      audienceGroupIndex.targetingGroupType;
    const audienceGroupSelected =
      state.selectedGroup.audienceGroupIndex ===
      audienceGroupIndex.audienceGroupIndex;

    const removeAudienceFromGroupCallback = (segment: Audience) => {
      removeAudienceFromGroup({
        ...audienceGroupIndex,
        segment,
      });
    };

    const handleIntrasetChange = (term: string) => {
      intrasetOperator(term, audienceGroupIndex);
    };

    const handleIntersetChange = (term: string) => {
      intersetOperator(term, audienceGroupIndex);
    };

    const removeAudienceGroupCallback = (event: any) => {
      event.stopPropagation();
      const audienceGroupSegments =
        state.audienceTargeting.targetingGroups[
          audienceGroupIndex.targetingGroupIndex
        ].include[audienceGroupIndex.audienceGroupIndex].segments;
      removeAudienceGroup(audienceGroupIndex);
      audienceGroupSegments.forEach(row => {
        removeFromSuggestedSegments(row.audienceId);
      });
    };

    const isSelected = (): boolean =>
      targetingGroupSelected && targetingTypeSelected && audienceGroupSelected;

    const disableRemove =
      index === 0 &&
      audienceGroup?.segments?.length === 0 &&
      audienceGroups.length === 1;

    return (
      <div key={index} data-testid={'audience-group'}>
        <AudienceGroupComponent
          useLegacy={useLegacy}
          audienceGroup={audienceGroup}
          isSelected={isSelected()}
          removeSegmentFromGroup={removeAudienceFromGroupCallback}
          intrasetChange={handleIntrasetChange}
          intersetChange={handleIntersetChange}
          removeGroup={removeAudienceGroupCallback}
          disableRemove={disableRemove}
          audienceGroupCount={audienceGroups.length}
          audienceGroupIndex={audienceGroupIndex}
          selectAudienceGroup={selectAudienceGroup}
          feeSupplyType={feeSupplyType}
          maxSegmentsPerGroup={maxSegmentsPerGroup}
        />
      </div>
    );
  };

  const getGroupHeader = () =>
    useLegacy
      ? getTranslation(ADDED_AUDIENCES)
      : targetingGroupIndex.targetingGroupType;

  return (
    <IncludeExcludeGroupComponentStyled.Container>
      <IncludeExcludeGroupComponentStyled.TitleRow>
        <Text type={'h5'}>{getGroupHeader()}</Text>
        <Icon
          style={{ cursor: 'pointer' }}
          onClick={() => setIsExpanded(!isExpanded)}
          type={isExpanded ? 'angle-up' : 'angle-down'}
        />
      </IncludeExcludeGroupComponentStyled.TitleRow>
      {isExpanded && (
        <>
          {audienceGroups.map((audienceGroup, index) =>
            buildAudienceGroupComponent(audienceGroup, index)
          )}
          {showAddButton() && (
            <TextButton
              onClick={() => addAudienceGroup(targetingGroupIndex)}
              data-testid={'add-button'}
            >
              + {getTranslation(ADD)}
            </TextButton>
          )}
        </>
      )}
    </IncludeExcludeGroupComponentStyled.Container>
  );
};

export default IncludeExcludeGroupComponent;
