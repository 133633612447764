import React, { useContext } from 'react';
import { Text } from '@amzn/storm-ui';
import { TranslationContext } from '../../../state/translationContext';
import {
  INCLUDE_AUDIENCE_TO_VIEW_SUGGESTIONS,
  INCOMPATIBLE_AUDIENCE_ERROR_MESSAGE,
  NO_SUGGESTED_AUDIENCE,
  NO_SUGGESTIONS_AVAILABLE,
  SUGGESTIONS_FAILED_MESSAGE,
  SUGGESTIONS_FAILED_MESSAGE_BODY,
} from '../../../constants/translations';
import {
  NoSuggestionsContainer,
  NoSuggestionsText,
} from './NoSuggestions.styled';

interface noRowsOverlayProps {
  /**
   * Whether the API call for a list of audiences succeeded or failed
   */
  noSegmentIncluded?: boolean;
  audienceIncompatible?: boolean;
  errorMessage?: string;
}

/**
 * Overlay on the audience table to show either an error message or a message that no audiences were found.
 */
export const NoSuggestions = ({
  noSegmentIncluded,
  audienceIncompatible,
  errorMessage,
}: noRowsOverlayProps) => {
  const getTranslation = useContext(TranslationContext);

  const getTitle = () => {
    if (errorMessage === SUGGESTIONS_FAILED_MESSAGE_BODY)
      return SUGGESTIONS_FAILED_MESSAGE;
    else if (noSegmentIncluded || audienceIncompatible || !!errorMessage)
      return NO_SUGGESTED_AUDIENCE;
    // default return value
    return NO_SUGGESTIONS_AVAILABLE;
  };

  return (
    <div>
      <NoSuggestionsContainer>
        <NoSuggestionsText type="h4">
          {getTranslation(getTitle())}
        </NoSuggestionsText>
        <Text type="p">
          {!!errorMessage && getTranslation(errorMessage)}
          {noSegmentIncluded &&
            getTranslation(INCLUDE_AUDIENCE_TO_VIEW_SUGGESTIONS)}
          {audienceIncompatible &&
            getTranslation(INCOMPATIBLE_AUDIENCE_ERROR_MESSAGE)}
        </Text>
      </NoSuggestionsContainer>
    </div>
  );
};
