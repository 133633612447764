// @ts-nocheck
export enum Weblabs {
  UI_REFACTOR = 'ADSP_PRICING_AUDIENCE_PICKER_REFACTOR_998528',
  APM_VIDEO_PRICING_AUDIENCE_PICKER_DEAL_BASED_1P_AUDIENCES = 'APM_VIDEO_PRICING_AUDIENCE_PICKER_DEAL_BASED_1P_AUDIENCES_1025823',
  ADSP_PRICING_UI_REFACTOR = 'ADSP_PRICING_AUDIENCE_PICKER_REFACTOR_998528',
  PATHFINDER_SEARCH = 'PATHFINDER_979251',
}

const excludedLineItems = ['AUDIO_GUARANTEED', 'OTT_VIDEO'];

/**
 * Check the active state of weblab whose state has been cached in the browser.
 * @param weblab - weblab to check
 * @param treatment - level of treatment to apply weblab
 * @returns boolean representing the active state of the weblab.
 */
export function isWeblabActive(weblab: Weblabs, treatment = 'T1') {
  return (
    window.weblabTreatments && window.weblabTreatments[weblab] === treatment
  );
}
