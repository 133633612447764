export const BETA_IDENTIFIER = 'integ.amazon';
export const GAMMA_IDENTIFIER = 'preprod.amazon';
export const PROD_IDENTIFIER = 'advertising.amazon';

export const JP_IDENTIFIER = [
  'advertising-jp',
  'amazon.co.jp',
  'advertising-au',
  'amazon.com.au',
];

export const NA_IDENTIFIER = [
  'advertising-ca',
  'amazon.ca',
  'advertising-mx',
  'amazon.com.mx',
  'advertising.integ.amazon.com',
  'advertising-preprod.amazon.com',
  'advertising.amazon.com',
];
