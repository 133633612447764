import React, { useContext, useEffect, useState } from 'react';
import { IAudienceTargetingContext } from '../../state/audienceTargetingContext';
import { useAudienceTargeting } from '../../state/useAudienceTargeting';
import { Icon, Text } from '@amzn/storm-ui';
import {
  AudienceFeeContainer,
  AudienceFeeContents,
  AudienceFeeValueContainer,
  PriceSummaryContainer,
} from './PriceSummary.style';
import { formatAudienceFee } from '../Table/renderers/FeeRenderer';
import { TranslationContext } from '../../state/translationContext';
import {
  AMAZON_AUDIENCES,
  AUDIENCE_FEE,
  THIRD_PARTY_AUDIENCES,
} from '../../constants/translations';
import { convertStateToSegmentList } from '../../utils/audienceTargetingConverter';
import { Audience } from '../../models';
import { calculateFee } from '../../utils/FeeCalculationUtil';
import {
  CombinedAudienceClient,
  CustomElementSegment,
  useMaxAudiencesFees,
} from '@amzn/d16g-pricing-react-utils';
import { isWeblabActive, Weblabs } from '../../utils/weblabUtil';
import { compareAndPrintForShadowMode } from '../../utils/pricingUtil';

interface PriceSummaryProps {
  /**
   * List of feeSupplyType(s) that dictate which fee to use from a given audience segment
   */
  feeSupplyType: string[];
  /**
   *  User's Currency of preference
   */
  currencyOfPreference?: string;

  /**
   *  Conversion rate(multiplication factor) from base currency to the currency of preference.
   */
  fxConversionRate?: number;
  targetedAudienceSummary: boolean;
  highLevelFilters?: string[];
}

const defaultProps: PriceSummaryProps = {
  targetedAudienceSummary: false,
  feeSupplyType: [],
};

/**
 * Component that shows the first-party and third-party fees from the audiences that are selected and in the state.
 */
const PriceSummary = ({
  currencyOfPreference,
  fxConversionRate,
  feeSupplyType,
  targetedAudienceSummary,
}: PriceSummaryProps) => {
  const { state }: IAudienceTargetingContext = useAudienceTargeting();
  const [amazonTotal, setAmazonTotal] = useState<number[]>([]);
  const [thirdPartyTotal, setThirdPartyTotal] = useState<number[]>([]);
  const [feeTotal, setFeeTotal] = useState<number[]>([]);
  const [currencyType, setCurrencyType] = useState<string>('');
  const getTranslation = useContext(TranslationContext);

  const getTotals = (segments: Audience[] = []) => {
    const totals = calculateFee(segments, feeSupplyType);

    if (totals.currencyType) setCurrencyType(totals.currencyType);

    if (JSON.stringify(totals.amazonTotal) !== JSON.stringify(amazonTotal))
      setAmazonTotal(totals.amazonTotal);

    if (
      JSON.stringify(totals.thirdPartyTotal) !== JSON.stringify(thirdPartyTotal)
    )
      setThirdPartyTotal(totals.thirdPartyTotal);

    if (JSON.stringify(totals.feeTotal) !== JSON.stringify(feeTotal))
      setFeeTotal(totals.feeTotal);
  };

  useEffect(() => {
    let isCancelled = false;
    CombinedAudienceClient.getInstance()
      .replaceCombinedAudiencesWithTheirUnderlyingAudiences(
        convertStateToSegmentList(state),
        true
      )
      .then(audiences => {
        if (!isCancelled) getTotals(audiences as Audience[]);
      });

    return () => {
      isCancelled = true;
    };
  }, [state, getTotals]);

  const {
    observedMaxFirstPartyFee,
    observedMaxThirdPartyFee,
    observedTotalFee,
  } = useMaxAudiencesFees(
    (convertStateToSegmentList(state) as any) as CustomElementSegment[]
  );

  const legacyAudienceTotalFee = formatAudienceFee(feeTotal, currencyType);
  let legacyAudienceTotalFeeWithCop;
  if (fxConversionRate)
    legacyAudienceTotalFeeWithCop = formatAudienceFee(
      feeTotal.map(fee => fee * fxConversionRate),
      currencyOfPreference
    );
  const legacyFirstPartyTotalFee = formatAudienceFee(amazonTotal, currencyType);
  const legacyThirdPartyTotalFee = formatAudienceFee(
    thirdPartyTotal,
    currencyType
  );

  const isCop: boolean = !!currencyOfPreference;
  const isfxRate: boolean = !!fxConversionRate;
  let shouldDisplayCop: boolean = isCop && isfxRate;

  if (isWeblabActive(Weblabs.ADSP_PRICING_UI_REFACTOR)) {
    compareAndPrintForShadowMode(
      state,
      legacyAudienceTotalFee,
      observedTotalFee,
      legacyAudienceTotalFeeWithCop
    );

    compareAndPrintForShadowMode(
      state,
      legacyFirstPartyTotalFee,
      observedMaxFirstPartyFee
    );

    compareAndPrintForShadowMode(
      state,
      legacyThirdPartyTotalFee,
      observedMaxThirdPartyFee
    );
  }

  const isUIWeblabRefactorOn = isWeblabActive(
    Weblabs.ADSP_PRICING_UI_REFACTOR,
    'T2'
  );

  const isTotalFeeLoading = isUIWeblabRefactorOn
    ? observedTotalFee?.isLoading
    : false;
  const isFirstPartyFeeLoading = isUIWeblabRefactorOn
    ? observedMaxFirstPartyFee?.isLoading
    : false;
  const isThirdPartyFeeLoading = isUIWeblabRefactorOn
    ? observedMaxThirdPartyFee?.isLoading
    : false;

  const totalFeeValue = isUIWeblabRefactorOn
    ? observedTotalFee?.displayValue
    : legacyAudienceTotalFee;
  const copTotalFeeValue = isUIWeblabRefactorOn
    ? observedTotalFee?.copDisplayValue
    : legacyAudienceTotalFeeWithCop;
  const firstPartyFeeValue = isUIWeblabRefactorOn
    ? observedMaxFirstPartyFee?.displayValue
    : legacyFirstPartyTotalFee;
  const thirdPartyFeeValue = isUIWeblabRefactorOn
    ? observedMaxThirdPartyFee?.displayValue
    : legacyThirdPartyTotalFee;

  shouldDisplayCop = isUIWeblabRefactorOn
    ? shouldDisplayCop && !observedTotalFee.isLoading
    : shouldDisplayCop;

  return (
    <PriceSummaryContainer
      data-testid={'price-summary-component'}
      id={'price-summary-component'}
      targetedAudienceSummary={targetedAudienceSummary}
    >
      <AudienceFeeContainer targetedAudienceSummary={targetedAudienceSummary}>
        <Text type={targetedAudienceSummary ? 'p' : 'h4'}>
          {getTranslation(AUDIENCE_FEE)}
        </Text>
        <AudienceFeeValueContainer>
          {isTotalFeeLoading ? (
            <Icon type={'spinner'} size="sm" style={{ opacity: '0.5' }} />
          ) : (
            <Text>{totalFeeValue}</Text>
          )}
          {shouldDisplayCop && (
            <Text
              data-testid={'price-summary-cop-component'}
              textColor="tertiary"
              fontSize="mini"
            >
              {`(${fxConversionRate && copTotalFeeValue})`}
            </Text>
          )}
        </AudienceFeeValueContainer>
      </AudienceFeeContainer>
      <AudienceFeeContents targetedAudienceSummary={targetedAudienceSummary}>
        <Text>{getTranslation(AMAZON_AUDIENCES)}</Text>
        {isFirstPartyFeeLoading ? (
          <Icon type={'spinner'} size="sm" style={{ opacity: '0.5' }} />
        ) : (
          <Text>{firstPartyFeeValue}</Text>
        )}
      </AudienceFeeContents>
      <AudienceFeeContents
        targetedAudienceSummary={targetedAudienceSummary}
        style={{ display: 'flex' }}
      >
        <Text>{getTranslation(THIRD_PARTY_AUDIENCES)}</Text>
        {isThirdPartyFeeLoading ? (
          <Icon type={'spinner'} size="sm" style={{ opacity: '0.5' }} />
        ) : (
          <Text>{thirdPartyFeeValue}</Text>
        )}
      </AudienceFeeContents>
    </PriceSummaryContainer>
  );
};

PriceSummary.defaultProps = defaultProps;

export default PriceSummary;
