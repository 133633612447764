import { AudienceTargeting } from '../models/AudienceTargeting';
import update from 'immutability-helper';
import { Audience, AudienceGroupIndex } from '../models';
import { AudienceGroupAction } from '../state/actions';
import { AND, INCLUDE, OR } from '../constants/expressions';

export const ADD_AUDIENCE_TO_GROUP = 'ADD_AUDIENCE_TO_GROUP';
export const BULK_ADD_AUDIENCE_TO_GROUP = 'BULK_ADD_AUDIENCE_TO_GROUP';
export const REMOVE_AUDIENCE_FROM_GROUP = 'REMOVE_AUDIENCE_FROM_GROUP';
export const REMOVE_AUDIENCE_GROUP = 'REMOVE_AUDIENCE_GROUP';
export const ADD_AUDIENCE_GROUP = 'ADD_AUDIENCE_GROUP';
export const SELECT_AUDIENCE_GROUP = 'SELECT_AUDIENCE_GROUP';
export const CHANGE_INTERSET = 'CHANGE_INTERSET';
export const CHANGE_INTRASET = 'CHANGE_INTRASET';

export type AudiencePickerState = {
  audienceTargeting: AudienceTargeting;
  selectedGroup: AudienceGroupIndex;
  incompatibleAudience: AudienceTargeting | null;
};

export const defaultSelectedGroup: AudienceGroupIndex = {
  targetingGroupIndex: 0,
  targetingGroupType: INCLUDE,
  audienceGroupIndex: 0,
};

export const audiencePickerInitialState: AudiencePickerState = {
  audienceTargeting: {
    targetingGroups: [
      {
        intersetOperator: AND,
        include: [
          {
            intersetOperator: AND,
            intrasetOperator: OR,
            segments: [],
          },
        ],
        exclude: [
          {
            intersetOperator: AND,
            intrasetOperator: OR,
            segments: [],
          },
        ],
      },
    ],
  },
  selectedGroup: defaultSelectedGroup,
  incompatibleAudience: null,
};

export const audienceTargetingReducer = (
  state: AudiencePickerState = audiencePickerInitialState,
  action: AudienceGroupAction
): AudiencePickerState => {
  switch (action.type) {
    case ADD_AUDIENCE_TO_GROUP:
      if (!state?.selectedGroup) {
        console.error('No audience group has been selected.');
        return state;
      }

      return update(state, {
        audienceTargeting: {
          targetingGroups: {
            [state?.selectedGroup.targetingGroupIndex]: {
              [state?.selectedGroup.targetingGroupType]: {
                [state?.selectedGroup.audienceGroupIndex]: {
                  segments: { $push: [action.audience] },
                },
              },
            },
          },
        },
      });
    case BULK_ADD_AUDIENCE_TO_GROUP:
      if (!state?.selectedGroup) {
        console.error('No audience group has been selected.');
        return state;
      }

      return update(state, {
        audienceTargeting: {
          targetingGroups: {
            [state?.selectedGroup.targetingGroupIndex]: {
              [state?.selectedGroup.targetingGroupType]: {
                [state?.selectedGroup.audienceGroupIndex]: {
                  segments: { $push: [...action.audienceList] },
                },
              },
            },
          },
        },
      });
    case REMOVE_AUDIENCE_FROM_GROUP:
      return update(state, {
        audienceTargeting: {
          targetingGroups: {
            [action.segmentToRemove.targetingGroupIndex]: {
              [action.segmentToRemove.targetingGroupType]: {
                [action.segmentToRemove.audienceGroupIndex]: {
                  segments: {
                    $apply: (segments: Audience[]) => {
                      const segmentIndex = segments.findIndex(
                        segment =>
                          segment.audienceId ===
                          action.segmentToRemove.segment.audienceId
                      );
                      return update(segments, {
                        $splice: [[segmentIndex, 1]],
                      });
                    },
                  },
                },
              },
            },
          },
        },
      });
    case ADD_AUDIENCE_GROUP:
      return update(state, {
        audienceTargeting: {
          targetingGroups: {
            [action.indexToAddGroup.targetingGroupIndex]: {
              [action.indexToAddGroup.targetingGroupType]: {
                $push: [
                  {
                    intersetOperator: AND,
                    intrasetOperator: OR,
                    segments: [],
                  },
                ],
              },
            },
          },
        },
        selectedGroup: {
          $set: {
            targetingGroupIndex: action.indexToAddGroup.targetingGroupIndex,
            targetingGroupType: action.indexToAddGroup.targetingGroupType,
            audienceGroupIndex:
              state.audienceTargeting.targetingGroups[
                action.indexToAddGroup.targetingGroupIndex
              ][action.indexToAddGroup.targetingGroupType].length,
          },
        },
      });
    case REMOVE_AUDIENCE_GROUP:
      return update(state, {
        audienceTargeting: {
          targetingGroups: {
            [action.indexToRemoveGroup.targetingGroupIndex]: {
              [action.indexToRemoveGroup.targetingGroupType]: {
                $apply: (audienceGroups: any) => {
                  if (audienceGroups.length > 1)
                    // if this is not the last group remove it from the array.
                    return update(audienceGroups, {
                      $splice: [
                        [action.indexToRemoveGroup.audienceGroupIndex, 1],
                      ],
                    });

                  // if this is the last group in the targeting group type clear its contexts instead.
                  return update(audienceGroups, {
                    0: { segments: { $set: [] } },
                  });
                },
              },
            },
          },
        },
        selectedGroup: {
          $apply: selectedGroup => {
            if (
              state.selectedGroup.targetingGroupIndex ===
                action.indexToRemoveGroup.targetingGroupIndex &&
              state.selectedGroup.targetingGroupType ===
                action.indexToRemoveGroup.targetingGroupType &&
              state.selectedGroup.audienceGroupIndex >=
                action.indexToRemoveGroup.audienceGroupIndex
            )
              // If a group was removed in the same targeting group and targeting group type (include/exclude) and the index
              // was <= the audience group to remove, we must shift the index by -1 so that a group is always selected
              // prevent indexes that are negative
              return update(selectedGroup, {
                audienceGroupIndex: {
                  $set:
                    state.selectedGroup.audienceGroupIndex - 1 >= 0
                      ? state.selectedGroup.audienceGroupIndex - 1
                      : 0,
                },
              });
            return selectedGroup;
          },
        },
      });
    case SELECT_AUDIENCE_GROUP:
      return update(state, {
        selectedGroup: { $set: action.indexToSelectGroup },
      });
    case CHANGE_INTERSET:
      return update(state, {
        audienceTargeting: {
          targetingGroups: {
            [action.audienceToUpdate.targetingGroupIndex]: {
              [action.audienceToUpdate.targetingGroupType]: {
                [action.audienceToUpdate.audienceGroupIndex]: {
                  intersetOperator: { $set: action.term },
                },
              },
            },
          },
        },
      });
    case CHANGE_INTRASET:
      return update(state, {
        audienceTargeting: {
          targetingGroups: {
            [action.audienceToUpdate.targetingGroupIndex]: {
              [action.audienceToUpdate.targetingGroupType]: {
                [action.audienceToUpdate.audienceGroupIndex]: {
                  intrasetOperator: { $set: action.term },
                },
              },
            },
          },
        },
      });
    default:
      throw new Error('action type not specified for audienceTargetingReducer');
  }
};
