import { create, State, StateCreator, StoreMutatorIdentifier } from 'zustand'

type Logger = <
    T extends State,
    Mps extends [StoreMutatorIdentifier, unknown][] = [],
    Mcs extends [StoreMutatorIdentifier, unknown][] = [],
>(
    f: StateCreator<T, Mps, Mcs>,
    name?: string,
) => StateCreator<T, Mps, Mcs>

type LoggerImpl = <T extends State>(
    f: StateCreator<T, [], []>,
    name?: string,
) => StateCreator<T, [], []>

const loggerImpl: LoggerImpl = (f, name) => (set, get, store) => {
    type T = ReturnType<typeof f>
    const loggedSet: typeof set = (...a) => {
        set(...a)
        if (isPricingStateAuditorCookieSet()) {
            console.log(...(name ? [`${name}:`] : []), get())
        }
    }

    if (isPricingStateAuditorCookieSet()) {
        const setState = store.setState
        store.setState = (...a) => {
            setState(...a)
            console.log(...(name ? [`${name}:`] : []), store.getState())
        }
    }

    return f(loggedSet, get, store)
}

const isPricingStateAuditorCookieSet = (): boolean => {
    const cookieRegex = /pricing-state-auditor=([^;]+)/;
    const match = document.cookie.match(cookieRegex);
    if (match) {
        const value = match[1];
        return JSON.parse(value) === true;
    }
    return false;
};

export const logger = loggerImpl as unknown as Logger;