import React, { useContext } from 'react';
import { Snackbar, TextButton, Tooltip } from '@amzn/storm-ui';
import { useUnifiedNotification } from '@amzn/unified-notification-components/core';

import {
  TranslationContext,
  capitalize,
} from '../../../state/translationContext';
import {
  INCLUDE_AUDIENCE_ERROR,
  ADD_SUGGESTIONS_DISABLED_TOOLTIP,
  INCLUDE,
  ADD_AUDIENCE_ERROR,
  ADD,
  INCLUDE_SUGGESTIONS_DISABLED_TOOLTIP,
} from '../../../constants/translations';
import { useAudienceTargeting } from '../../../state/useAudienceTargeting';
import { DisabledBtn } from '../AudienceSuggestions.styled';
import { ActionStyle } from './Action.styled';
import { SuggestedAudience } from '../../../models/AudienceSuggestions';
import { AudienceDataClient } from '../../../api';
import {
  TaktLoggingContext,
  TaktLoggingContextType,
} from '../../../state/taktLoggingContext';
import { UnifiedDataTableApiContext } from '@amzn/unified-data-table-components/core';
import { NOTIFICATION_TIMEOUT } from '../../../constants/suggestion';
import { isSelectedGroupExclude } from '../../../utils/audienceSuggestionUtils';

interface ActionRendererProps {
  data: SuggestedAudience;
  audienceDataClient: AudienceDataClient;
  useLegacy: boolean;
}

export const ActionRenderer = ({
  data,
  audienceDataClient,
  useLegacy,
}: ActionRendererProps) => {
  const getTranslation = useContext(TranslationContext);
  const notificationApi = useUnifiedNotification();

  const { state, addAudienceToGroup } = useAudienceTargeting();
  const apiContext = useContext(UnifiedDataTableApiContext);
  const { api } = apiContext;

  const handleError = () => {
    api?.hideOverlay();

    const id = 'addAudienceErrorSnackbar';
    notificationApi.addNotification(
      id,
      <Snackbar id={id} type="error">
        {getTranslation(
          useLegacy ? INCLUDE_AUDIENCE_ERROR : ADD_AUDIENCE_ERROR
        )}
      </Snackbar>
    );
    setTimeout(() => {
      notificationApi.hideNotification(id);
    }, NOTIFICATION_TIMEOUT);
  };

  const addAudienceToInclude = async () => {
    try {
      api?.showLoadingOverlay();
      const response = await audienceDataClient.getAudienceById([data.id]);
      if (response?.audiences?.[0])
        addAudienceToGroup({ ...response.audiences[0], not: false });
      else {
        console.error('Error occured while fetching audience object');
        handleError();
      }
    } catch (error) {
      console.error('Error occured while adding overlapping audience: ', error);
      handleError();
    }
  };

  const { addToSuggestedSegments } = useContext<TaktLoggingContextType>(
    TaktLoggingContext
  );

  const handleAddEvent = () => {
    addAudienceToInclude();
    addToSuggestedSegments({
      canonicalId: data.id,
      affinity: data.insightMetrics.affinity,
      overlapPercentage: data.insightMetrics.overlapPercentage,
      forecastDailyReach: data.forecast.dailyReach,
    });
  };

  const ActionText = capitalize(getTranslation(useLegacy ? INCLUDE : ADD));
  return (
    <ActionStyle>
      {isSelectedGroupExclude(
        state.audienceTargeting.targetingGroups[0],
        state.selectedGroup
      ) ? (
        <Tooltip
          trigger={<DisabledBtn>{ActionText}</DisabledBtn>}
          message={getTranslation(
            useLegacy
              ? INCLUDE_SUGGESTIONS_DISABLED_TOOLTIP
              : ADD_SUGGESTIONS_DISABLED_TOOLTIP
          )}
          type="dark"
        />
      ) : (
        <TextButton size="small" onClick={handleAddEvent}>
          {ActionText}
        </TextButton>
      )}
    </ActionStyle>
  );
};
