import React, { FunctionComponent } from 'react';
import { PredicateGroupView } from './PredicateGroupView';
import {
  PredicateCardsContainer,
  PredicateCardsList,
  PredicateGroupPanel,
} from './PredicateGroups.styled';
import { GroupType, PredicateGroupsProps } from '../Props';

export const PredicateGroupsView: FunctionComponent<PredicateGroupsProps> = ({
  name,
  selectedGroup,
  onSelectGroup,
  targetList,
  excludeList,
  onRemoveAllTargets,
  onRemoveAllExcludes,
  onRemoveItem,
}) => (
  <PredicateGroupPanel>
    <PredicateCardsContainer>
      <PredicateCardsList>
        <PredicateGroupView
          name={'targeted'}
          emptyMessage={'Target ' + name}
          isSelected={selectedGroup === GroupType.Targeted}
          onSelect={() => onSelectGroup(GroupType.Targeted)}
          nodesList={targetList}
          onRemoveAll={onRemoveAllTargets}
          onRemoveItem={onRemoveItem}
        />
        <PredicateGroupView
          name={'excluded'}
          emptyMessage={'Block ' + name}
          isSelected={selectedGroup === GroupType.Excluded}
          onSelect={() => onSelectGroup(GroupType.Excluded)}
          nodesList={excludeList}
          onRemoveAll={onRemoveAllExcludes}
          onRemoveItem={onRemoveItem}
        />
      </PredicateCardsList>
    </PredicateCardsContainer>
  </PredicateGroupPanel>
);
