import { AudienceSegmentsResponse, Fee, FeeTypes } from '../models';
import {
  Price,
  PricingResponseV2,
} from '@amzn/d16g-pricing-shared-client-library/src/models';

const DEFAULT_SCALE = 100000;

/**
 * @function
 * Given the mediaType, return the Fee type that can be put into the fees array of a segment
 * @param {string} mediaType - The media type enum value
 * @param {string} supplyTypes - The supply type from peng
 */
const mapMediaTypeToImpressionType = (
  mediaType?: string,
  supplyTypes?: string[]
): FeeTypes => {
  switch (mediaType) {
    case 'OTT_VIDEO':
      return FeeTypes.OTT_VIDEO;
    case 'STREAMING_AUDIO':
      return FeeTypes.STREAMING_AUDIO;
    case 'VIDEO':
      if (supplyTypes?.includes('STV')) return FeeTypes.STV;
      if (supplyTypes?.includes('PVA')) return FeeTypes.PVA;
      return FeeTypes.VIDEO;
    default:
      return FeeTypes.WEB;
  }
};

/**
 * @function
 * Replaces the fee object with the
 * @param {string} mediaType - The media type enum value
 */
const updateFee = (
  price: Price,
  mediaType?: string,
  supplyTypes?: string[]
) => ({
  amount: Number(price.amount) * DEFAULT_SCALE,
  currency: price.currency,
  scale: DEFAULT_SCALE,
  feeCalculationType: 'CPM',
  impressionSupplyType: mapMediaTypeToImpressionType(mediaType, supplyTypes),
});

/**
 * @function
 * Take the output of the PricingSharedClientLibrary and rehydrate the audience output from the client.
 * @param {AudienceSegmentsResponse} audienceOutput - The value outputted by the getAudienceSegments API call
 * @param {PricingResponse} pricingResponse - The value outputted by the getPricingResponse API call in PricingSharedLibrary
 */
export const rehydratePrices = (
  audienceOutput: AudienceSegmentsResponse,
  pricingResponse: PricingResponseV2
): AudienceSegmentsResponse => {
  // for each audience, replace its fee object with the updated value
  const pricedAudiences = audienceOutput.audiences.map(audience => {
    const updatedFeeList: Fee[] = [];
    // find all corresponding prices with this segment
    const matchedPriceScenarios = pricingResponse.pricingScenarios.filter(
      scenario => scenario?.audience?.id === audience.audienceId
    );

    // if there are no matched prices returned by PENG, which would happen for a third-party segment,
    // just return the audience as is

    if (!matchedPriceScenarios.length) return audience;

    matchedPriceScenarios.forEach(scenario => {
      const maxPrice = scenario?.priceRange?.max;
      const mediaType = scenario?.mediaTypes?.[0];
      const supplySourceTypes = scenario?.supplySourceTypes;
      updatedFeeList.push(updateFee(maxPrice, mediaType, supplySourceTypes));
    });

    return { ...audience, fees: updatedFeeList };
  });

  return { ...audienceOutput, audiences: pricedAudiences };
};

export function compareAndPrintForShadowMode(
  data: any,
  legacyValue: any,
  newFeeObject: any,
  legacyCOPValue?: any
) {
  if (legacyValue === '--' || newFeeObject.displayValue === '--') return;

  if (legacyCOPValue && legacyCOPValue !== newFeeObject.copDisplayValue) {
    console.error('legacy COP fee: ', legacyCOPValue);
    console.error('new COP fee: ', newFeeObject.copDisplayValue);
    console.error(data);
  }

  if (legacyValue !== newFeeObject.displayValue) {
    console.error('legacy fee: ', legacyValue);
    console.error('new fee: ', newFeeObject);
    console.error(data);
  }
}
