import axios, { AxiosError, AxiosInstance } from 'axios';
import {
  REQUEST_TIMEOUT,
  constructHeaders,
  AudienceDataClient,
} from './AudienceClient';
import {
  COMBINED_AUDIENCES_CATEGORY,
  COMBINED_AUDIENCES_ENDPOINT,
} from 'src/constants/combined-audiences';
import {
  AudienceV1,
  GetCombinedAudienceDetailsResponse,
  AudienceSegmentsResponse, AudienceApiData
} from 'src/model';
import { Audience } from "@amzn/d16g-pricing-shared-client-library";
import { PricingApiData } from "src/model/ApiData.model";

type QueueItem = {
  id: string;
  resolve: (value: Audience[] | Promise<Audience[]>) => void;
  reject: (reason: string) => void;
  cancelled: boolean;
};

type CacheItem = {
  promise: Promise<Audience[]>;
  completed: boolean;
};

export class CombinedAudienceClient {
  private static readonly MIN_TIME_BETWEEN_REQUESTS_MS: number = 1000;
  private static readonly MAX_ATTEMPTS_PER_AUDIENCE: number = 5;

  private readonly client: AxiosInstance;
  private readonly advertiserId: string;
  private readonly headers: {};
  private readonly endpoint: string;
  private readonly audienceDataClient: AudienceDataClient;

  private cache: Map<string, CacheItem> = new Map();
  // A map from audience id to the number of attempted requests
  private attempts: Map<string, number> = new Map();
  private queue: QueueItem[] = [];
  private intervalId: any = null;

  private static instance: CombinedAudienceClient;
  private readonly observeAudiencesForPricing: (() => void) | undefined;

  public static initialize(apiData: PricingApiData, observeAudiencesForPricing?: () => void) {
    if (!CombinedAudienceClient.instance)
      CombinedAudienceClient.instance = new CombinedAudienceClient(apiData, observeAudiencesForPricing);
  }

  public static getInstance(): CombinedAudienceClient {
    if (!CombinedAudienceClient.instance)
      throw new Error(
        'Singleton class has not been initialized. Call initialize(ApiData) first.'
      );

    return CombinedAudienceClient.instance;
  }

  private constructor(apiData: PricingApiData, observeAudiencesForPricing?: () => void) {
    const { advertiserId, baseURL, headers } = apiData;

    this.advertiserId = advertiserId || "";
    this.headers = headers || constructHeaders(apiData);
    this.endpoint = COMBINED_AUDIENCES_ENDPOINT;
    this.client = axios.create({
      baseURL,
      withCredentials: true,
      timeout: REQUEST_TIMEOUT,
    });
    this.audienceDataClient = new AudienceDataClient(apiData);
    this.observeAudiencesForPricing = observeAudiencesForPricing;
  }

  /**
   * Call Combined Audience / List Audiences to expand audience lists containing combined audiences
   * @param {Audience[]} audiences - the list of audiences
   * @param {boolean} isHighPriority - is the request high priority
   * @returns the list of audiences with combined audiences expanded
   */
  public async replaceCombinedAudiencesWithTheirUnderlyingAudiences(
    audiences: Audience[],
    isHighPriority: boolean
  ): Promise<Audience[]> {
    const promises = audiences.map(audience => {
      if (audience.category === COMBINED_AUDIENCES_CATEGORY)
        return this.getUnderlyingAudiences(audience, isHighPriority).promise;

      return Promise.resolve([audience]);
    });

    const expandedAudiences = await Promise.all(promises);
    return expandedAudiences.flat();
  }

  /**
   * Call Combined Audiences / List Audiences to expand a single audience (also returns a Cancel Fn to cancel requests)
   * @param {Audience} audience - the combined audience to expand
   * @param {boolean} isHighPriority - is the request high priority
   * @returns the underlying audiences
   */
  public getUnderlyingAudiences(
    audience: Audience,
    isHighPriority: boolean
  ): { promise: Promise<Audience[]>; cancel: () => void } {
    if (this.cache.get(audience.audienceId)?.completed)
      return {
        promise: (this.cache.get(audience.audienceId) as CacheItem).promise,
        cancel: () => {},
      };

    const item = this.queueRequest(audience.audienceId, isHighPriority);
    this.ensureProcessingStarted();
    return item;
  }

  private ensureProcessingStarted() {
    if (this.intervalId === null) {
      this.dequeueRequest();
      this.intervalId = setInterval(() => {
        this.dequeueRequest();
      }, CombinedAudienceClient.MIN_TIME_BETWEEN_REQUESTS_MS);
    }
  }

  private stopProcessing() {
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  private queueRequest(
    id: string,
    isHighPriority: boolean
  ): { promise: Promise<Audience[]>; cancel: () => void } {
    let resolve: (value: Audience[] | Promise<Audience[]>) => void = () => {};
    let reject: (reason: string) => void = () => {};

    const promise = new Promise<Audience[]>((res, rej) => {
      resolve = res;
      reject = rej;
    });

    const item: QueueItem = { id, resolve, reject, cancelled: false };

    if (isHighPriority) this.queue.unshift(item);
    else this.queue.push(item);

    const cancel = () => {
      item.cancelled = true;
      item.reject(`Combined Audience request with ID ${id} was cancelled.`);
    };

    return { promise, cancel };
  }

  private async dequeueRequest(): Promise<void> {
    this.dropCancelled();
    this.dropPending();

    if (this.queue.length > 0) {
      const item = this.queue.shift() as QueueItem;
      try {
        const data = await this.fetchDataForCombinedAudience(item.id);
        item.resolve(data);
      } catch (error) {
        if (this.shouldRetry(item)) {
          this.queue.unshift(item);
          this.ensureProcessingStarted();
        }
      }
    }

    if (this.queue.length === 0) this.stopProcessing();
  }

  private dropCancelled(): void {
    this.queue = this.queue.filter(item => !item.cancelled);
  }

  private dropPending(): void {
    this.queue = this.queue.filter(item => {
      const cachedItem = this.cache.get(item.id);

      if (cachedItem) {
        cachedItem.promise
          .then(request => item.resolve(request))
          .catch(() => {
            if (this.shouldRetry(item)) {
              this.queue.unshift(item);
              this.ensureProcessingStarted();
            }
          });
        return false;
      }

      return true;
    });
  }

  private shouldRetry(item: QueueItem): boolean {
    const attemptCount = this.attempts.get(item.id);

    if (attemptCount === CombinedAudienceClient.MAX_ATTEMPTS_PER_AUDIENCE) {
      item.resolve((this.cache.get(item.id) as CacheItem).promise);
      item.cancelled = true;
      return false;
    }

    return true;
  }

  private async fetchDataForCombinedAudience(id: string): Promise<Audience[]> {
    const dataPromise = this.getCombinedAudienceDetails(id);
    this.cache.set(id, { promise: dataPromise, completed: false });
    const attempts = (this.attempts.get(id) || 0) + 1;
    this.attempts.set(id, attempts);

    try {
      const result = await dataPromise;
      this.cache.set(id, { promise: Promise.resolve(result), completed: true });
      return result;
    } catch (error) {
      if (attempts < CombinedAudienceClient.MAX_ATTEMPTS_PER_AUDIENCE)
        this.cache.delete(id);
      throw error;
    }
  }

  private async getCombinedAudienceDetails(
    audienceId: string
  ): Promise<Audience[]> {
    try {
      const url = `${this.endpoint}/${audienceId}`;

      const response: any = await this.client
        .get<GetCombinedAudienceDetailsResponse>(url, {
          headers: { ...this.headers, 'Amazon-Advertising-API-Scope': '123' },
          params: { advertiserId: this.advertiserId },
        })
        .then(resp => resp.data);

      const audienceV1s: AudienceV1[] =
        response.expression.audienceTargetingExpression.audiences;

      const positiveAudiences = audienceV1s
        .filter(audienceV1 => !audienceV1.negative)
        .map(audienceV1 => audienceV1.audienceId);

      let allAudiences: Audience[] = [];
      let nextToken: string | undefined;

      do {
        const audienceData: AudienceSegmentsResponse = await this.audienceDataClient.getAudienceById(
          audienceV1s.map(audience => audience.audienceId),
          nextToken
        );

        const updatedAudiences = audienceData.audiences.map(audience => ({
          ...audience,
          not: !positiveAudiences.includes(audience.audienceId),
        }));

        allAudiences = allAudiences.concat(updatedAudiences);
        nextToken = audienceData.nextToken;
      } while (nextToken);

      return allAudiences;
    } catch (e) {
      const error = e as AxiosError;
      if (error?.toJSON) console.error(error.toJSON());
      return Promise.reject(
        new Error(
          `Failed to get combined audience details for audience ID ${audienceId}: ${error.message}`
        )
      );
    }
  }
}
