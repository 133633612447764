import styled from 'styled-components';
import { Button, TextButton } from '@amzn/storm-ui';
import { PredicateValuePanelStylesProps } from './Props';

export enum Status {
  Entering = 'entering',
  Exiting = 'exiting',
  Exited = 'exited',
}

function isPanelHidden(status: string) {
  return (
    status === Status.Entering ||
    status === Status.Exiting ||
    status === Status.Exited
  );
}

export const PredicateViewContainer = styled.div<
  PredicateValuePanelStylesProps
>`
  &::before {
    content: '';
    background-color: ${props =>
      isPanelHidden(props.status)
        ? 'rgba(235,235,237,0)'
        : 'rgba(235,235,237,0.65)'};
    position: absolute;
    top: 0;
    width: 100%;
    right: 100%;
    bottom: 0;
    transition: background-color ${props => props.duration}ms ease-in-out;
  }
  position: absolute;
  z-index: 2000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${props =>
    isPanelHidden(props.status)
      ? 'rgba(235,235,237,0)'
      : 'rgba(235,235,237,0.65)'};
  transform: translate(
    ${props => (isPanelHidden(props.status) ? '-100%' : '0%')}
  );
  transition: transform ${props => props.duration}ms ease-in-out,
    background-color ${props => props.duration}ms ease-in-out;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
`;

export const PredicateViewPanel = styled.div`
  position: relative;
  width: 100%;
  filter: drop-shadow(-1px 0 4px rgba(0, 0, 0, 0.3));
  background-color: ${({ theme }) => theme.palette.white};
`;

export const PredicateViewFlexBox = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const PredicateViewHeadingContainer = styled.div`
  flex: 0 0 auto;
  align-self: stretch;
  padding: 14px 22px 14px 22px;
`;
export const PredicateViewHeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
`;
export const CloseButton = styled(TextButton)`
  background: transparent;
  color: darkgray;
  &:hover {
    color: black;
  }
`;

export const GroupAndValueContainer = styled.div`
  min-height: 450px;
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  align-items: stretch;
  border-top: 1px solid rgb(221, 221, 221);
`;

export const PredicateViewValuePanel = styled.div`
  width: 50%;
  -webkit-box-flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  border-right: 1px solid rgb(231, 231, 231);
  border-bottom-left-radius: 4px;
  border-top-left-radius: 8px;
  padding-left: 8px;
`;
export const PredicateViewActionPanel = styled.div`
  flex: 0 0 auto;
  align-self: stretch;
  padding: 15px 18px 15px 18px;
  border-top: 1px solid rgb(221, 221, 221);
`;

export const PredicateButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-content: flex-end;
  align-items: center;
  align-self: stretch;
  gap: 10px;
`;

export const PredicateNodeTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: start;
  padding: 18px;
`;

export const PredicateNodeTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: start;
  min-width: 130px;
`;

export const FlexWrapper = styled('div')`
  display: flex;
  justify-content: center;
`;

export const ChangeButton = styled(Button)`
  margin-right: auto;
`;

export const AddEditButtonContainer = styled.div`
  padding-top: 18px;
  padding-left: 15px;
`;

export const PredicateNode = styled.li`
  display: flex;
  flex-direction: row;
  box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.palette.alto};
  box-sizing: border-box;
  font-size: 13px;
  height: auto;
  justify-content: space-between;
  align-items: flex-start;
  gap: 30px;
  padding: ${({ theme }): string => theme.spacing.mini}
    ${({ theme }) => theme.spacing.medium} ${({ theme }) => theme.spacing.mini}
    0;
`;

export const PredicateGroupContainer = styled.div`
  width: 50%;
  -webkit-box-flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 8px;
  padding-right: 8px;
  background-color: ${({ theme }) => theme.palette.alabaster};
`;

export const Container = styled.div``;

export const NodeList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const SummaryViewCard = styled.div`
  display: 'flex',
  flexDirection: 'column',
  gap: 14,
`;
