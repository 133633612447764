import { useContext } from "react";
import { FeeContext } from "src/context/FeeContext";
import { MISSING_FEE_STORE_CONTEXT } from "src/constants";
import { DisplayFee } from "src/model";
import { useStore } from "zustand";
import { formatDisplayFee } from "src/utils/FeeUtil";

type ObservedPerformancePlusFees = {
    observedPerformancePlusFee: DisplayFee | undefined;
}

export default function usePerformancePlusFee(): ObservedPerformancePlusFees {
    const feeStore = useContext(FeeContext);

    if (!feeStore) {
        throw new Error(MISSING_FEE_STORE_CONTEXT);
    }

    const observedPerformancePlusFee: DisplayFee | undefined = useStore(feeStore, (state) => {
        if (state?.performancePlusFee) {
            return formatDisplayFee(state?.performancePlusFee);
        }
    })

    return {
        observedPerformancePlusFee
    };
}