import React, { useContext, useState } from 'react';
import { Text, Icon, TextButton } from '@amzn/storm-ui';
import { TranslatorString } from 'src/Localization/LocalizationConfig';
import { LocalizedText } from 'src/Localization/LocalizationText';
import { opportunities } from '@amzn/storm-ui-icons';
import {
  logTwitchSuggestionEvent,
  TaktLoggingContext,
  TaktLoggingContextType,
} from '@amzn/d16g-audience-picker-component';

const TwitchSuggestion = ({
  setTwitchInInventory,
  setShowTwitchSnackbar,
  targetedCanonicalIds,
  lineItemType,
}) => {
  const { advertiserId, lineItemId } =
    useContext<TaktLoggingContextType>(TaktLoggingContext);
  const [showTwitchSuggestion, setShowTwitchSuggestion] = useState(true);

  const handleDismiss = () => {
    logTwitchSuggestionEvent({
      metricName: 'DISMISS_UPDATE_INVENTORY_CLICKED',
      segmentIds: targetedCanonicalIds,
      lineItemType,
      advertiserId,
      lineItemId,
    });
    setShowTwitchSuggestion(false);
  };

  const handleUpdate = () => {
    logTwitchSuggestionEvent({
      metricName: 'UPDATE_INVENTORY_CLICKED',
      segmentIds: targetedCanonicalIds,
      lineItemType,
      advertiserId,
      lineItemId,
    });
    setShowTwitchSnackbar(true);
    setTwitchInInventory(true);
    setShowTwitchSuggestion(false);
  };

  return (
    <>
      {showTwitchSuggestion && (
        <span style={{ display: 'flex', alignItems: 'flex-start' }}>
          <Icon type={opportunities} style={{ color: '#5158E0' }} />
          <span style={{ marginLeft: '5px', display: 'inline-block' }}>
            <Text inline>
              <span style={{ color: '#495566' }}>
                <LocalizedText
                  translatorString={
                    TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TWITCH_SUGGESTION_TEXT
                  }
                />{' '}
              </span>
              <TextButton onClick={handleUpdate}>
                <LocalizedText
                  translatorString={
                    TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TWITCH_SUGGESTION_UPDATE
                  }
                />
              </TextButton>
              <span style={{ color: '#E6E9ED' }}>{' | '}</span>
              <TextButton onClick={handleDismiss}>
                <LocalizedText
                  translatorString={
                    TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TWITCH_SUGGESTION_DISMISS
                  }
                />
              </TextButton>
            </Text>
          </span>
        </span>
      )}
    </>
  );
};

export default TwitchSuggestion;
