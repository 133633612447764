export interface AudienceTargetingGroup {
  dealBasedTargetingEnabled: boolean;
  intrasetOperator: 'OR' | 'AND';
  intersetOperator: 'OR' | 'AND';
  segments: Segment[];
}

export interface Segment {
  id: string;
  not: boolean;
  audienceName: string;
}

export enum SummaryViewSectionTitle {
  TARGETING = 'Target: ',
  SELECTED = 'Selected: ',
  INCLUDING = 'Include: ',
  EXCLUDING = 'Exclude: ',
  APPLIED = 'Applied:',
  NONE = 'None',
}

export enum SummaryViewIcon {
  ACCEPTED,
  REJECTED,
}

interface AudienceOperator {
  OR: string;
  AND: string;
}

export const AudienceOperatorEnum: AudienceOperator = {
  OR: 'or',
  AND: 'and',
};
