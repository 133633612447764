import React from 'react';
import { LocalizedText } from 'src/Localization/LocalizationText';
import { TranslatorString } from 'src/Localization/LocalizationConfig';
import { Snackbar } from '@amzn/storm-ui';

const TwitchInventoryUpdatedSnackbar = ({ setShowTwitchSnackbar }) => {
  const handleCloseSnackbar = () => {
    setShowTwitchSnackbar(false);
  };

  const snackbarContainerStyle: React.CSSProperties = {
    position: 'fixed',
    bottom: '22px',
    right: '22px',
    width: '450px',
  };

  return (
    <Snackbar
      id="Twitch-suggestion-snackbar"
      type="success"
      onClose={handleCloseSnackbar}
      style={snackbarContainerStyle}
      closeButtonLabel={
        <LocalizedText
          translatorString={
            TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TWITCH_SUGGESTION_DISMISS
          }
        />
      }
    >
      <LocalizedText
        translatorString={
          TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TWITCH_SUGGESTION_UPDATE_SUCCESS
        }
      />
    </Snackbar>
  );
};

export default TwitchInventoryUpdatedSnackbar;
