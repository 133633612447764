import { useEffect, useState } from 'react';
import {
  isAudienceIncompatible,
  isSelectedGroupExclude,
} from '../../utils/audienceSuggestionUtils';
import { TargetingExpression } from '../../models/AudienceSuggestions';
import { useAudienceTargeting } from '../../state/useAudienceTargeting';
import { isEqual } from 'lodash-es';
import { SuggestionsDataClient } from 'api/SuggestionsClient';

export const useRefreshSuggestionsTable = (
  refreshDataTable: Function,
  suggestionsDataClient: SuggestionsDataClient
) => {
  const [audienceIncompatible, setAudienceIncompatible] = useState<boolean>(
    false
  );
  const [noSegmentIncluded, setNoSegmentIncluded] = useState<boolean>(false);
  const { state } = useAudienceTargeting();

  const targetingGroup =
    state.audienceTargeting.targetingGroups[
      state.selectedGroup.targetingGroupIndex
    ];

  useEffect(() => {
    // All groups have 0 segments or all segments are excluded
    const noSegments = state.audienceTargeting.targetingGroups[0].include.every(
      block => block.segments.every(segment => segment.not)
    );

    if (noSegments !== noSegmentIncluded) setNoSegmentIncluded(noSegments);

    if (noSegments) {
      setAudienceIncompatible(false);
      return;
    }

    // Handling incompatible audiences
    // When audience is incompatible do not call the api
    if (isAudienceIncompatible(targetingGroup)) {
      setAudienceIncompatible(true);
      return;
    }

    // Handling compatible audiences

    // Build targeting expression for suggestion api call
    const targetingExpression: TargetingExpression[] = [];
    targetingGroup.include.forEach((includeBlock, index) => {
      const expression = includeBlock.segments
        .filter(exp => !exp.not)
        .map(({ audienceId, not }) => ({
          negative: !!not,
          groupId: 'IncludeGroup' + index,
          audienceId,
        }));
      if (expression.length) targetingExpression.push(...expression);
    });

    // When there are any changes in the exclude group we do not have to fetch suggestions
    if (
      isSelectedGroupExclude(
        state.audienceTargeting.targetingGroups[0],
        state.selectedGroup
      ) &&
      // When a group has include and exclude both and we remove include we need to fetch suggestions
      // hence added this condition
      !audienceIncompatible &&
      // when constructed targeting epression doesn't match the expression that we used to last call the suggestions api we need to call the api again
      // Use case: When the first group has excluded segments and user opens the Picker suggestions api has to be called even if it is exclude group to load suggestions
      (isEqual(
        targetingExpression,
        suggestionsDataClient?.targetingExpression
      ) ||
        !targetingExpression.length)
    )
      return;

    // Fetch suggestions for the new taregting expression
    refreshDataTable(targetingExpression);

    // If control has come to this point it means that audience is compatible and we can set it to false
    if (audienceIncompatible) {
      setAudienceIncompatible(false);
      // Usecase: when there is one group with incompatible audience and we remove that group
      // Refresh data table to update no suggestions subtext
      if (!targetingExpression.length) refreshDataTable([], true);
    }
  }, [targetingGroup.include]);
  return [audienceIncompatible, noSegmentIncluded];
};
