// @ts-nocheck
import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
  useContext,
} from 'react';
import debounce from 'lodash-es/debounce';
import cloneDeep from 'lodash-es/cloneDeep';
import { UnifiedNotification } from '@amzn/unified-notification-components/core';

import { TableComponent } from '../Table/TableComponent';
import { AudiencePickerStyled } from './AudiencePicker.styled';
import { AudienceTargetingProvider } from '../../state/audienceTargetingContext';
import TargetingGroupComponent from '../../components/AudienceTargeting/TargetingGroup/TargetingGroupComponent';
import PriceSummary from '../PriceSummary/PriceSummary';
import { useAudienceTargeting } from '../../state/useAudienceTargeting';
import { AudienceTargeting, ApiData, Audience } from '../../models';
import useConstant from 'use-constant';
import {
  AudiencePickerState,
  defaultSelectedGroup,
  audiencePickerInitialState,
} from '../../state/audienceTargetingReducer';
import { AudienceCategory } from '../../models/FilterSet';
import { AudienceDataClient } from '../../api/Client';
import {
  CombinedAudienceClient,
  useAudienceFee,
} from '@amzn/d16g-pricing-react-utils';

// install Ag-grid license before loading the first component that consumes ag-grid or TropicalUI
import UnifiedDataTableLicense from '@amzn/unified-data-table-license';
import UdcLicenseManager from '@amzn/unified-data-table-components/core/licenseManager';

import { TranslationProvider } from '../../state/translationContext';
import BulkEditTargetingGroupComponent from '../BulkEdit/BulkEditTargetingGroupComponent';
import NewAudienceAlert from '../IncompatibleAudience/NewAudienceAlert/NewAudienceAlert';
import { CurrentAudience } from '../IncompatibleAudience/CurrentAudience/CurrentAudience';
import { AudienceSuggestions } from '../AudienceSuggestions/AudienceSuggestions';
import { TargetedAudienceSummary } from '../TargetedAudienceSummary/TargetedAudienceSummary';
import { useResizeObservable } from '../../hooks/useResizeObservable';

// license must be set before loading the component contains Ag-grid import statement
const keyString = UnifiedDataTableLicense.getLicenseKey();
UdcLicenseManager.setLicenseKey(keyString);

type AudiencePickerProps = {
  /**
   * callback method to be used outside of the component to return the state and audiences selected.
   */
  onChange: (audienceState: AudienceTargeting) => void;
  /**
   * Initial "saved" state, which audiences have been selected for which groups, the group operators, etc.
   */
  initialAudienceState?: AudienceTargeting;
  /**
   * parameters for the API to make a request for audiences to populate the table.
   */
  apiData: ApiData;
  /**
   * Whether to show the category dropdown filter in the header of the table.
   */
  useCategoryDropdownFilter?: boolean;
  /**
   * List of categories to be shown in the filters dropdown(if `useCategoryDropdownFilter` is `true`, and this is not provided, the filterTaxonomy will be used instead).
   */
  allowedFilterCategories?: AudienceCategory[];
  /**
   * Whether to show the "All" filter if there is a list of allowedFilterCategories passed in.
   */
  removeAllFilter?: string;
  /**
   * List of feeSupplyType(s) that dictate which fee to use from a given audience segment
   */
  feeSupplyType?: string[];
  /**
   * Whether to show the "legacy" logic version of the picker, or the "include/exclude" logic version of the picker.
   */
  useLegacy?: boolean;
  /**
   * The maximum number of segments that can be added to a group before an error is shown and no more audiences can be added to that group.
   */
  maxSegmentsPerGroup?: number;
  /**
   * The maximum number of groups that can be added before the "add" button disappears.
   */
  maxIncludeGroups?: number;
  /**
   * Specified language code to trigger the TranslationContext and provide strings in the requested locale.
   */
  locale?: string;
  /**
   * Callback method to get the height of the table and dynamically update it.
   */
  getTableHeight?: (
    isSuggestionTableExpanded: boolean,
    calculateSuggestionTableHeight?: boolean
  ) => string;
  /**
   * If a bulkEditMutationType is passed in, the BulkEditTargetingGroupComponent will be used in place of the default TargetingGroupComponent.
   */
  bulkEditMutationType?: string;
  /**
   * Passed in method that accepts an audience and if that audience meets some criterion to be disabled, returns the error message that is to be shown as a tooltip.
   */
  disableRowsCallback?: (data: Audience) => string;
  /**
   * Method to force the table column data to update on some given criterion.
   */
  triggerColumnDefs?: any;
  /**
   * If `useLegacy` is set to false and `hideExcludeGroup` is set to true, the legacy logic will remain but the exclude group will not show up.
   */
  hideExcludeGroup?: boolean;
  isAudienceIncompatible?: boolean;
  isEntityAllowListed?: boolean;
  showSuggestions?: boolean;
  isInclusionDisabled?: boolean;
  inclusionDisabledReasonCode?: string;
};

// Use the optional prop interface to define the default props
const defaultProps: AudiencePickerProps = {
  isAudienceIncompatible: false,
  isEntityAllowListed: false,
  showSuggestions: false,
  isInclusionDisabled: false,
};

/**
 * starting point of the AudiencePicker component, returns an instance of the audience picker with selected props.
 */
export const AudiencePicker = (props: AudiencePickerProps) => {
  const initAudienceState = useConstant(() => {
    // We need to save initialAudienceState in audienceTargeting when audience is compatible
    // otherwise save it in incompatibleAudience since we need the include/exclude blocks to be empty in this case
    // These changes are done for the new picker that is available only for some of the entities
    if (
      props?.initialAudienceState &&
      !(props?.isEntityAllowListed && props?.isAudienceIncompatible)
    )
      return {
        audienceTargeting: cloneDeep(props?.initialAudienceState),
        selectedGroup: defaultSelectedGroup,
        incompatibleAudience: {},
      } as AudiencePickerState;
    if (
      props?.initialAudienceState &&
      props?.isEntityAllowListed &&
      props?.isAudienceIncompatible
    )
      return {
        audienceTargeting: cloneDeep(
          audiencePickerInitialState.audienceTargeting
        ),
        selectedGroup: defaultSelectedGroup,
        incompatibleAudience: cloneDeep(props?.initialAudienceState),
      } as AudiencePickerState;

    return;
  });

  return (
    <TranslationProvider locale={props.locale ?? 'en-US'}>
      <AudienceTargetingProvider initialState={initAudienceState}>
        <UnifiedNotification>
          <AudiencePickerContents {...props} />
        </UnifiedNotification>
      </AudienceTargetingProvider>
    </TranslationProvider>
  );
};

const AudienceTableComponent = React.memo(TableComponent);

const AudiencePickerContents = ({
  onChange,
  apiData,
  useCategoryDropdownFilter,
  allowedFilterCategories,
  removeAllFilter,
  useLegacy,
  feeSupplyType,
  maxSegmentsPerGroup,
  maxIncludeGroups,
  getTableHeight,
  bulkEditMutationType,
  disableRowsCallback,
  triggerColumnDefs,
  hideExcludeGroup,
  isAudienceIncompatible,
  isEntityAllowListed,
  showSuggestions,
  isInclusionDisabled,
  inclusionDisabledReasonCode,
  locale,
}: AudiencePickerProps) => {
  const { state } = useAudienceTargeting();
  const [selectionContainerHeight, setSelectionContainerHeight] = useState<
    number
  >();
  const [showSuggestionsTable, setShowSuggestionsTable] = useState(
    showSuggestions
  );
  const ref = useRef<HTMLDivElement>(null);

  const [highLevelFilters, setHighLevelFilters] = useState<string[]>([]);
  const [filtersLoaded, setFiltersLoaded] = useState<boolean>(false);

  const { observeAudiencesForPricing, observedAudienceFee } = useAudienceFee();

  useEffect(() => {
    onChange(state.audienceTargeting);
  }, [state.audienceTargeting]);

  const audienceDataClient = useMemo(
    () => new AudienceDataClient(apiData, observeAudiencesForPricing),
    [apiData]
  );

  useMemo(() => {
    CombinedAudienceClient.initialize(apiData);
  }, [apiData]);

  const getFilterTaxonomy = async () => {
    const response = await audienceDataClient.getFilterTaxonomy();
    const options = response?.map(cat => cat.category);
    setHighLevelFilters(options ?? []);
    setFiltersLoaded(true);
  };

  useEffect(() => {
    if (!filtersLoaded) getFilterTaxonomy();
  }, []);

  const callback = useCallback((mutationList: any) => {
    const setSelectionContainerHeightDebounced = debounce(() => {
      setSelectionContainerHeight(mutationList[0].target.offsetHeight);
    }, 20);
    setSelectionContainerHeightDebounced();
  }, []);

  useResizeObservable<HTMLDivElement | null>(ref?.current, callback);
  return (
    <AudiencePickerStyled.Container>
      <AudiencePickerStyled.TableContainer ref={ref}>
        <AudienceTableComponent
          apiData={apiData}
          audienceDataClient={audienceDataClient}
          useCategoryDropdownFilter={useCategoryDropdownFilter}
          allowedFilterCategories={allowedFilterCategories}
          highLevelFilters={highLevelFilters}
          removeAllFilter={removeAllFilter}
          feeSupplyType={feeSupplyType}
          useLegacy={useLegacy}
          getTableHeight={getTableHeight}
          disableRowsCallback={disableRowsCallback}
          triggerColumnDefs={triggerColumnDefs}
          bulkEditMutationType={bulkEditMutationType}
          locale={locale ?? 'en-US'}
          setSelectionHeight={setSelectionContainerHeight}
          showSuggestions={showSuggestionsTable}
          isInclusionDisabled={isInclusionDisabled}
          inclusionDisabledReasonCode={inclusionDisabledReasonCode}
        />
        {showSuggestions && (
          <AudienceSuggestions
            getTableHeight={getTableHeight}
            setShowSuggestions={setShowSuggestionsTable}
            showSuggestions={showSuggestionsTable}
            apiData={apiData}
            audienceDataClient={audienceDataClient}
            useLegacy={useLegacy}
          />
        )}
      </AudiencePickerStyled.TableContainer>
      <AudiencePickerStyled.SelectionsContainer
        selectionContainerHeight={selectionContainerHeight}
      >
        {showSuggestions && false ? (
          <TargetedAudienceSummary
            feeSupplyType={feeSupplyType}
            currencyOfPreference={apiData.currencyOfPreference}
            fxConversionRate={apiData.fxConversionRate}
            highLevelFilters={highLevelFilters}
          />
        ) : (
          feeSupplyType && (
            <PriceSummary
              feeSupplyType={feeSupplyType}
              currencyOfPreference={apiData.currencyOfPreference}
              fxConversionRate={apiData.fxConversionRate}
              highLevelFilters={highLevelFilters}
            />
          )
        )}
        {isEntityAllowListed && isAudienceIncompatible && (
          <>
            <CurrentAudience />
            <NewAudienceAlert />
          </>
        )}
        {bulkEditMutationType ? (
          <BulkEditTargetingGroupComponent
            feeSupplyType={feeSupplyType}
            useLegacy={useLegacy}
            bulkEditMutationType={bulkEditMutationType}
          />
        ) : (
          <TargetingGroupComponent
            feeSupplyType={feeSupplyType}
            useLegacy={useLegacy}
            maxIncludeGroups={maxIncludeGroups}
            maxSegmentsPerGroup={maxSegmentsPerGroup}
            hideExcludeGroup={hideExcludeGroup}
            isEntityAllowListed={isEntityAllowListed}
            isAudienceIncompatible={isAudienceIncompatible}
          />
        )}
      </AudiencePickerStyled.SelectionsContainer>
    </AudiencePickerStyled.Container>
  );
};

AudiencePicker.defaultProps = defaultProps;
