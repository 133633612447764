import axios, { AxiosError, AxiosInstance } from 'axios';
import {
  AudienceSuggestionsResponse,
  SuggestedAudience,
  SuggestedAudienceRequest,
  TargetingExpression,
} from '../models/AudienceSuggestions';
import { CATEGORY_FILTERS, MAX_SUGGESTIONS } from '../constants/suggestion';
import { REQUEST_TIMEOUT, constructHeaders } from './Client';
import { ApiData } from 'models';

export class SuggestionsDataClient {
  private readonly client: AxiosInstance;
  public advertiserId: string;
  public headers: {};
  public endpoint?: string;
  public baseUrl?: string;
  public targetingExpression: TargetingExpression[];

  public constructor(apiData: ApiData) {
    const {
      advertiserId,
      personaBuilderTopOverlappingEndpoint,
      personaBuilderUrl,
      headers,
    } = apiData;

    this.advertiserId = advertiserId;
    this.headers = headers || constructHeaders(apiData);
    this.endpoint = personaBuilderTopOverlappingEndpoint;
    this.baseUrl = personaBuilderUrl;
    this.client = axios.create({
      baseURL: personaBuilderUrl,
      withCredentials: true,
      timeout: REQUEST_TIMEOUT,
    });
    this.targetingExpression = [];
  }

  public async getSuggestedAudiences(
    categoryFilter: Array<string>
  ): Promise<{ topAudiences: SuggestedAudience[] }> {
    try {
      if (
        this.targetingExpression.length > 0 &&
        this.baseUrl !== undefined &&
        this.endpoint !== undefined
      ) {
        const requestBody: SuggestedAudienceRequest = {
          audienceTargetingExpression: {
            audiences: this.targetingExpression,
          },
          categoryFilter: categoryFilter || CATEGORY_FILTERS,
        };
        const response = await this.client
          .post<AudienceSuggestionsResponse>(this.endpoint, requestBody, {
            // FIXME: Remove the Amazon-Advertising-API-Scope once insights team updates their contract to remove this header for 1p auth
            headers: { ...this.headers, 'Amazon-Advertising-API-Scope': '123' },
            params: {
              advertiserId: this.advertiserId,
              maxResults: MAX_SUGGESTIONS,
            },
          })
          .then(resp => resp.data);
        return {
          topAudiences: response?.audiences || [],
        };
      }
      return {
        topAudiences: [],
      };
    } catch (e) {
      const error = e as AxiosError<any>;
      if (error && error.toJSON) console.error(error.toJSON());
      throw error;
    }
  }
}
