import styled from 'styled-components';
import { FooterPanelContainer } from '@amzn/unified-data-table-components/core';
import { defaultTheme } from '@amzn/storm-ui';

export const FilterPillContainer = styled.div`
  font-family: Amazon Ember;
  padding: 0 10px 10px 10px;
`;

export const FooterContainer = styled(FooterPanelContainer)`
  padding-right: 21px;
  border: 1px solid ${defaultTheme.palette.gray[100]};
  border-top: 0;
`;
