import { has } from 'lodash-es';
import { MFEBootstrap } from '@mfe/state-management';
import { LineItemProposalPageState } from 'src/model/LineItemPageState';
import useAbstractedLineItemPageState from 'src/utils/hooks/useAbstractedLineItemPageState';

/**
 * Custom React hook to use input method value from pageState
 * Use effect to subscribe to global state updates. Whenever global state is updated,
 * check `input method` value. If "use text string" is selected (targetingMode = ADVANCED),
 * return true; false otherwise.
 * @param {object} pageState line item page state that stores line item info
 * @returns {LineItemCreateEditPageState} localState
 */
export default function useIsInputMethodAdvanced(
  pageState: MFEBootstrap.PageState<LineItemProposalPageState>
) {
  const [lineItemPageState] = useAbstractedLineItemPageState(pageState, true);

  return (
    has(lineItemPageState.lineItemV1, 'targetingMode') &&
    lineItemPageState.lineItemV1.targetingMode === 'ADVANCED'
  );
}
