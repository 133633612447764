import React, { ReactElement } from 'react';
import { Modal, Text } from '@amzn/storm-ui';
import {
  CurrentAudience,
  NewAudience,
} from '@amzn/d16g-audience-picker-component';
import styled from 'styled-components';
import { ModalProps } from 'src/model/ConfirmationModal';
import ModalFooter from './ModalFooter';
import { LocalizedText } from 'src/Localization/LocalizationText';
import { TranslatorString } from 'src/Localization/LocalizationConfig';

const ModalBody = styled.div`
  display: flex;
  height: 550px;
  width: 750px;
  div {
    flex: 1;
  }
`;

const ModalHeader = (): ReactElement => (
  <>
    <Text fontSize="large">
      <LocalizedText
        translatorString={
          TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_REPLACE_AUDIENCE
        }
      />
      ?
    </Text>
    <Text type="p" fontSize="base">
      <LocalizedText
        translatorString={
          TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_REPLACE_AUDIENCE_CONFIRM
        }
      />
    </Text>
  </>
);

/**
 * Presentational Confirmation Modal component.
 * Displays a modal asking the user to confirm or cancel
 * their request to remove a subsection.
 * @param props Component props
 * @returns Presentational Confirmation Modal component.
 */
const ReplaceAudience = (props: ModalProps): ReactElement => {
  const { isOpen, toggleElement, onCancel, onConfirm } = props;

  return (
    <>
      <Modal
        isOpen={isOpen}
        header={<ModalHeader />}
        footer={
          <ModalFooter
            cancelButtonTranslatorString={
              TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_CANCEL
            }
            onCancel={onCancel}
            confirmButtonTranslatorString={
              TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_REPLACE_AUDIENCE
            }
            onConfirm={onConfirm}
            testId="replace-audience"
          />
        }
        onClose={onCancel}
        toggleEl={toggleElement}
        padding="micro"
      >
        <ModalBody>
          <CurrentAudience expandable={false} border={false} />
          <NewAudience />
        </ModalBody>
      </Modal>
    </>
  );
};

export default ReplaceAudience;
