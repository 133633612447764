import { memo, useRef } from "react";
import { FeeProps } from "src/store/FeeStore";
import { FeeContext } from "src/context/FeeContext";
import { PricingApiHelper } from "src/utils/PricingApiHelper";
import useConstant from "use-constant";
import { MFEBootstrap } from "@mfe/state-management";
import {PricingApiContext, PricingApiData} from "src/model/ApiData.model";
import useBulkEditPageStateFeeManager from "src/hooks/internal/useBulkEditPageStateFeeMananger";

interface FeeContextWrapperProps {
    children: React.ReactNode;
    initStoreProps?: Partial<FeeProps>;
    pageState: MFEBootstrap.PageState<any>;
    apiData?: PricingApiData;
    pricingApiContext?: PricingApiContext;
}

const BulkEditFeeContextWrapper = memo(function FeeContextWrapper({children, initStoreProps, pageState, apiData}: FeeContextWrapperProps) {
    useConstant(() => {
        //@ts-ignore
        PricingApiHelper.initialize(apiData, initStoreProps);
    })

    const feeContext = useRef(PricingApiHelper.getInstance().getFeeStore()).current;
    //@ts-ignore
    useBulkEditPageStateFeeManager(pageState, feeContext);

    return (
        // @ts-ignore
        <FeeContext.Provider value={feeContext}>
            {children}
        </FeeContext.Provider>
    )
})

export default BulkEditFeeContextWrapper