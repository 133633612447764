/* eslint-disable */
import React, {useContext, useEffect, useRef} from 'react';
import {defaultTheme, PortalContext, Text, TextButton, Tooltip, useUID} from '@amzn/storm-ui';
import {Audience, FeeTypes} from '../../../models';
import {IAudienceTargetingContext} from '../../../state/audienceTargetingContext';
import {useAudienceTargeting} from '../../../state/useAudienceTargeting';
import styled from 'styled-components';
import {capitalize, TranslationContext,} from '../../../state/translationContext';
import {
  ADD,
  COMBINED_AUDIENCE_SELECTION_DISABLED_MESSAGE,
  EXCLUDE,
  EXCLUDE_AUDIENCES,
  EXCLUDED,
  INCLUDE,
  INCLUDE_AUDIENCES,
  INCLUDED,
  MULTIPLE_DMP_ERROR
} from "../../../constants/translations";
import {isInShadowDom, retargetEvents} from "../../../utils/retargetEvents";
import {convertIncludeStateToSegmentList} from '../../../utils/audienceTargetingConverter'
import {THIRD_PARTY_CATEGORIES} from "../../../constants/3p-categories";
import {InclusionDisabledReasonCodeMap} from "../../../constants/inclusionsDisabledReasonCode";
import {COMBINED_AUDIENCES_CATEGORY} from "../../../constants/combined-audiences";
import {logAudienceTargetingEvent} from "../../../utils/taktLogging";
import {TaktLoggingContext} from "../../../state";
import {isWeblabActive, Weblabs} from "../../../utils/weblabUtil";

type ActionRendererProps = {
  /**
   * segment data for this row.
   */
  data: Audience;
  /**
   * Whether to show the "legacy" logic version of the picker, or the "include/exclude" logic version of the picker.
   */
  useLegacy?: boolean;
  /**
   * Passed in method that accepts an audience and if that audience meets some criterion to be disabled, returns the error message that is to be shown as a tooltip.
   */
  disableRowsCallback?: (audience: Audience) => string;
  /**
   * List of feeSupplyType(s) that dictate which fee to use from a given audience segment
   */
  feeSupplyType?: string[];
  /**
   * Whether we are excluding, including, or removing the audiences that will be shown in this component.
   */
  bulkEditMutationType?: string;
  isInclusionDisabled?: boolean;
  inclusionDisabledReasonCode?: string;
};

const ActionStyle = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
`;

const IncludeExcludeDivider = styled(TextButton)`
  color: ${defaultTheme.palette.dustyGray};
  padding: 0 4px;
  cursor: default;
`;

/**
 * Column with the button to add, or include/exclude the selected segment
 */
export const ActionRenderer = ({
  data,
  useLegacy,
  disableRowsCallback,
  feeSupplyType,
  bulkEditMutationType,
  isInclusionDisabled,
  inclusionDisabledReasonCode,
}: ActionRendererProps) => {
  const ref = useRef<HTMLElement>(null);
  const inlinePortalRef = useContext(PortalContext);
  const getTranslation = useContext(TranslationContext);

  const takt = useContext(TaktLoggingContext);

  const INCLUDE_TEXT = capitalize(getTranslation(INCLUDE));
  const INCLUDED_TEXT = capitalize(getTranslation(INCLUDED));
  const EXCLUDE_TEXT = capitalize(getTranslation(EXCLUDE));
  const EXCLUDED_TEXT = capitalize(getTranslation(EXCLUDED));
  const ADD_TEXT = capitalize(getTranslation(ADD));
  const MAX_COMBINED_AUDIENCE_TOOLTIP_MESSAGE_TEXT = getTranslation(COMBINED_AUDIENCE_SELECTION_DISABLED_MESSAGE);

  const {
    selectedAudienceGroup,
    addAudienceToGroup,
    state,
  }: IAudienceTargetingContext = useAudienceTargeting();
  const hasBeenAdded = selectedAudienceGroup?.segments.find(
    segment => data.audienceId === segment.audienceId
  );
  const excluded = selectedAudienceGroup?.segments.find(
    segment => segment.not === true && data.audienceId === segment.audienceId
  );

  const disableUnmatchedDMP = () => {

    if (bulkEditMutationType == EXCLUDE_AUDIENCES) return false;
    if (!THIRD_PARTY_CATEGORIES.includes(data.category)) return false;

    //only look at included segments to check for a DMP
    const selectedDMP = convertIncludeStateToSegmentList(state).find(
      segment => THIRD_PARTY_CATEGORIES.includes(segment.category) && !!segment.providerId
    )?.providerId;

    if (!selectedDMP || !data.providerId) return false;
    if (data.providerId == selectedDMP) return false;

    const fees = feeSupplyType?.map((supplyType) => {
      let supplyFee = data?.fees?.find((fee: any) => fee.impressionSupplyType === supplyType);
      if (supplyType === FeeTypes.PVA || supplyType === FeeTypes.STV || supplyType === FeeTypes.OTT_VIDEO && !supplyFee) {
        supplyFee = data?.fees?.find((fee: any) => fee.impressionSupplyType === FeeTypes.VIDEO);
      }
      return supplyFee?.amount;
    });

    // @ts-ignore
    return (fees?.length) ? fees.some((fee: number) => fee > 0) : true;
  }

  const getInclusionsDisableMessage = () => {
    const message = inclusionDisabledReasonCode && InclusionDisabledReasonCodeMap.get(inclusionDisabledReasonCode);
    return message && getTranslation(message);
  }

  const showInclusionsDisableMessage = () => {
    return !bulkEditMutationType && getInclusionsDisableMessage();
  };

  const disableInclusions = () => {
    if (bulkEditMutationType == INCLUDE_AUDIENCES && isInclusionDisabled && inclusionDisabledReasonCode) return true;
    return !useLegacy && !bulkEditMutationType && isInclusionDisabled && inclusionDisabledReasonCode;
  }

  const showDisableMessage = () => {
    if (disableRowsCallback && disableRowsCallback(data))
      return disableRowsCallback(data);

    if (disableUnmatchedDMP()) return getTranslation(MULTIPLE_DMP_ERROR);

    if (disableInclusions()) {
      return getInclusionsDisableMessage();
    }

    return '';
  };

  useEffect(() => {
    if (ref?.current && isInShadowDom(inlinePortalRef?.current)) retargetEvents(ref.current);
  });

  //for the addActionButton, we want to disable multi-DMP validation if we are in the exclude group
  const disableExcludedAddButton = state.selectedGroup.targetingGroupType === EXCLUDE ? !disableUnmatchedDMP : true;

  const uid = useUID('Audience-Selector-Add-button');

  const totalCombinedAudiencesAddedExceedsMaxAllowed = () => {
    const combinedAudienceCount = state.audienceTargeting.targetingGroups
      .flatMap(group => group.include)
      .flatMap(includeGroup => includeGroup.segments)
      .filter(segment => segment.category === COMBINED_AUDIENCES_CATEGORY)
      .length;

    return (combinedAudienceCount >= 5 && data.category === COMBINED_AUDIENCES_CATEGORY);
  };

  const handleAddEvent = (notValue: boolean) => {
    logAudienceTargetingEvent({
        metricName: "LI_PICKER_AUDIENCE_TARGETING_SELECTED",
        advertiserId: takt.advertiserId,
        payload: `canonicalId: '${data.audienceId}', isPathfinderEnabled: '${isWeblabActive(Weblabs.PATHFINDER_SEARCH)}'`
      }
    );
    addAudienceToGroup({ ...data, not: notValue })

  };

  const AddActionButton = () => {
    const disabled =
      (bulkEditMutationType == INCLUDE_AUDIENCES && isInclusionDisabled) ||
      !!hasBeenAdded ||
      (!!showDisableMessage() && disableExcludedAddButton) ||
      totalCombinedAudiencesAddedExceedsMaxAllowed() ||
      (isWeblabActive(
          Weblabs.APM_VIDEO_PRICING_AUDIENCE_PICKER_DEAL_BASED_1P_AUDIENCES
      ) &&
        feeSupplyType?.includes(FeeTypes.OTT_VIDEO) &&
        !!showDisableMessage());
    return (
      totalCombinedAudiencesAddedExceedsMaxAllowed() ?
      <Tooltip
        type="dark"
        trigger={
          (<ActionStyle>
            <TextButton
              disabled={true}
              size="small"
            >
              {ADD_TEXT}
            </TextButton>
          </ActionStyle>)
        }
        message={MAX_COMBINED_AUDIENCE_TOOLTIP_MESSAGE_TEXT}
      /> :
      <TextButton
        id={uid}
        disabled={disabled}
        onClick={() => handleAddEvent(false)}
        size="small"
      >
        {ADD_TEXT}
      </TextButton>
    );
  };

  const DisabledInclusionButton = () => {
    return <Tooltip
        type="dark"
        trigger={
          (<ActionStyle>
            <TextButton
              disabled={isInclusionDisabled}
              size="small"
            >
              <Text>{hasBeenAdded && !excluded ? INCLUDED_TEXT : INCLUDE_TEXT}</Text>
            </TextButton>
          </ActionStyle>)
        }
        message={showInclusionsDisableMessage()}
       />

  };

  const IncludeExcludeActionButton = () => {
    const isExclusionDisabled =
      !!hasBeenAdded ||
      (!!showDisableMessage() && !disableUnmatchedDMP) ||
      (isWeblabActive(
        Weblabs.APM_VIDEO_PRICING_AUDIENCE_PICKER_DEAL_BASED_1P_AUDIENCES
      ) &&
        feeSupplyType?.includes(FeeTypes.OTT_VIDEO) &&
        !!showDisableMessage());
    return (
      <>
        {isInclusionDisabled ?
          <DisabledInclusionButton/> :
          (totalCombinedAudiencesAddedExceedsMaxAllowed() ?
            <Tooltip
              type="dark"
              trigger={
                (<ActionStyle>
                  <TextButton
                    disabled={true}
                    size="small"
                  >
                    <Text>{hasBeenAdded && !excluded ? INCLUDED_TEXT : INCLUDE_TEXT}</Text>
                  </TextButton>
                </ActionStyle>)
              }
              message={MAX_COMBINED_AUDIENCE_TOOLTIP_MESSAGE_TEXT}
            /> : (
            <TextButton
              id={uid}
              disabled={isInclusionDisabled || !!hasBeenAdded || !!showDisableMessage() || totalCombinedAudiencesAddedExceedsMaxAllowed()}
              onClick={() => handleAddEvent(false)}
              size="small"
            >
              <Text>{hasBeenAdded && !excluded ? INCLUDED_TEXT : INCLUDE_TEXT}</Text>
            </TextButton>))
        }
        <IncludeExcludeDivider> | </IncludeExcludeDivider>
        <TextButton
          id={uid}
          disabled={isExclusionDisabled}
          onClick={() => handleAddEvent(true)}
          size="small"
        >
          <Text>{hasBeenAdded && !!excluded ? EXCLUDED_TEXT : EXCLUDE_TEXT}</Text>
        </TextButton>
      </>
    );
  };

  const ActionButton = () =>
    useLegacy ? <IncludeExcludeActionButton /> : <AddActionButton />;

  return (
    <ActionStyle>
      {!showDisableMessage() ? (
        <ActionButton />
      ) : (
        <Tooltip
          type="dark"
          trigger={
            //@ts-ignore
            <ActionStyle ref={ref}>
              <ActionButton />
            </ActionStyle>
          }
          message={showDisableMessage()}
        />
      )}
    </ActionStyle>
  );
};
