import React from 'react';
import { Text } from '@amzn/storm-ui';
import { Audience } from '../../../models';
import styled from 'styled-components';

type ActionRendererProps = {
  /**
   * segment data for this row.
   */
  data: Audience;
};

const CategoryContainer = styled(Text)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

/**
 * Column with the category of the segment, as well as it's subCategory as subtext.
 */
export const CategoryRenderer = ({ data }: ActionRendererProps) => {
  if (!data) return null;

  const { category, subCategory } = data as Audience;
  return (
    <CategoryContainer>
      <Text>{category}</Text>
      <Text textColor="tertiary" fontSize="mini">
        {subCategory}
      </Text>
    </CategoryContainer>
  );
};
