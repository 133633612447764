import styled from 'styled-components';
import { defaultTheme } from '@amzn/storm-ui';

export const WarningContainer = styled.div`
  display: flex;
  align-items: start;
  padding: 5px 0 0 0;
  color: ${defaultTheme.palette.gray['600']};
  text-transform: none;
`;
