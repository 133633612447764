import {
  LocalizationContextBuilder,
  createAsyncMessageBundleManager,
} from '@amzn/arb-tools';
import { MbmProvider } from '@amzn/react-arb-tools';
import React from 'react';
import arbManifest from '../../translations/arbManifest.js';

const resources = require('./localizationResources');

interface LocalizationWrapperProps {
  pageState;
  children;
}

export const DEFAULT_LOCALE = 'en-US';
export const getLocaleFromState = (pageState) => {
  if (pageState.getCurrentPageState().rodeoLocalizationContext)
    return (
      pageState.getCurrentPageState().rodeoLocalizationContext.locale ??
      pageState.getCurrentPageState().rodeoLocalizationContext.defaultLocale
    );

  return DEFAULT_LOCALE;
};
const LocalizationWrapper = ({
  pageState,
  children,
}: LocalizationWrapperProps) => {
  const locale = getLocaleFromState(pageState);
  // Define a localization context provider
  const localizationContextProvider = () =>
    new LocalizationContextBuilder()
      .withLocale(locale)
      .withDefaultLocale(DEFAULT_LOCALE)
      .build();
  const mbm = createAsyncMessageBundleManager({
    arbManifest,
    localizationContextProvider,
    resolveResourcePack: async (url: string) => {
      if (url === undefined)
        throw new Error(
          'Unresolved URL, check arbManifest.endpointMap if the given message bundle & locale combination points to a file.'
        );

      if (!resources.resourcesMap.has(locale))
        return resources.resourcesMap.get('defaultResources');

      return resources.resourcesMap.get(locale);
    },
  });
  return <MbmProvider mbm={mbm}>{children}</MbmProvider>;
};

export default LocalizationWrapper;
