import { AnyFee, DisplayFee, Fee } from "src/model";
import { FeeStore } from "src/store/FeeStore";
import { PricingApiHelper } from "src/utils/PricingApiHelper";
import { ZERO_FEE } from "src/constants";

/**
 * Formats a Fee object for display purposes.
 *
 * @param {Fee} [fee=EMPTY_FEE] - The Fee object to be formatted.
 * @returns {DisplayFee} An object containing the formatted fee values, containing two formatted values, one that
 * accounts for currency of preference and one which uses the default currency.
 */
export const formatDisplayFee = (
    fee: AnyFee = ZERO_FEE,
): DisplayFee => {
    let copFormat: string = "--";
    let defaultFormat: string = "--";
    const feeStore: FeeStore = PricingApiHelper.getInstance().getFeeStore();
    if (feeStore && fee) {
        // @ts-ignore
        if (!isNaN(fee.value)) {
            if (fee.currency)
                // @ts-ignore
                defaultFormat = (Number((fee.value))).toLocaleString(undefined, { style: 'currency', currency: fee.currency })
            else if (feeStore.getState().currencyOfMarketplace)
                // @ts-ignore
                defaultFormat = (Number((fee.value))).toLocaleString(undefined, { style: 'currency', currency: feeStore.getState().currencyOfMarketplace})
        }
    }

    const fxConversionRate = feeStore.getState().fxConversionRate;
    const currencyOfPreference = feeStore.getState().currencyOfPreference;
    if (feeStore && fee && fxConversionRate && currencyOfPreference) {
        // @ts-ignore
        if (!isNaN(fee.value)) {
            // @ts-ignore
            copFormat = Number((fee.value * fxConversionRate).toFixed(2)).toLocaleString(undefined, { style: 'currency', currency: currencyOfPreference })
        }
    }

    return {
        ...fee,
        copDisplayValue: copFormat,
        displayValue: defaultFormat
    }
}

/**
 * Formats a fee value as a percentage for display purposes.
 *
 * @param {AnyFee} fee - The fee object to be formatted. If not provided, defaults to a zero fee.
 * @returns {DisplayFee} An object containing the formatted fee value and display value.
 *
 * @example
 * const fee = { value: '0.15' };
 * const displayFee = formatPercentDisplayFee(fee);
 * console.log(displayFee.value); // Output: '15.00'
 * console.log(displayFee.displayValue); // Output: '15.00%'
 */
export const formatPercentDisplayFee = (fee: AnyFee = ZERO_FEE): DisplayFee => {
    const PERCENT_FACTOR = 100;
    if (fee.value) {
        return {
            ...fee,
            value:  (Number(fee.value) * PERCENT_FACTOR).toFixed(2).toString(),
            displayValue: (Number(fee.value) * PERCENT_FACTOR).toFixed(2).toString() + '%'
        }
    } else {
        return {
            ...fee,
            value: '--',
            displayValue: '--%'
        }
    }
}

/**
 * Builds a Fee object representing the total fee based on the provided parameters.
 *
 * @param {number} totalFeeValue - The total fee value.
 * @param {Fee} observedMaxFirstPartyFee - The observed maximum first-party fee.
 * @param {Fee} observedMaxThirdPartyFee - The observed maximum third-party fee.
 * @returns {Fee} An object representing the total fee.
 */
export const buildTotalFeeObject = (
    totalFeeValue: number,
    observedMaxFirstPartyFee: Fee,
    observedMaxThirdPartyFee: Fee,
): Fee => {
    // @ts-ignore
    return {
        value: totalFeeValue.toString(),
        currency: observedMaxFirstPartyFee.currency || observedMaxThirdPartyFee.currency,
        range: {
            // @ts-ignore
            max: Math.max(Number(observedMaxFirstPartyFee.range?.max?.amount), Number(observedMaxThirdPartyFee.range?.max?.amount)).toFixed(2).toString(),
            // @ts-ignore
            min: Math.min(Number(observedMaxThirdPartyFee.range?.min?.amount), Number(observedMaxFirstPartyFee.range?.min?.amount)).toFixed(2).toString(),
        },
        isLoading: observedMaxFirstPartyFee.isLoading
    }
}
