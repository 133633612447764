import { LineItemV1 } from '@amzn/d16g-state-management-interfaces';
import { Common } from '@mfe/state-management-interfaces';
import { includes } from 'lodash-es';
import { DeepPartial } from "@mfe/state-management-interfaces/dist/Common";
import { LineItemModel } from "@amzn/d16g-state-management-interfaces/dist/v1/LineItem";
import { Fee, ThirdPartyAudienceDisplayFee } from "src/model";
import { THIRD_PARTY_CATEGORIES } from "@amzn/d16g-pricing-shared-client-library";

export const STV_SUPPLY_SOURCE_IDS = [4106, 611, 4032]; // list all STV supply source ids
export const TWITCH_SUPPLY_SOURCE_IDS = [650, 651]; // list of all Twitch supply source ids
export const PVA_SUPPLY_SOURCE_ID = [623];
export const OTHER_SUPPLY_SOURCE_ID = [610];

export const FeeTypes = {
  VIDEO: 'VIDEO',
  WEB: 'WEB',
  STV: 'STV',
  STREAMING_AUDIO: 'STREAMING_AUDIO',
  PVA: 'PVA',
  OTT_VIDEO: 'OTT_VIDEO',
} as const;

export type FeeTypes = typeof FeeTypes[keyof typeof FeeTypes];
export const videoSubFeeTypes = [FeeTypes.STV, FeeTypes.PVA, FeeTypes.OTT_VIDEO];

export const AdTypes = {
  OTT_VIDEO: "OTT_VIDEO",
  AAP_VIDEO_CPM: "AAP_VIDEO_CPM"
}

export type AdTypes = typeof AdTypes[keyof typeof AdTypes];

export const SELECTED_SOURCES = 'SELECTED_SOURCES';

export function hasSTVSources(
  lineItemState: Common.DeepPartial<LineItemV1.LineItemModel> | undefined
) {
  if (!lineItemState) return false;
  const supplySourceType =
    lineItemState?.targetingDetails?.supplySourceDetails?.supplySourceType;

  if (supplySourceType === SELECTED_SOURCES) {
    if (lineItemState?.supplySourcesTree_NON_HOUSE_VIDEO?.some(isSTVSource))
      return true;
    else if (
      lineItemState?.targetingDetails?.supplySourceDetails?.supplySources?.some(
        isSTVSource
      )
    )
      return true;
  } else {
    const includeOttSupply =
      // @ts-ignore
      lineItemState?.externalSupplySourceView?.includeOttSupply;

    const includeTwitchSelfServiceStandalone =
      // @ts-ignore
      lineItemState?.externalSupplySourceView
        ?.includeTwitchSelfServiceStandalone;
    // returns true if the STV or Twitch supply source checkbox is checked
    if (
      includeOttSupply ||
      includeTwitchSelfServiceStandalone
    )
      return true;
  }
  return false;
}

export function hasPVASource(
  lineItemState: Common.DeepPartial<LineItemV1.LineItemModel> | undefined
) {
  if (!lineItemState) return false;
  return (
    !!lineItemState?.supplySourcesTree_NON_HOUSE_VIDEO?.some(isPVASource) ||
    !!lineItemState?.targetingDetails?.supplySourceDetails?.supplySources?.some(
      isPVASource
    )
  );
}

export function isSTVSource(source: string | number) {
  return (
    includes(TWITCH_SUPPLY_SOURCE_IDS, +source) ||
    includes(STV_SUPPLY_SOURCE_IDS, +source)
  );
}

export function isPVASource(source: string | number) {
  return includes(PVA_SUPPLY_SOURCE_ID, +source);
}

export function getImpressionSupplyType (
    lineItemState: DeepPartial<LineItemModel> | undefined
): FeeTypes | undefined {
  if (!lineItemState) {
    // default is WEB
    return FeeTypes.WEB;
  }
  const lineItemType = lineItemState?.type;

  if (lineItemType === AdTypes.OTT_VIDEO) {
    return FeeTypes.OTT_VIDEO;
  }

  if (lineItemType === AdTypes.AAP_VIDEO_CPM) {
    if (hasSTVSources(lineItemState)) return FeeTypes.STV;

    return FeeTypes.VIDEO;
  }

  return FeeTypes.WEB;
}