import * as React from 'react';
import { Filter } from '../../models';
import { IFilterParams } from 'ag-grid-community';

/**
 * Component to set properties for searching the UDC-table.
 */
export class AudienceSearchFilter extends React.Component<
  IFilterParams,
  { filterSet?: Filter }
> {
  constructor(props: IFilterParams) {
    super(props);

    this.state = {
      filterSet: undefined,
    };
  }

  public isFilterActive() {
    // eslint-disable-line class-methods-use-this
    return true;
  }

  public doesFilterPass() {
    // eslint-disable-line class-methods-use-this
    return true;
  }

  public getModel() {
    return { value: this.state.filterSet };
  }

  public setModel(filterSet: Filter) {
    this.setState({ filterSet });
  }

  public render() {
    return null;
  }
}
