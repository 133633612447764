import React, { useState } from 'react';
import { Text, Icon } from '@amzn/storm-ui';
import { Audience } from '../../../models';
import styled from 'styled-components';

type ActionRendererProps = {
  /**
   * segment data for this row.
   */
  data: Audience;
};

const AudienceNameStyle = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const HighlightOnHoverText = styled.span`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  user-select: all;
`;

const AudienceNameText = styled(Text)`
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 14px;
`;

/**
 * Column with the human-readable name of the segment, as well as it's canonicalID as subtext.
 */
export const AudienceNameRenderer = ({ data }: ActionRendererProps) => {
  const [showCopy, setShowCopy] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  if (!data) return null;

  const { audienceId, audienceName } = data as Audience;

  const onIdHover = () => {
    setShowCopy(true);
  };
  const onIdLeave = () => {
    setShowCopy(false);
  };

  const onCopyClicked = () => {
    navigator.clipboard.writeText(audienceId);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1000);
  };

  return (
    <AudienceNameStyle>
      <AudienceNameText type={'h5'} fontSize="small" title={audienceName}>
        {audienceName}
      </AudienceNameText>
      <HighlightOnHoverText
        data-testid={'audience-id-container'}
        onMouseEnter={onIdHover}
        onMouseLeave={onIdLeave}
        onClick={onCopyClicked}
      >
        <Text textColor="tertiary" fontSize="mini">
          {audienceId}
        </Text>
        {showCopy && !isCopied && <Icon type={'copy'}></Icon>}
        {isCopied && (
          <Text textColor="secondary" fontSize="mini">
            - Copied
          </Text>
        )}
      </HighlightOnHoverText>
    </AudienceNameStyle>
  );
};
