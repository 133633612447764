import { TypedPatch, MFEBootstrap } from '@mfe/state-management';
import { LineItemPageState } from 'src/model/LineItemPageState';
import { BulkEditPageState } from 'src/model/BulkEditPageState';
import useBulkEditPageState from 'src/utils/hooks/useBulkEditPageState';

export type StatePatchApplier = (
  patches: TypedPatch.TypedPatch<LineItemPageState>[]
) => void;

function useAbstractedBulkEditPageState(
  pageState: MFEBootstrap.PageState<BulkEditPageState>,
  subscribeToUpdates?: boolean
): [BulkEditPageState, StatePatchApplier] {
  const [globalState, applyStatePatches] = useBulkEditPageState(
    pageState,
    subscribeToUpdates
  );
  const abstractedBulkEditPageState: BulkEditPageState = {
    ...globalState,
    orderLineItemBulkEditState: globalState.orderLineItemBulkEditState,
  };

  const applyAbstractedStatePatches = (
    patches: TypedPatch.TypedPatch<BulkEditPageState>[]
  ): void => {
    applyStatePatches(patches);
  };

  return [abstractedBulkEditPageState, applyAbstractedStatePatches];
}

export default useAbstractedBulkEditPageState;
