import { whenAmazonTakt } from '@amzn/takt-utils';
import { SegmentInsights } from '../state/taktLoggingContext';

const context = 'AudienceSuggestions';
const twitchContext = 'TwitchSuggestion';
const targetingContext = 'PickerTargetingSelection';
const audienceTargetingMatchTypeContext = 'AudienceTargetingMatchType';

type AudienceSuggestionsEventProps = {
  advertiserId?: string;
  entityId?: string;
  lineItemId?: string;
  campaignId?: string;
  suggestedSegments: SegmentInsights[];
};

type AudienceTargetingEventProps = {
  metricName: string;
  advertiserId?: string;
  payload: string;
};

type TwitchSuggestionEventProps = {
  metricName: string;
  segmentIds: string[];
  lineItemType: string;
  advertiserId?: string;
  lineItemId?: string;
};

type AudienceTargetingMatchTypeCheckboxEventProps = {
  metricName: string;
  checkboxEnabled: boolean;
  advertiserId?: string;
  lineItemId?: string;
};

const logAudienceSuggestionsEvent = ({
  advertiserId,
  entityId,
  lineItemId,
  campaignId,
  suggestedSegments,
}: AudienceSuggestionsEventProps) => {
  if (!suggestedSegments.length) return;

  whenAmazonTakt(amazonTakt => {
    if (entityId)
      amazonTakt.logCustom({
        context,
        details: JSON.stringify({
          event: 'AudienceSuggestionsUsedByEntity',
          entityId,
        }),
      });

    if (advertiserId)
      amazonTakt.logCustom({
        context,
        details: JSON.stringify({
          event: 'AudienceSuggestionsUsedByAdvertiser',
          entityId,
          advertiserId,
        }),
      });

    if (lineItemId)
      amazonTakt.logCustom({
        context,
        details: JSON.stringify({
          event: 'AudienceSuggestionsUsedByLineItem',
          entityId,
          lineItemId,
        }),
      });

    if (campaignId)
      amazonTakt.logCustom({
        context,
        details: JSON.stringify({
          event: 'AudienceSuggestionsUsedByCampaign',
          entityId,
          campaignId,
        }),
      });

    amazonTakt.logCustom({
      context,
      details: JSON.stringify({
        event: 'SuggestedAudienceListAddedToInclude',
        advertiserId,
        entityId,
        lineItemId,
        campaignId,
        suggestedSegments,
      }),
    });
  });
};

const logTwitchSuggestionEvent = ({
  metricName,
  segmentIds,
  lineItemType,
  advertiserId,
  lineItemId,
}: TwitchSuggestionEventProps) => {
  whenAmazonTakt(amazonTakt => {
    amazonTakt.logCustom({
      context: twitchContext,
      details: JSON.stringify({
        event: metricName,
        segmentIds,
        lineItemType,
        advertiserId,
        lineItemId,
      }),
    });
  });
};

const logAudienceTargetingEvent = ({
  metricName,
  advertiserId,
  payload,
}: AudienceTargetingEventProps) => {
  whenAmazonTakt(amazonTakt => {
    amazonTakt.logCustom({
      context: targetingContext,
      details: JSON.stringify({
        event: metricName,
        advertiserId,
        payload,
      }),
    });
  });
};

const logAudienceTargetingMatchTypeCheckboxEvent = ({
  metricName,
  checkboxEnabled,
  advertiserId,
  lineItemId,
}: AudienceTargetingMatchTypeCheckboxEventProps) => {
  whenAmazonTakt(amazonTakt => {
    amazonTakt.logCustom({
      context: audienceTargetingMatchTypeContext,
      details: JSON.stringify({
        event: metricName,
        checkboxEnabled,
        advertiserId,
        lineItemId,
      }),
    });
  });
};

export {
  logTwitchSuggestionEvent,
  logAudienceTargetingEvent,
  logAudienceTargetingMatchTypeCheckboxEvent,
};
export default logAudienceSuggestionsEvent;
