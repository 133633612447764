import styled from 'styled-components';
import { defaultTheme, Text } from '@amzn/storm-ui';

export const FilterCategoryHeader = styled(Text)`
  color: ${defaultTheme.palette.dustyGray};
  text-transform: uppercase;
  font-weight: 600;
`;

export const DropdownContainer = styled.div`
  padding: 10px 15px;
  width: 250px;
  text-transform: capitalize;
`;
