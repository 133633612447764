import React, { useContext } from 'react';
import AudienceSegmentComponent from '../../../components/AudienceTargeting/AudienceSegment/AudienceSegmentComponent';
// @ts-ignore
import { AudienceGroupStyled } from '../../../components/AudienceTargeting/AudienceGroup/AudienceGroupComponent.styled';
import WarningsComponent from '../WarningsComponent/WarningsComponent';
import { Audience, AudienceGroup, AudienceGroupIndex } from '../../../models';
import { Divider, Dropdown, DropdownItem, Text } from '@amzn/storm-ui';
import {
  capitalize,
  TranslationContext,
} from '../../../state/translationContext';
import {
  EXCLUDE,
  GROUP,
  NO_AUDIENCES_ADDED,
  OPERATOR,
  REMOVE,
} from '../../../constants/translations';
import { INCLUDE } from '../../../constants';
import {
  TaktLoggingContext,
  TaktLoggingContextType,
} from '../../../state/taktLoggingContext';

type SegmentGroupComponentProps = {
  /**
   * The audience group data to be shown
   */
  audienceGroup: AudienceGroup;
  /**
   * Whether an audienceGroup is currently highlighted as the one that segments will be added to
   */
  isSelected: boolean;
  /**
   * Callback method to remove a segment from a given group
   */
  removeSegmentFromGroup: (segment: Audience) => void;
  /**
   * Method to check if the intraset operator of a group has been updated.
   */
  intrasetChange?: (term: string) => void;
  /**
   * Method to check if the interset operator of a group has been updated.
   */
  intersetChange?: (term: string) => void;
  /**
   * Callback method to entirely remove a group from the IncludeExcludeGroups
   */
  removeGroup: (event: MouseEvent) => void;
  /**
   * Whether the button to remove a group should be disabled or not. i.e. when there is only one group.
   */
  disableRemove?: boolean;
  /**
   * Whether to show the "legacy" logic version of the picker, or the "include/exclude" logic version of the picker.
   */
  useLegacy?: boolean;
  /**
   * Total number of audience groups being shown in the audience picker component
   */
  audienceGroupCount: number;
  /**
   * Index of the current group
   */
  audienceGroupIndex: AudienceGroupIndex;
  /**
   * Callback function to mark the clicked on group as "selected" for audience segments to be added to it.
   */
  selectAudienceGroup: (indexToSelectGroup: AudienceGroupIndex) => void;
  /**
   * List of feeSupplyType(s) that dictate which fee to use from a given audience segment
   */
  feeSupplyType?: string[];
  /**
   * The maximum number of segments that can be added to a group before an error is shown and no more audiences can be added to that group.
   */
  maxSegmentsPerGroup?: number;
};

const OR = 'OR';
const AND = 'AND';

/**
 * A group that audience segments can be added to
 */
const AudienceGroupComponent = (props: SegmentGroupComponentProps) => {
  const {
    intrasetChange,
    intersetChange,
    selectAudienceGroup,
    removeSegmentFromGroup,
    removeGroup,
    audienceGroupIndex,
    isSelected,
    disableRemove,
    useLegacy,
    audienceGroup,
    audienceGroupCount,
    feeSupplyType,
    maxSegmentsPerGroup,
  } = props;
  const getTranslation = useContext(TranslationContext);

  if (maxSegmentsPerGroup)
    if (audienceGroup.segments.length > maxSegmentsPerGroup) {
      removeSegmentFromGroup(
        audienceGroup.segments[audienceGroup.segments.length - 1]
      );
    }

  const orderSegments = (unorderedSegments: Audience[]) => {
    const includes = unorderedSegments.filter(x => !x.not);
    const excludes = unorderedSegments.filter(x => x.not);
    return [...includes, ...excludes];
  };

  const segmentList = orderSegments(audienceGroup.segments);

  const TRANS_OR = getTranslation(OR.toLowerCase());
  const TRANS_AND = getTranslation(AND.toLowerCase());

  const dividerText = (segment: Audience, index: number) => {
    let text = '';
    if (index !== 0)
      text += audienceGroup.intrasetOperator === OR ? TRANS_OR : TRANS_AND;

    if (segment.not)
      text += text ? ` ${getTranslation(EXCLUDE)}` : getTranslation(EXCLUDE);
    else text += text ? ` ${getTranslation(INCLUDE)}` : getTranslation(INCLUDE);

    return text;
  };

  const { removeFromSuggestedSegments } = useContext<TaktLoggingContextType>(
    TaktLoggingContext
  );

  const handleRemoveSegmentFromGroupEvent = (segment: Audience) => () => {
    removeSegmentFromGroup(segment);
    removeFromSuggestedSegments(segment.audienceId);
  };

  return (
    <>
      <div onClick={() => selectAudienceGroup(audienceGroupIndex)}>
        <AudienceGroupStyled.Container
          isSelected={isSelected}
          data-testid={'audience-group-container'}
        >
          <AudienceGroupStyled.ActionRow>
            {useLegacy && intrasetChange && (
              <>
                <AudienceGroupStyled.AlignedText type={'h5'}>
                  {getTranslation(GROUP)}{' '}
                  {audienceGroupIndex.audienceGroupIndex + 1} |
                </AudienceGroupStyled.AlignedText>

                <Dropdown
                  onChange={(term: string) => intrasetChange(term)}
                  selectedValue={audienceGroup.intrasetOperator}
                  small={true}
                  data-testid={'intraset-operator-change'}
                >
                  <DropdownItem value={OR}>{TRANS_OR}</DropdownItem>
                  <DropdownItem value={AND}>{TRANS_AND}</DropdownItem>
                </Dropdown>
              </>
            )}
            <AudienceGroupStyled.RemoveButton
              test-dataid={'audience-group-remove-button'}
              disabled={disableRemove}
              // @ts-ignore
              onClick={removeGroup}
            >
              {capitalize(getTranslation(REMOVE))}
            </AudienceGroupStyled.RemoveButton>
          </AudienceGroupStyled.ActionRow>
          {segmentList?.length > 0 &&
            segmentList.map((segment: Audience, index: number) => (
              <>
                {useLegacy && (
                  <Divider
                    style={{ margin: '5px 0px' }}
                    text={dividerText(segment, index)}
                  />
                )}
                <AudienceSegmentComponent
                  key={index}
                  segment={segment}
                  feeSupplyType={feeSupplyType}
                  onRemove={handleRemoveSegmentFromGroupEvent(segment)}
                />
                {!useLegacy && segmentList?.length - 1 !== index && (
                  <Divider style={{ margin: '5px 0px' }} text={'or'} />
                )}
              </>
            ))}
          {segmentList?.length === 0 && (
            <AudienceGroupStyled.MessageRow>
              <Text
                textColor={'secondary'}
                data-testid={'no-audiences-added-msg'}
              >
                {getTranslation(NO_AUDIENCES_ADDED)}
              </Text>
            </AudienceGroupStyled.MessageRow>
          )}
          <WarningsComponent
            segmentList={segmentList}
            intrasetOperator={audienceGroup.intrasetOperator}
            maxSegmentsReached={maxSegmentsPerGroup === segmentList.length}
          />
        </AudienceGroupStyled.Container>
      </div>

      {audienceGroupCount - 1 !== audienceGroupIndex.audienceGroupIndex &&
        useLegacy &&
        intersetChange && (
          <AudienceGroupStyled.IntersetDivider>
            <AudienceGroupStyled.AlignedText>
              {getTranslation(OPERATOR)}:
            </AudienceGroupStyled.AlignedText>
            <Dropdown
              onChange={(term: string) => intersetChange(term)}
              selectedValue={audienceGroup.intersetOperator}
              small={true}
              data-testid={'interset-operator-change'}
            >
              <DropdownItem value={OR}>{TRANS_OR}</DropdownItem>
              <DropdownItem value={AND}>{TRANS_AND}</DropdownItem>
            </Dropdown>
          </AudienceGroupStyled.IntersetDivider>
        )}
      {audienceGroupCount - 1 !== audienceGroupIndex.audienceGroupIndex &&
        !useLegacy && (
          <AudienceGroupStyled.AndDivider data-testid={'and-divider'}>
            {getTranslation(AND.toLowerCase())}
          </AudienceGroupStyled.AndDivider>
        )}
    </>
  );
};

export default AudienceGroupComponent;
