import React, { createRef, FunctionComponent, useState } from 'react';
import { ThemeProvider, Text } from '@amzn/storm-ui';
import {
  AddEditButtonContainer,
  PredicateNode,
  ChangeButton,
  FlexWrapper,
  PredicateNodeTextContainer,
  PredicateNodeTitleContainer,
  SummaryViewCard,
} from './PredicatePanel.styled';

import { PredicateView } from './PredicateView';
import { NavPredicateProps } from './Props';
import { TreeNode } from '../data/TreeNode';
import { buildSummaryView } from './utils/BuildSummaryView';

export const NavPredicateName: FunctionComponent<NavPredicateProps> = ({
  name,
  description,
  apiData,
}) => {
  const [isPredicateViewOpen, setPredicateViewOpen] = useState(false);
  const [savedTargetList, setSavedTargetList] = useState<TreeNode[]>([]);
  const [savedExcludeList, setSavedExcludeList] = useState<TreeNode[]>([]);
  const [savedDetailedTargetedNodes, setSavedDetailedTargetedNodes] = useState<
    Set<string>
  >(new Set());
  const [savedDetailedExcludedNodes, setSavedDetailedExcludedNodes] = useState<
    Set<string>
  >(new Set());
  const buttonRef = createRef();

  const handleSaveChanges = (
    targetList: TreeNode[],
    excludeList: TreeNode[],
    detailedTargetedNodes: Set<string>,
    detailedExcludedNodes: Set<string>
  ) => {
    setSavedTargetList(targetList);
    setSavedExcludeList(excludeList);
    setSavedDetailedTargetedNodes(detailedTargetedNodes);
    setSavedDetailedExcludedNodes(detailedExcludedNodes);
    setPredicateViewOpen(false);
  };

  const handlePredicateViewOpen = () => {
    setPredicateViewOpen(!isPredicateViewOpen);
  };

  return (
    <ThemeProvider>
      <PredicateNode>
        <PredicateNodeTitleContainer>
          <FlexWrapper>
            <PredicateNodeTextContainer>
              <Text type="h5">{`${name}`}</Text>
              {savedTargetList.length === 0 && savedExcludeList.length === 0 && (
                <Text type="p" textColor="tertiary">
                  {`Add ${description}`}
                </Text>
              )}
              <SummaryViewCard
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap:
                    savedTargetList.length > 0 && savedExcludeList.length > 0
                      ? '16px'
                      : '0',
                }}
              >
                {savedTargetList.length > 0 &&
                  buildSummaryView('targeted', true, savedTargetList)}
                {savedExcludeList.length > 0 &&
                  buildSummaryView('excluded', false, savedExcludeList)}
              </SummaryViewCard>
            </PredicateNodeTextContainer>
          </FlexWrapper>
        </PredicateNodeTitleContainer>
        <AddEditButtonContainer>
          <ChangeButton
            buttonRef={buttonRef}
            small
            onClick={handlePredicateViewOpen}
          >
            Change
          </ChangeButton>
        </AddEditButtonContainer>
        {isPredicateViewOpen && (
          <PredicateView
            name={name}
            description={description}
            setIsOpen={setPredicateViewOpen}
            onSave={handleSaveChanges}
            initialTargetList={savedTargetList}
            initialExcludeList={savedExcludeList}
            initialDetailedTargetedNodes={savedDetailedTargetedNodes}
            initialDetailedExcludedNodes={savedDetailedExcludedNodes}
            apiData={apiData}
          />
        )}
      </PredicateNode>
    </ThemeProvider>
  );
};
