import { useContext } from 'react';
import {
  AudienceTargetingContext,
  IAudienceTargetingContext,
} from '../state/audienceTargetingContext';

/**
 * Method to create an audienceTargetingContext so that audience targeting actions can be taken from anywhere.
 */
export const useAudienceTargeting = (): IAudienceTargetingContext => {
  const context: IAudienceTargetingContext = useContext(
    AudienceTargetingContext
  ) as IAudienceTargetingContext;

  if (context === undefined)
    throw new Error(
      'useAudienceTargeting must be used within AudienceTargetingContext'
    );

  return context;
};
