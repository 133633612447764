import styled from 'styled-components';

interface StyleProps {
  targetedAudienceSummary: boolean;
}

export const PriceSummaryContainer = styled.div<StyleProps>`
  background: white;
  z-index: 1;
  position: sticky;
  top: 0;
  padding: ${props =>
    props.targetedAudienceSummary ? '0' : '10px 10px 10px 20px'};
  border-bottom: ${props =>
    props.targetedAudienceSummary ? '0' : '1px solid #e7e7e7;'};
`;
export const AudienceFeeContainer = styled.div<StyleProps>`
  padding: ${props =>
    props.targetedAudienceSummary ? '5px 0px 0px 0px' : '0 10px 5px 0'};
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  color: #232f3f;
`;

export const AudienceFeeContents = styled.div<StyleProps>`
  padding: ${props =>
    props.targetedAudienceSummary ? '5px 0px 0px 10px' : '0 10px 5px 0'};
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  color: #495566;
`;

export const AudienceFeeValueContainer = styled.div`
  display: flex;
  justify-content: flex;
  flex-direction: column;
  align-items: end;
`;
