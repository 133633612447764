import { defaultTheme } from '@amzn/storm-ui';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  position: relative;
  height: 100%;
`;

const TableContainer = styled.div`
  flex: 0 1 70%;
  background: #f3f3f3;
  height: 100%;
`;

interface SelectionsContainerProps {
  selectionContainerHeight: number;
}
const SelectionsContainer = styled.div<SelectionsContainerProps>`
  overflow: auto;
  max-height: ${props => props.selectionContainerHeight}px;
  min-width: 300px;
  flex: 0 1 30%;
  background-color: ${defaultTheme.palette.gray[50]};
`;

export const AudiencePickerStyled = {
  Container,
  TableContainer,
  SelectionsContainer,
};
