import { TranslatorString } from 'src/Localization/LocalizationConfig';

// eslint-disable-next-line no-shadow
export enum ComponentNames {
  AUDIENCE_TARGETING = 'adsp-line-item-audience-targeting',
  NONCARD_AUDIENCE_TARGETING = 'adsp-non-card-audience-targeting',
  BULK_EDIT_TARGETING = 'adsp-bulk-edit-audience-targeting',
}

// eslint-disable-next-line no-shadow
export enum ComponentIds {
  AUDIENCE_TARGETING = 'ADSPPRICING_LINEITEM_AUDIENCE_TARGETING',
  AUDIENCE_TARGETING_NONCARD = 'ADSPPRICING_NONCARD_AUDIENCE_TARGETING',
  BULK_EDIT_TARGETING = 'ADSPPRICING_BULK_EDIT_AUDIENCE_TARGETING',
}

// eslint-disable-next-line no-shadow
export enum LineItemTypes {
  STANDARD_DISPLAY = 'AAP_CPM',
  MOBILE_APP = 'AAP_MOBILE_APP',
  MOBILE_WEB = 'AAP_MOBILE_WEB',
  VIDEO = 'AAP_VIDEO_CPM',
  OTT_VIDEO = 'OTT_VIDEO',
  AUDIO = 'AAP_AUDIO_CPM',
}

// eslint-disable-next-line no-shadow
export enum PageTypes {
  LINE_ITEM_NEW = 'LINE_ITEM_NEW',
  LINE_ITEM_EDIT = 'LINE_ITEM_EDIT',
  LINE_ITEM_CLONE = 'LINE_ITEM_CLONE',
}

// eslint-disable-next-line no-shadow
export enum SummaryViewSectionTitle {
  TARGETING = TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TITLE_TARGETING,
  SELECTED = TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TITLE_SELECTED,
  EXCLUDING = TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TITLE_EXCLUDING,
  APPLIED = TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TITLE_APPLIED,
  NONE = TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_TITLE_NONE,
}

// eslint-disable-next-line no-shadow
export const PROJECT_BASE_ID = 'd16g-rodeo-line-item';

export const THIRD_PARTY_CATEGORIES = ['Third-party', 'Advertiser audiences'];
export const CONTEXTUAL_CATEGORIES = ['Contextual'];
export const DEMOGRAPHIC_1P_CATEGORY = 'Demographic';
export const AGE_AND_GENDER_1P_SUB_CATEGORIES = ['Age', 'Gender'];
export const IN_MARKET_LIFESTYLE_1P_CATEGORIES = [
  'In-market',
  'Lifestyle',
  'Interest',
  'Life event',
];
export const TARGETED_AUDIENCE_IDS = [
  // gender=Male
  '368330822066611159',
  '430521397565363125',
  '430970247064040743',
  '380499191665620411',
  '378047250517698160',
  '428471033725130385',
  '426639336038458592',
  '382921883640155124',
  '371437656655354565',
  '382859859157269535',
  '372501268864863455',
  '373874605666351445',
  '392568384353115721',
  '396714677931195984',
  '388165399050791135',
  '422776023361587431',

  // age 18-24
  '415359161282390810',
  '370293867020021485',
  '420994306561287704',
  '380234977357952061',
  '381576310472434714',
  '405670483292088260',
  '384768111703165403',
  '379158983239640060',
  '392589906482911647',
  '371652892268318911',
  '363727624938624029',
  '385920155632910312',
  '393104359985278323',
  '412465454445750391',
  '378577286955861281',

  // age 25-34
  '386105689833016518',
  '378922310131337345',
  '405251163753365983',
  '371224672012344586',
  '400949725961897413',
  '412888477872604275',
  '404320511951064472',
  '376260347865039819',
  '399184371425790118',
  '394379996049577833',
  '398920770427757151',
  '421607736154785876',
  '372513986618638118',
  '420371309807824456',
  '363688798896694512',
  '390234291317638814',
  '378904483223284554',

  // Interest = technology enthusiast
  '366978923856692427',
  '361695681339825370',
  '414048439086267480',
  '414882918065540231',
  '383242335975249236',
  '369848932874642991',
  '368607719006118485',
  '426934725820557875',
  '378948029715602761',
  '399278790932024323',

  // Interest = video games
  '425053821118357035',
  '417603986428237467',
  '394180927638822030',
  '421802355742444926',
  '386584112465165904',
  '411541099536399862',
  '389361778629556013',
  '374059189534498251',
  '374944602186855804',

  // Video Game Enthusiast
  '385013808258756806',
  '383045794834710676',
  '430647363950244539',
  '381264293471416647',

  // Entertainment Enthusiast
  '386297379189132706',
  '427357260719434719',
  '416177080142474517',
  '364442338672517899',
  '422682207894352719',
  '392112390926686040',
  '414002904816720144',
  '386297379189132706',
];

export const AUDIENCE_TARGETING_MATCH_TYPE_CHECKBOX_ALLOW_LIST_BY_ADVERTISER_ID =
  [
    '9570031520101', // dev-server advertiserId
    '587937286177993844',
    // https://advertising.amazon.com/dsp/ENTITYHA6O7FB0QLPR/advertisers/585957566706201540/orders - US
    '585957566706201540',
    // https://advertising.amazon.co.uk/dsp/ENTITY1BVW0IYJJJV0R/advertisers/589250137525086299/orders - GB
    '589250137525086299',
    // https://advertising.amazon.co.jp/dsp/ENTITY1VHVRSIVA49BF/advertisers/9288496780003/orders - JP
    '9288496780003',
    // https://advertising-preprod.amazon.com/dsp/ENTITYA3HAQUY9BOUM/advertisers/577081794611985435/orders
    // Gamma test ad line item - NA
    '577081794611985435',
    // closed beta participant - JP - https://advertising.amazon.co.jp/dsp/ENTITY3RU1L8L5C5JG5/advertisers/577612785117224865/orders
    '577612785117224865',
    // closed beta participant - US - https://advertising.amazon.com/dsp/ENTITYOFQ6HH015TTA/advertisers/7505537570901/orders
    '7505537570901',
    // closed beta participant - US - https://advertising.amazon.com/dsp/ENTITY3NQM38JXZ26OY/advertisers/585810624362384922/orders
    '585810624362384922',
    // closed beta participant - US - https://advertising.amazon.com/dsp/ENTITY38DSYEUXZ2A4I/advertisers/577713853020174812/orders
    '577713853020174812',
    // closed beta participant - US - https://advertising.amazon.com/dsp/ENTITY38DSYEUXZ2A4I/advertisers/590125850324284719/orders
    '590125850324284719',
    // closed beta participant - US - https://advertising.amazon.com/dsp/ENTITY1Z193COM8A41D/advertisers/577879043219374866/orders
    '577879043219374866',
    // closed beta participant - US - https://advertising.amazon.com/dsp/ENTITY21AU5UBBEHQBQ/advertisers/9617354430801/orders
    '9617354430801',
    // https://advertising.amazon.com/dsp/ENTITY2ZDCJ90TD8B42/advertisers/3259068250901/orders
    '7205779620002',
    // closed beta participant - UK - https://advertising.amazon.co.uk/dsp/ENTITYEA0SEJNT22CT/advertisers/7205779620002/orders
    '3259068250901',
    // Cyress Integration Test Advertiser
    '585149259886367837',
  ];
