import { memo, useRef } from "react";
import { FeeProps } from "src/store/FeeStore";
import { FeeContext } from "src/context/FeeContext";
import { PricingApiHelper } from "src/utils/PricingApiHelper";
import useConstant from "use-constant";
import { MFEBootstrap } from "@mfe/state-management";
import usePageStateFeeManager from "src/hooks/internal/usePageStateFeeManager";
import { PricingApiContext, PricingApiData } from "src/model/ApiData.model";
import { InitialStorePropsBuilder, PricingApiDataBuilder } from "src/utils/PricingApiDataBuilder";

interface FeeContextWrapperProps {
  children: React.ReactNode;
  initStoreProps?: Partial<FeeProps>;
  pageState: MFEBootstrap.PageState<any>;
  apiData?: PricingApiData;
  pricingApiContext?: PricingApiContext;
}

const FeeContextWrapper = memo(function FeeContextWrapper({children, initStoreProps, apiData, pageState, pricingApiContext}: FeeContextWrapperProps) {
  useConstant(() => {
    //TODO: Implement Axios window. synching if the axios is not shared.
    //@ts-ignore
    const pricingApiData = apiData || PricingApiDataBuilder(pageState, pricingApiContext);
    const initialStoreProps = InitialStorePropsBuilder(initStoreProps, pricingApiContext);
    PricingApiHelper.initialize(pricingApiData, initialStoreProps);
  })

  const feeContext = useRef(PricingApiHelper.getInstance().getFeeStore()).current;

  usePageStateFeeManager(pageState, feeContext);

  return (
      // @ts-ignore
      <FeeContext.Provider value={feeContext}>
        {children}
      </FeeContext.Provider>
  )
})

export default FeeContextWrapper