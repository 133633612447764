import React, { useContext } from 'react';
import { Audience } from '../../../models';
import { WarningContainer } from './WarningsComponent.styled';
import { Icon, Text } from '@amzn/storm-ui';
import styled from 'styled-components';
import { TranslationContext } from '../../../state/translationContext';
import {
  INCLUDE_EXCLUDE_WITH_OR_WARNING,
  MULTIPLE_EXCLUDE_WITH_OR_WARNING,
  MAX_SEGMENTS_REACHED,
} from '../../../constants/translations';
type WarningsComponentProps = {
  /**
   * AND or OR intraset property of a given group
   */
  intrasetOperator: string;
  /**
   * List of segments that are within a given group
   */
  segmentList: Audience[];
  /**
   * Whether the maxSegmentsPerGroup property is equal to the length of the segmentList for the group.
   */
  maxSegmentsReached?: boolean;
};

const WarningIcon = styled(Icon)`
  cursor: pointer;
  color: orange;
  padding: 0 5px 0 0;
`;

/**
 * Component to show various warning messages within a group based on the passed in props.
 */
const WarningsComponent = ({
  intrasetOperator,
  segmentList,
  maxSegmentsReached,
}: WarningsComponentProps) => {
  const excluded = segmentList.filter(x => x.not);
  const included = segmentList.filter(x => !x.not);
  const getTranslation = useContext(TranslationContext);

  const getError = () => {
    if (intrasetOperator === 'OR') {
      if (included.length && excluded.length)
        return getTranslation(INCLUDE_EXCLUDE_WITH_OR_WARNING);

      if (excluded.length > 1)
        return getTranslation(MULTIPLE_EXCLUDE_WITH_OR_WARNING);
    }
    if (maxSegmentsReached) return getTranslation(MAX_SEGMENTS_REACHED);

    return ``;
  };

  return (
    <div data-testid={'warning-component'}>
      {getError() && (
        <WarningContainer>
          <WarningIcon
            style={{ cursor: 'pointer', color: 'orange', padding: '0 5px 0 0' }}
            type="exclamation-triangle"
            size={'xs'}
          />
          <Text fontSize={'mini'}>{getError()}</Text>
        </WarningContainer>
      )}
    </div>
  );
};

export default WarningsComponent;
