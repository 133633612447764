import React, { useContext } from 'react';
import { CheckboxItem } from '@amzn/d16g-rodeo-website-shared-components/src/components/checkbox-item/CheckboxItem';
import { MFEBootstrap } from '@mfe/state-management';
import { LineItemProposalPageState } from 'src/model/LineItemPageState';
import useFormField from 'src/utils/hooks/useFormField';
import { ComponentIds } from 'src/utils/MFEConstants';
import { LineItemV1 } from '@amzn/d16g-state-management-interfaces';
import { TextButton } from '@amzn/storm-ui';
import { TranslatorString } from 'src/Localization/LocalizationConfig';
import styled from 'styled-components';
import * as IdGenerator from 'src/utils/IdGenerator';
import {
  logAudienceTargetingMatchTypeCheckboxEvent,
  TaktLoggingContext,
  TaktLoggingContextType,
} from '@amzn/d16g-audience-picker-component';
import { translateText } from 'src/Localization/LocalizationText';
import { useBundle } from '@amzn/react-arb-tools';

interface AudienceTargetingMatchTypeCheckboxProps {
  pageState: MFEBootstrap.PageState<LineItemProposalPageState>;
  initialAudienceTargetingMatchType?: string;
  learnMoreUrl: string;
}

const SIMILAR_AUDIENCE_TARGETING: LineItemV1.AudienceTargetingMatchType =
  'SIMILAR' as LineItemV1.AudienceTargetingMatchType;
const EXACT_AUDIENCE_TARGETING: LineItemV1.AudienceTargetingMatchType =
  'EXACT' as LineItemV1.AudienceTargetingMatchType;

const audienceTargetingMatchTypeCheckboxId = IdGenerator.generateHtmlId(
  'audienceTargetingMatchType-checkbox',
  ComponentIds.AUDIENCE_TARGETING
);

const audienceTargetingMatchTypeCheckboxItemId = IdGenerator.generateHtmlId(
  'audienceTargetingMatchType-checkbox-item',
  ComponentIds.AUDIENCE_TARGETING
);

const AudienceTargetingMatchTypeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: ${({ theme }) => theme.spacing.medium};
`;

const SimilarAudienceMoreInfoButtonWrapper = styled.div`
  margin-top: auto;
  padding-bottom: 10px;
  padding-left: 2px;
`;

function handleAudienceTargetingMatchTypeCheckboxEvent(
  checkboxEnabled: boolean,
  advertiserId?: string,
  lineItemId?: string
) {
  logAudienceTargetingMatchTypeCheckboxEvent({
    metricName: 'AUDIENCE_TARGETING_MATCH_TYPE_CHECKBOX_ENABLED',
    checkboxEnabled,
    advertiserId,
    lineItemId,
  });
}

const AudienceTargetingMatchTypeCheckbox = ({
  pageState,
  initialAudienceTargetingMatchType,
  learnMoreUrl,
}: AudienceTargetingMatchTypeCheckboxProps) => {
  const [bundle] = useBundle('strings');

  const { advertiserId, lineItemId } =
    useContext<TaktLoggingContextType>(TaktLoggingContext);

  const lineItemBeingCreated = lineItemId == undefined || lineItemId === '0';

  const initialValue: LineItemV1.AudienceTargetingMatchType =
    lineItemBeingCreated
      ? SIMILAR_AUDIENCE_TARGETING
      : (initialAudienceTargetingMatchType as LineItemV1.AudienceTargetingMatchType) ??
        EXACT_AUDIENCE_TARGETING;

  const [{ value: audienceTargetingMatchType }, setAudienceTargetingMatchType] =
    useFormField(
      (lineItemModel) =>
        lineItemModel?.targetingDetails?.audienceTargetingMatchType,
      ComponentIds.AUDIENCE_TARGETING,
      pageState as unknown as MFEBootstrap.PageState<LineItemProposalPageState>,
      initialValue
    );

  const onAudienceTargetingMatchTypeCheckboxChange = (changedCheckboxItem) => {
    handleAudienceTargetingMatchTypeCheckboxEvent(
      changedCheckboxItem.isChecked,
      lineItemId,
      advertiserId
    );
    setAudienceTargetingMatchType(
      changedCheckboxItem.isChecked
        ? SIMILAR_AUDIENCE_TARGETING
        : EXACT_AUDIENCE_TARGETING
    );
  };

  const openSimilarAudienceInfoPage = () => {
    window.open(learnMoreUrl, '_blank');
  };

  return (
    <AudienceTargetingMatchTypeWrapper>
      <CheckboxItem
        checkboxItemWithInfo={{
          id: audienceTargetingMatchTypeCheckboxItemId,
          name: translateText(
            bundle,
            TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_AUDIENCE_TARGETING_MATCH_TYPE_CHECKBOX_TEXT
          ),
          isChecked: SIMILAR_AUDIENCE_TARGETING == audienceTargetingMatchType,
        }}
        onSelectionChange={onAudienceTargetingMatchTypeCheckboxChange}
        id={audienceTargetingMatchTypeCheckboxId}
      />
      <SimilarAudienceMoreInfoButtonWrapper>
        <TextButton onClick={openSimilarAudienceInfoPage}>
          {translateText(
            bundle,
            TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_SUMMARY_VIEW_AUDIENCE_TARGETING_MATCH_TYPE_CHECKBOX_ANCHORED_ALERT_LEARN_MORE
          )}
        </TextButton>
      </SimilarAudienceMoreInfoButtonWrapper>
    </AudienceTargetingMatchTypeWrapper>
  );
};

export default AudienceTargetingMatchTypeCheckbox;
