import { TranslatorString } from 'src/Localization/LocalizationConfig';

export interface Tactic {
  id: string;
  description: TranslatorString;
  category: TranslatorString;
}

/**
 * List of valid tactic options that can be attached to a line item. More info can be found in the
 * Performance+ Tactics PRD: https://quip-amazon.com/nbGSAJyxcRbk/cPerformance-Tactics-Workflow-PRD
 */
export const TacticOptions: Tactic[] = [
  {
    id: 'REMARKETING',
    description:
      TranslatorString.ADSP_D16G_AUDIENCE_PICKER_REMARKETING_TACTIC_IDENTIFIER,
    category: TranslatorString.ADSP_D16G_AUDIENCE_PICKER_TACTIC_CATEGORY_TEXT,
  },
  {
    id: 'PROSPECTING',
    description:
      TranslatorString.ADSP_D16G_AUDIENCE_PICKER_PROSPECTING_TACTIC_IDENTIFIER,
    category: TranslatorString.ADSP_D16G_AUDIENCE_PICKER_TACTIC_CATEGORY_TEXT,
  },
  {
    id: 'RETENTION',
    description:
      TranslatorString.ADSP_D16G_AUDIENCE_PICKER_RETENTION_TACTIC_IDENTIFIER,
    category: TranslatorString.ADSP_D16G_AUDIENCE_PICKER_TACTIC_CATEGORY_TEXT,
  },
];
