import en from '../../translations/translation-en.json';
import es from '../../translations/translation-es.json';
import tr from '../../translations/translation-tr.json';
import ar from '../../translations/translation-ar.json';
import de from '../../translations/translation-de.json';
import fr from '../../translations/translation-fr.json';
import hi from '../../translations/translation-hi.json';
import it from '../../translations/translation-it.json';
import ja from '../../translations/translation-ja.json';
import ko from '../../translations/translation-ko.json';
import nl from '../../translations/translation-nl.json';
import pl from '../../translations/translation-pl.json';
import pt from '../../translations/translation-pt.json';
import sv from '../../translations/translation-sv.json';
import ta from '../../translations/translation-ta.json';
import th from '../../translations/translation-th.json';
import vi from '../../translations/translation-vi.json';
import zh from '../../translations/translation-zh.json';

export const languages: any = {
  en,
  es,
  tr,
  ar,
  de,
  fr,
  hi,
  it,
  ja,
  ko,
  nl,
  pl,
  pt,
  sv,
  th,
  ta,
  vi,
  zh,
};

export const AND = 'and';
export const OR = 'or';
export const INCLUDE = 'include';
export const EXCLUDE = 'exclude';
export const INCLUDED = 'included';
export const EXCLUDED = 'excluded';
export const AMAZON_AUDIENCES = 'amazon-audiences';
export const REMOVE = 'remove';
export const THIRD_PARTY_AUDIENCES = 'third-party-audiences';
export const COMBINED_AUDIENCES = 'combined-audiences';
export const AUDIENCE_FEE = 'audience-fee';
export const NO_AUDIENCES_ADDED = 'no-audiences-added';
export const OPERATOR = 'operator';
export const AMAZON = 'amazon';
export const OTHER_SOURCES = 'other-sources';
export const COMBINED = 'combined';
export const GROUP = 'group';
export const HEADER_NAME = 'header-name';
export const HEADER_FEE = 'header-fee';
export const ADDED_AUDIENCES = 'added-audiences';
export const SEARCH_AUDIENCES = 'search-audiences';
export const FILTER = 'filter';
export const HEADER_CATEGORY = 'header-category';
export const MULTIPLE_EXCLUDE_WITH_OR_WARNING =
  'multiple-exclude-with-or-warning';
export const INCLUDE_EXCLUDE_WITH_OR_WARNING =
  'include-exclude-with-or-warning';
export const ADD = 'add';
export const AUDIENCE_CATEGORY = 'audience-category';
export const MAX_SEGMENTS_REACHED = 'max-segments-reached';
export const NO_AUDIENCES_FOUND = 'no-audiences-found';
export const INPUT_CONTAINS_CODE_ERROR_MESSAGE =
  'input-contains-code-error-message';
export const INPUT_TOO_LONG_ERROR_MESSAGE = 'input-too-long-error-message';
export const MULTIPLE_DMP_ERROR = 'multiple-DMP-error';
export const ERROR_LOADING_ROWS = 'error-loading-rows';

export const EXCLUDE_AUDIENCES = 'exclude-audiences';
export const INCLUDE_AUDIENCES = 'include-audiences';
export const NEW_AUDIENCE = 'new-audience';
export const NEW_AUDIENCE_ALERT_MESSAGE = 'new-audience-alert-message';
export const CURRENT_AUDIENCE = 'current-audience';

// Audience Suggestions
export const SUGGESTION_HEADER_AUDIENCE_NAME = 'suggestion-header-name';
export const SUGGESTION_HEADER_CATEGORY = 'suggestion-header-category';
export const SUGGESTION_HEADER_OVERLAP = 'suggestion-header-overlap';
export const SUGGESTION_HEADER_REACH = 'suggestion-header-reach';
export const SUGGESTION_HEADER_AFFINITY = 'suggestion-header-affinity';
export const NO_SUGGESTED_AUDIENCE = 'no-suggested-audience';
export const INCLUDE_AUDIENCE_TO_VIEW_SUGGESTIONS =
  'include-audience-to-view-auggestion';
export const SUGGESTED_AUDIENCE = 'suggested-audience';
export const HIDE_SUGGESTIONS = 'hide-suggestions';
export const SHOW_SUGGESTIONS = 'show-suggestions';
export const BULK_INCLUDE = 'bulk-include';
export const BULK_ADD = 'bulk-add';
export const OVERLAP_TOOLTIP = 'overlap-tooltip';
export const REACH_TOOLTIP = 'reach-tooltip';
export const AFFINITY_TOOLTIP = 'affinity-tooltip';
export const OVERLAP_PERCENTAGE = 'overlap-percentage';
export const BULK_INCLUDE_DISABLED_TOOLTIP = 'bulk-include-disabled-tooltip';
export const BULK_ADD_DISABLED_TOOLTIP = 'bulk-add-disabled-tooltip';
export const ADD_SUGGESTIONS_DISABLED_TOOLTIP =
  'add-suggestions-disabled-tooltip';
export const INCLUDE_SUGGESTIONS_DISABLED_TOOLTIP =
  'include-suggestions-disabled-tooltip';
export const AUDIENCE_SIZE = 'audience-size';
export const AUDIENCE_SUMMARY = 'audience-summary';
export const CUSTOM_BUILT = 'custom-built';
export const IN_MARKET = 'in-market';
export const LIFESTYLE = 'lifestyle';
export const LIFE_EVENT = 'life-event';
export const INTEREST = 'interest';
export const DEMOGRAPHIC = 'demographic';
export const THIRD_PARTY = 'third-party';
export const ADVERTISER_AUDIENCES = 'advertiser-audiences';
export const LOOKALIKE = 'lookalike';
export const INCLUDE_AUDIENCE_ERROR = 'include-audience-error';
export const ADD_AUDIENCE_ERROR = 'add-audience-error';
export const NO_SUGGESTIONS_AVAILABLE = 'no-suggestions-available';
export const INCOMPATIBLE_AUDIENCE_ERROR_MESSAGE =
  'incompatible-audience-error-message';
export const SUGGESTIONS_FAILED_MESSAGE = 'suggestions-failed-message';
export const SUGGESTIONS_FAILED_MESSAGE_BODY =
  'suggestions-failed-message-body';
export const INCLUSIONS_DISABLED_PERFORMANCE_PLUS_TOOLTIP =
  'inclusions-disabled-performance-plus-tooltip';
export const COMBINED_AUDIENCE_SELECTION_DISABLED_MESSAGE =
  'combined-audience-selection-disabled-message';
