import { Segment } from '@amzn/d16g-state-management-interfaces/dist/v1/LineItem';
import {
  AND,
  AudienceTargetingGroup,
  OR,
} from 'src/MFE/lineItem/model/AudienceTargetingGroup';
import {
  AudienceGroup,
  AudienceTargeting,
  Audience,
} from '@amzn/d16g-audience-picker-component';
import { DeepPartial } from '@mfe/state-management-interfaces/dist/Common';
import {
  MutationType,
  SegmentSet,
} from '@amzn/d16g-state-management-interfaces/dist/v1/BulkEdit';
import {
  ImpressionSupplyTypes,
  VideoSubImpressionSupplyTypes,
} from 'src/utils/ImpressionSupplyType';
import { THIRD_PARTY_CATEGORIES } from 'src/utils/MFEConstants';

export interface LegacyAudienceGroup {
  operator: string;
  intrasetOperator: string;
  segments: CustomElementSegment[];
  dealBasedTargetingEnabled: boolean;
}

export interface CustomElementSegment extends Segment {
  subCategory?: string;
  fees?: any;
}

export const EMPTY_LEGACY_AUDIENCE_TARGETING:
  | SegmentSet
  | AudienceTargetingGroup = {
  operator: OR,
  intrasetOperator: AND,
  segments: [],
  dealBasedTargetingEnabled: false,
};

export const EMPTY_AUDIENCE_TARGETING: AudienceTargeting = {
  targetingGroups: [
    {
      intersetOperator: AND,
      include: [],
      exclude: [],
    },
  ],
};

const LOCKED_GROUP_INDEX = 0;

export const ConvertFromLegacySegmentStructure = (
  legacyAudienceGroups: DeepPartial<LegacyAudienceGroup[]>
) => {
  const audienceTargeting = JSON.parse(
    JSON.stringify(EMPTY_AUDIENCE_TARGETING)
  );

  legacyAudienceGroups?.forEach((legacyAudienceGroup) => {
    const newAudienceGroup = {
      intersetOperator: legacyAudienceGroup.intrasetOperator,
      intrasetOperator: legacyAudienceGroup.operator,
      segments: legacyAudienceGroup.segments
        ? legacyAudienceGroup.segments.map((segment) => ({
            ...segment,
            audienceName: segment.label,
            audienceId: segment.canonicalId,
          }))
        : [],
    } as AudienceGroup;
    audienceTargeting.targetingGroups[LOCKED_GROUP_INDEX].include.push(
      newAudienceGroup
    );
  });

  return audienceTargeting;
};

export const ConvertLegacySegmentKeys = (
  legacyAudienceGroups: DeepPartial<LegacyAudienceGroup[]>
) => {
  const audienceTargeting = [];

  legacyAudienceGroups?.forEach((legacyAudienceGroup) => {
    const newAudienceGroup = {
      intersetOperator: legacyAudienceGroup.intrasetOperator,
      intrasetOperator: legacyAudienceGroup.operator,
      segments: legacyAudienceGroup.segments
        ? legacyAudienceGroup.segments.map((segment) => ({
            ...segment,
            audienceName: segment.label,
            audienceId: segment.canonicalId,
          }))
        : [],
    } as AudienceGroup;
    audienceTargeting.push(newAudienceGroup);
  });

  return audienceTargeting;
};

export const ConvertToLegacySegmentStructure = (
  newSegmentStructure: AudienceTargeting,
  impressionSupplyType?: ImpressionSupplyTypes
) => {
  const audienceSegments =
    newSegmentStructure?.targetingGroups[LOCKED_GROUP_INDEX];
  const legacyAudienceSegments = [];

  audienceSegments.include.forEach((audienceGroup) => {
    const legacyAudienceGroup = {
      dealBasedTargetingEnabled: false,
      operator: audienceGroup?.intrasetOperator,
      intrasetOperator: audienceGroup?.intersetOperator,
      segments: audienceGroup.segments.map((segment) => ({
        ...segment,
        not: segment.not ?? false,
        operator: audienceGroup?.intrasetOperator.toLowerCase(),
        label: segment.audienceName,
        canonicalId: segment.audienceId,
        feeMilliCents: getFeeValueInMilli(segment, impressionSupplyType),
      })),
    };
    legacyAudienceSegments.push(legacyAudienceGroup);
  });

  audienceSegments.exclude.forEach((audienceGroup) => {
    const legacyAudienceGroup = {
      dealBasedTargetingEnabled: false,
      operator: audienceGroup?.intrasetOperator,
      intrasetOperator: audienceGroup?.intersetOperator,
      segments: audienceGroup.segments.map((segment) => ({
        ...segment,
        not: true,
        operator: audienceGroup?.intrasetOperator.toLowerCase(),
        label: segment.audienceName,
        canonicalId: segment.audienceId,
        feeMilliCents: getFeeValueInMilli(segment, impressionSupplyType),
      })),
    };
    legacyAudienceSegments.push(legacyAudienceGroup);
  });
  return legacyAudienceSegments;
};

export const ConvertToMutateSegmentList = (
  newSegmentStructure: AudienceTargeting,
  mutationType: MutationType
) => {
  const audienceSegments =
    newSegmentStructure?.targetingGroups[LOCKED_GROUP_INDEX];
  let mutateSegments: any[] = [];
  audienceSegments.include.forEach((audienceGroup) => {
    mutateSegments = audienceGroup.segments.map((segment) => ({
      ...segment,
      not: mutationType === MutationType.EXCLUDE,
      operator: audienceGroup?.intrasetOperator.toLowerCase(),
      label: segment.audienceName,
      canonicalId: segment.audienceId,
    }));
  });

  return mutateSegments;
};

export const UpdateSegmentsByMutationType = (
  mutateSegments: Segment[],
  mutationType: MutationType
) =>
  mutateSegments.map((segment) => ({
    ...segment,
    not: mutationType === MutationType.EXCLUDE,
  }));

export const ConvertFromMutateSegmentList = (
  mutateSegmentStructure: Segment[]
) => {
  const audienceTargeting = JSON.parse(
    JSON.stringify(EMPTY_AUDIENCE_TARGETING)
  );

  audienceTargeting.targetingGroups[LOCKED_GROUP_INDEX].include.push({
    intersetOperator: AND,
    intrasetOperator: OR,
    segments: mutateSegmentStructure,
  });

  return audienceTargeting;
};

export const isFreeNegativeThirdPartySegment = (segment: Audience) =>
  segment.not && THIRD_PARTY_CATEGORIES.includes(segment.category);

export const feeDataMissing = (segment: Audience, feeSupplyType?: string) => {
  const fees = segment.fees;
  return !feeSupplyType || !fees || !fees.length;
};

// In order for the OTT_GUARANTEED budget fee summary view to work correctly, we must place
// each segments' fee value (in millicents) onto the page state.
export const getFeeValueInMilli = (
  segment: Audience,
  feeSupplyType?: ImpressionSupplyTypes
) => {
  if (
    feeDataMissing(segment, feeSupplyType) ||
    isFreeNegativeThirdPartySegment(segment)
  )
    return 0;

  const fees = segment.fees;
  let impressionTypeIndex = fees.findIndex(
    (fee: any) => fee.impressionSupplyType === feeSupplyType
  );
  // fallback to VIDEO fee if video sub type fee does not exist
  if (
    impressionTypeIndex < 0 &&
    VideoSubImpressionSupplyTypes.includes(feeSupplyType)
  )
    impressionTypeIndex = fees.findIndex(
      (fee: any) => fee.impressionSupplyType === ImpressionSupplyTypes.VIDEO
    );

  return impressionTypeIndex > -1 ? fees[impressionTypeIndex].amount : 0;
};
