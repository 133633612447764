import { useEffect } from 'react';
import {
  useDisableEdit,
  useToggleDoneButton,
} from '@amzn/d16g-rodeo-card-common';

export default function useAdvancedTextWidgetEditing(
  isInternalUser: boolean,
  isAdvancedTargetingEnabled: boolean,
  disabledMessage: string
) {
  const { toggleDoneButton } = useToggleDoneButton();
  const { disableEdit } = useDisableEdit();
  useEffect(() => {
    if (!isInternalUser) {
      if (isAdvancedTargetingEnabled) disableEdit();
    } else {
      /* istanbul ignore next */
      const toggleParam = isAdvancedTargetingEnabled
        ? {
            disabledMessage,
            isDisabled: true,
          }
        : false;
      toggleDoneButton(toggleParam);
    }
  }, [isAdvancedTargetingEnabled, isInternalUser]);
}
