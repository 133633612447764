import styled from 'styled-components';
import { defaultTheme } from '@amzn/storm-ui';
import { FooterPanelContainer } from '@amzn/unified-data-table-components/core';

export const FooterPanel = styled(FooterPanelContainer)`
  padding: 15px 21px 10px;
  border: 1px solid ${defaultTheme.palette.gray[100]};
  border-top: 0;
  display: flex;
  justify-content: flex-end;
`;
