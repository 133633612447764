import React, { useState } from 'react';
import { Icon, Text } from '@amzn/storm-ui';
import { flatten } from 'lodash-es';
import { SummaryView } from '@amzn/d16g-rodeo-website-shared-components';

import Audience from './Audience';
import {
  AudienceOperatorEnum,
  SummaryViewIcon,
} from '../../constants/audience';
import { Audience as AudienceType } from '../../models/Audience';

import { Styled } from './AudienceSummary.styled';
import { AudienceGroup } from 'models';

interface AudienceSummaryContainerProps {
  audienceSegments: Array<AudienceGroup>;
  title?: string;
  sectionTitle: string;
  categories: string[];
  id: string;
  icon: SummaryViewIcon;
  maxItemsInCollapsedView?: number;
  expandable: boolean;
  border?: boolean;
}

interface summaryViewType {
  summaryRows: { widget: JSX.Element; id: string; category: string }[];
  summarySectionTitle: string;
  categories: string[];
  id: string;
  icon: SummaryViewIcon;
  maxItemsInCollapsedView?: number;
}

export const AudienceSummary = ({
  audienceSegments,
  title = '',
  sectionTitle,
  categories,
  id,
  icon,
  maxItemsInCollapsedView,
  expandable,
  border = true,
}: AudienceSummaryContainerProps): JSX.Element => {
  const legacyAudiencesGroups = audienceSegments || [];

  const [isExpanded, setIsExpanded] = useState(true);

  const orderSegments = (unorderedSegments: Array<AudienceType>) => {
    const includes = unorderedSegments.filter(x => !x.not);
    const excludes = unorderedSegments.filter(x => x.not);
    return [...includes, ...excludes];
  };

  const getSummaryRows = () =>
    legacyAudiencesGroups.map((legacyAudienceGroup, audienceGroupIndex) => {
      const audiences = legacyAudienceGroup.segments
        ? orderSegments(legacyAudienceGroup.segments).map(
            (audienceSegment, audienceSegmentIndex) => ({
              widget: (
                <Audience
                  intrasetOperator={legacyAudienceGroup.intrasetOperator}
                  audience={audienceSegment}
                  isNotFirstSegment={audienceSegmentIndex !== 0}
                />
              ),
              id: audienceSegment.audienceId,
              category: categories[audienceGroupIndex],
            })
          )
        : [];

      if (audienceGroupIndex < legacyAudiencesGroups.length - 1) {
        const separator = {
          widget: (
            <Styled.LogicalOperator>
              {AudienceOperatorEnum[legacyAudienceGroup.intersetOperator]}
            </Styled.LogicalOperator>
          ),
          category: categories[audienceGroupIndex],
          id: audienceGroupIndex + legacyAudienceGroup.intersetOperator,
        };
        audiences.push(separator);
      }
      return audiences;
    });

  const summaryRows = flatten(getSummaryRows());

  const summaryViewProps: summaryViewType = {
    summaryRows,
    summarySectionTitle: sectionTitle,
    categories,
    id,
    icon,
    maxItemsInCollapsedView,
  };

  const Container = border
    ? Styled.AudienceSummaryContainer
    : Styled.AudienceSummaryContainerNoBorder;

  return (
    <Container>
      <Styled.AudienceSummaryTitle>
        <Text type={'h5'}>
          <Text> {title} </Text>
        </Text>
        {expandable && (
          <Icon
            type={isExpanded ? 'angle-up' : 'angle-down'}
            size="md"
            onClick={() => setIsExpanded(!isExpanded)}
          />
        )}
      </Styled.AudienceSummaryTitle>
      {isExpanded && <SummaryView {...summaryViewProps} />}
    </Container>
  );
};
