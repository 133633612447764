import React, { useContext, useState } from 'react';
import { Divider, Icon, Text } from '@amzn/storm-ui';
// @ts-ignore
import { TargetingGroupComponentStyled } from '../../components/AudienceTargeting/TargetingGroup/TargetingGroupComponent.styled';
import { INCLUDE } from '../../constants';
import { IAudienceTargetingContext } from '../../state/audienceTargetingContext';
import { useAudienceTargeting } from '../../state/useAudienceTargeting';
import { TranslationContext } from '../../state/translationContext';
import { Audience, AudienceGroup, AudienceGroupIndex } from '../../models';
import AudienceGroupComponent from '../AudienceTargeting/AudienceGroup/AudienceGroupComponent';
import { IncludeExcludeGroupComponentStyled } from '../AudienceTargeting/IncludeExcludeGroup/IncludeExcludeGroupComponent.styled';
import {
  TaktLoggingContext,
  TaktLoggingContextType,
} from '../../state/taktLoggingContext';

type TargetingGroupComponentProps = {
  /**
   * List of feeSupplyType(s) that dictate which fee to use from a given audience segment
   */
  feeSupplyType?: string[];
  /**
   * Whether to show the "legacy" logic version of the picker, or the "include/exclude" logic version of the picker.
   */
  useLegacy?: boolean;
  /**
   * Whether we are excluding, including, or removing the audiences that will be shown in this component.
   */
  bulkEditMutationType: string;
};
const targetingGroupIndex = {
  targetingGroupIndex: 0,
  targetingGroupType: INCLUDE,
};

/**
 * If a bulkEditMutationType is passed in, the BulkEditTargetingGroupComponent will be used in place of the default TargetingGroupComponent, and show one group for which selected audiences will be subject to the bulkEditMutationType.
 */
const BulkEditTargetingGroupComponent = ({
  feeSupplyType,
  useLegacy,
  bulkEditMutationType,
}: TargetingGroupComponentProps) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const {
    state,
    removeAudienceFromGroup,
    removeAudienceGroup,
    selectAudienceGroup,
  }: IAudienceTargetingContext = useAudienceTargeting();

  const getTranslation = useContext(TranslationContext);

  const { removeFromSuggestedSegments } = useContext<TaktLoggingContextType>(
    TaktLoggingContext
  );

  if (
    !state?.audienceTargeting?.targetingGroups[
      targetingGroupIndex.targetingGroupIndex
    ]
  )
    return null;

  const audienceGroup: AudienceGroup =
    state?.audienceTargeting?.targetingGroups[
      targetingGroupIndex.targetingGroupIndex
    ][INCLUDE][0];

  const audienceGroupIndex: AudienceGroupIndex = {
    targetingGroupType: INCLUDE,
    targetingGroupIndex: targetingGroupIndex.targetingGroupIndex,
    audienceGroupIndex: 0,
  };

  const removeAudienceFromGroupCallback = (segment: Audience) => {
    removeAudienceFromGroup({
      ...audienceGroupIndex,
      segment,
    });
  };

  const removeAudienceGroupCallback = (event: any) => {
    event.stopPropagation();
    const audienceGroupSegments =
      state.audienceTargeting.targetingGroups[
        audienceGroupIndex.targetingGroupIndex
      ].include[audienceGroupIndex.audienceGroupIndex].segments;
    removeAudienceGroup(audienceGroupIndex);
    audienceGroupSegments.forEach(row => {
      removeFromSuggestedSegments(row.audienceId);
    });
  };

  return (
    <TargetingGroupComponentStyled.Container
      data-testid={'targeting-groups-component'}
    >
      <TargetingGroupComponentStyled.GroupsContainer>
        <IncludeExcludeGroupComponentStyled.Container>
          <IncludeExcludeGroupComponentStyled.TitleRow>
            <Text type={'h5'}>{getTranslation(bulkEditMutationType)}</Text>
            <Icon
              style={{ cursor: 'pointer' }}
              onClick={() => setIsExpanded(!isExpanded)}
              type={isExpanded ? 'angle-up' : 'angle-down'}
            />
          </IncludeExcludeGroupComponentStyled.TitleRow>
          {isExpanded && (
            <>
              <div data-testid={'audience-group'}>
                <AudienceGroupComponent
                  useLegacy={useLegacy}
                  audienceGroup={audienceGroup}
                  isSelected={true}
                  removeSegmentFromGroup={removeAudienceFromGroupCallback}
                  removeGroup={removeAudienceGroupCallback}
                  audienceGroupCount={1}
                  audienceGroupIndex={audienceGroupIndex}
                  selectAudienceGroup={selectAudienceGroup}
                  feeSupplyType={feeSupplyType}
                />
              </div>
            </>
          )}
        </IncludeExcludeGroupComponentStyled.Container>
        {/* @ts-ignore */}
        <Divider style={{ 'margin-bottom': '0px' }} />
      </TargetingGroupComponentStyled.GroupsContainer>
    </TargetingGroupComponentStyled.Container>
  );
};

export default BulkEditTargetingGroupComponent;
