import { Audience, FeeTypes } from '../models';
import { THIRD_PARTY_CATEGORIES } from '../constants/3p-categories';
import { COMBINED_AUDIENCES_CATEGORY } from '../constants/combined-audiences';

const ZERO = 0;
const videoSubFeeTypes = [FeeTypes.STV, FeeTypes.PVA, FeeTypes.OTT_VIDEO];

const addArrays = (array1: number[], array2: number[]) => {
  const shorterArray =
    array1.length > array2.length ? [...array2] : [...array1];
  const longerArray = array1.length > array2.length ? [...array1] : [...array2];
  // Edge case where the arrays different sizes, we fill in the shorter array with 0s at the front
  while (shorterArray.length < longerArray.length) shorterArray.unshift(0);
  return shorterArray.map((num, idx) => num + longerArray[idx]);
};

/**
 * @function
 * Given the feeSupplyType(s), returns the currency, and human-readable amount for a segment
 * @param {Audience} segment - the segment to look at the fees array from
 * @param {string[]} feeSupplyType - the feeSupplyType(s) to choose from the segment fees.
 */
export const getFeeValue = (segment: Audience, feeSupplyType?: string[]) => {
  const fees = segment.fees;
  if (!feeSupplyType?.length || !fees || !fees.length)
    return { cpm: [ZERO], currency: '' };

  // as long as there is at least one fee type, we can use its currency
  const currency = fees ? fees[ZERO].currency : '';
  const cpm: number[] = [];

  // case for free excluded third-party segments
  if (segment.not && THIRD_PARTY_CATEGORIES.includes(segment.category))
    return { cpm: [ZERO], currency };

  feeSupplyType.forEach(supplyType => {
    const impressionTypeIndex = fees.findIndex(
      (fee: any) => fee.impressionSupplyType === supplyType
    );

    if (impressionTypeIndex > -1)
      cpm.push(
        fees[impressionTypeIndex].amount / fees[impressionTypeIndex].scale
      );
    else if (videoSubFeeTypes.includes(supplyType as FeeTypes)) {
      const videoIndex = fees.findIndex(
        (fee: any) => fee.impressionSupplyType === FeeTypes.VIDEO
      );

      if (videoIndex > -1)
        cpm.push(fees[videoIndex].amount / fees[videoIndex].scale);
    }
  });

  const sortedMaxMin =
    cpm.length >= 2 ? [Math.min(...cpm), Math.max(...cpm)] : cpm;
  return { cpm: sortedMaxMin, currency };
};

/**
 * @function
 * Calculate the fee total for a selection of audiences
 * @param {Audience} segments - the audiences selected
 * @param {string[]} feeSupplyType - the feeSupplyType(s) to choose from the segment fees.
 */
export const calculateFee = (
  segments: Audience[] = [],
  feeSupplyType?: string[]
) => {
  const updateMax = (max: number[], cpm: number[]) => {
    const newMax: number[] = new Array(cpm.length).fill(0);
    cpm.forEach((fee, index) => {
      newMax[index] = Math.max(max[index] || 0, fee);
    });

    return newMax;
  };

  let amazonMax: number[] = [];
  let thirdPartyMax: number[] = [];
  let currencyType: string = '';

  segments.forEach(segment => {
    const { cpm, currency } = getFeeValue(segment, feeSupplyType);
    if (currency) currencyType = currency;
    if (THIRD_PARTY_CATEGORIES.includes(segment.category))
      thirdPartyMax = updateMax(thirdPartyMax, cpm);
    else if (segment.category !== COMBINED_AUDIENCES_CATEGORY)
      amazonMax = updateMax(amazonMax, cpm);
  });

  const feeTotal = addArrays(amazonMax, thirdPartyMax);

  return {
    amazonTotal: amazonMax,
    thirdPartyTotal: thirdPartyMax,
    feeTotal,
    currencyType,
  };
};
