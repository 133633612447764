import { INCLUSIONS_DISABLED_PERFORMANCE_PLUS_TOOLTIP } from './translations';

/**
 * This is a map that maps from the inclusionDisabledReasonCode provided by MFEService to the
 * panther translated enum that contains the UX approved string message that appears in the tooltip.
 */
export const InclusionDisabledReasonCodeMap: Map<string, string> = new Map<
  string,
  string
>([['PERFORMANCE_PLUS', INCLUSIONS_DISABLED_PERFORMANCE_PLUS_TOOLTIP]]);
