import styled from 'styled-components';

const Container = styled.div`
  margin: 0px 20px;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  padding: 10px 2px;
`;

export const IncludeExcludeGroupComponentStyled = {
  Container,
  TitleRow,
};
