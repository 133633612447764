import React, { useContext } from 'react';
import { AudienceSummary } from '../../AudienceSummary/AudienceSummary';
import { CURRENT_AUDIENCE } from '../../../constants/translations';
import { SummaryViewIcon } from '@amzn/d16g-rodeo-website-shared-components';
import { IAudienceTargetingContext } from '../../../state/audienceTargetingContext';
import { useAudienceTargeting } from '../../../state/useAudienceTargeting';
import { TranslationContext } from '../../../state/translationContext';
import { SummaryViewSectionTitle } from '../../../constants/audience';

interface CurrentAudienceProps {
  expandable?: boolean;
  border?: boolean;
}

export const CurrentAudience = ({
  expandable = true,
  border = true,
}: CurrentAudienceProps) => {
  const getTranslation = useContext(TranslationContext);
  const TRANS_CURRENT_AUDIENCE = getTranslation(CURRENT_AUDIENCE);

  const { state }: IAudienceTargetingContext = useAudienceTargeting();

  const legacyAudiencesGroups = state?.incompatibleAudience
    ? [
        ...state?.incompatibleAudience?.targetingGroups?.[0]?.include,
        ...state?.incompatibleAudience?.targetingGroups?.[0]?.exclude,
      ]
    : [];

  const getCategoryName = (num: number) => `Group ${num + 1}`;

  const getCategories = () =>
    legacyAudiencesGroups.map((_legacyAudienceGroup, i: number) =>
      getCategoryName(i)
    );
  return (
    <AudienceSummary
      audienceSegments={legacyAudiencesGroups}
      title={TRANS_CURRENT_AUDIENCE}
      sectionTitle={SummaryViewSectionTitle.SELECTED}
      categories={getCategories()}
      id="incompatible_current_audience"
      icon={SummaryViewIcon.ACCEPTED}
      expandable={expandable}
      border={border}
    />
  );
};
