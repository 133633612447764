import { round } from 'lodash-es';
import {
  AudienceGroupIndex,
  TargetingGroup,
} from '../models/AudienceTargeting';
import { AND, EXCLUDE, OR } from '../constants/expressions';

export const simplifyDisplayValue = (originalValue: number) => {
  if (originalValue >= 1000000000)
    return `${round(originalValue / 1000000000)}B`;
  if (originalValue >= 1000000) return `${round(originalValue / 1000000)}M`;
  if (originalValue >= 1000) return `${round(originalValue / 1000)}K`;
  return originalValue;
};

// This function to detect incompatible audience was implemented refering getAudienceSegmentCharacteristics of TargetingSimplificationHelpers package
// https://code.amazon.com/packages/TargetingSimplificationHelpers/blobs/40a25da6977d34cfb21ecf0e22c154c6a8fee4d6/--/src/com/amazon/targetingsimplification/audiences/AudiencesSegmentCharacteristics.java#L27
// These should remain in sync
export const isAudienceIncompatible = (audiences: TargetingGroup): boolean => {
  let incompatible = false;
  let hasExcludeSegmentSet = false;
  audiences.include.forEach((segmentSet, index) => {
    const intersetOperator = segmentSet.intersetOperator;
    const intrasetOperator = segmentSet.intrasetOperator;
    const isExcludeGroup = segmentSet.segments.every(segment => segment.not);
    /**
     * Verify operators
     * 1. operator between groups should be AND
     * 2. for include group operator between segments should be OR
     * 3. for exclude group operator between segments should be AND
     * The operator checks will not be performed for traling groups and for groups where we have just one segment
     */
    if (
      (intersetOperator === OR && index + 1 !== audiences.include.length) ||
      (intrasetOperator === AND &&
        !isExcludeGroup &&
        segmentSet.segments.length > 1) ||
      (intrasetOperator === OR &&
        isExcludeGroup &&
        segmentSet.segments.length > 1)
    ) {
      incompatible = true;
      return;
    }

    // Check if some segments are excluded
    if (segmentSet.segments.some(segment => segment.not)) {
      // Check if there is already a group with excluded segments
      if (hasExcludeSegmentSet) {
        incompatible = true;
        return;
      }
      hasExcludeSegmentSet = true;
      // Check if all segments are excluded if not then the expression is incompatible
      if (!isExcludeGroup) incompatible = true;
    }
  });
  if (!incompatible) incompatible = audiences.exclude.length > 1;
  return incompatible;
};

export const isSelectedGroupExclude = (
  audiences: TargetingGroup,
  selectedGroup: AudienceGroupIndex
) => {
  let isExclude = false;
  if (selectedGroup?.targetingGroupType === EXCLUDE) return true;

  audiences?.include.forEach((segmentSet, index) => {
    if (
      segmentSet.segments.length &&
      selectedGroup.audienceGroupIndex === index &&
      segmentSet.segments.every(segment => segment.not)
    )
      isExclude = true;
  });
  return isExclude;
};
