import { Fee } from "src/model";
import { EMPTY_FEE } from "src/constants";

export interface ContextualFeeProps {
    contextualProductFee: Fee | null | undefined,
    contextualCategoryFee: Fee | null | undefined,
    contextualRelatedProductFee: Fee | null | undefined, // related products
    contextualEntertainmentFee: Fee | null | undefined,
    contextualKeywordFee: Fee | null | undefined,
}

export interface ContextualFeeState extends ContextualFeeProps {
    setContextualProductFee: (fee: Fee) => void,
    setContextualCategoryFee: (fee: Fee) => void,
    setContextualRelatedProductFee: (fee: Fee) => void,
    setContextualEntertainmentFee: (fee: Fee) => void,
    setContextualKeywordFee: (fee: Fee) => void,
    invalidateContextualFees: () => void,
    setContextualFeesLoadingState: (isLoading: boolean) => void
}

export const createContextualFeeSlice = (set): ContextualFeeState => ({
    contextualProductFee: null,
    contextualCategoryFee: null,
    contextualRelatedProductFee: null,
    contextualEntertainmentFee: null,
    contextualKeywordFee: null,
    setContextualProductFee: (fee: Fee) => set({contextualProductFee: fee}),
    setContextualCategoryFee: (fee: Fee) => set({contextualCategoryFee: fee}),
    setContextualRelatedProductFee: (fee: Fee) => set({contextualRelatedProductFee: fee}),
    setContextualEntertainmentFee: (fee: Fee) => set({contextualEntertainmentFee: fee}),
    setContextualKeywordFee: (fee: Fee) => set({contextualKeywordFee: fee}),
    invalidateContextualFees: () => set({
        contextualProductFee: EMPTY_FEE,
        contextualCategoryFee: EMPTY_FEE,
        contextualRelatedProductFee: EMPTY_FEE,
        contextualEntertainmentFee: EMPTY_FEE,
        contextualKeywordFee: EMPTY_FEE,
    }),
    setContextualFeesLoadingState: (isLoading: boolean) =>
        set((state) => ({
            contextualProductFee: {...state.contextualProductFee, isLoading},
            contextualCategoryFee: {...state.contextualCategoryFee, isLoading},
            contextualRelatedProductFee: {...state.contextualRelatedProductFee, isLoading},
            contextualEntertainmentFee: {...state.contextualEntertainmentFee, isLoading},
            contextualKeywordFee: {...state.contextualKeywordFee, isLoading},
        })),
});