import styled from 'styled-components';

const Container = styled.div`
  overflow-x: auto;
`;

const GroupsContainer = styled.div`
  background-color: white;
`;

export const TargetingGroupComponentStyled = {
  Container,
  GroupsContainer,
};
