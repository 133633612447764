import { Audience } from '../models';
import {
  ADD_AUDIENCE_GROUP,
  ADD_AUDIENCE_TO_GROUP,
  REMOVE_AUDIENCE_FROM_GROUP,
  REMOVE_AUDIENCE_GROUP,
  SELECT_AUDIENCE_GROUP,
  CHANGE_INTRASET,
  CHANGE_INTERSET,
  BULK_ADD_AUDIENCE_TO_GROUP,
} from '../state/audienceTargetingReducer';
import {
  AudienceGroupIndex,
  SegmentGroupIndex,
  TargetingGroupIndex,
} from '../models/AudienceTargeting';

/**
 * list of actions that can be performed on the audience picker component.
 */
export type AudienceGroupAction =
  | { type: 'ADD_AUDIENCE_TO_GROUP'; audience: Audience }
  | { type: 'BULK_ADD_AUDIENCE_TO_GROUP'; audienceList: Array<Audience> }
  | { type: 'REMOVE_AUDIENCE_FROM_GROUP'; segmentToRemove: SegmentGroupIndex }
  | { type: 'ADD_AUDIENCE_GROUP'; indexToAddGroup: TargetingGroupIndex }
  | { type: 'REMOVE_AUDIENCE_GROUP'; indexToRemoveGroup: AudienceGroupIndex }
  | { type: 'SELECT_AUDIENCE_GROUP'; indexToSelectGroup: AudienceGroupIndex }
  | {
      type: 'CHANGE_INTERSET';
      term: string;
      audienceToUpdate: AudienceGroupIndex;
    }
  | {
      type: 'CHANGE_INTRASET';
      term: string;
      audienceToUpdate: AudienceGroupIndex;
    };

/**
 * Action structure to be passed into an immutibility helper to add an audience to a selected group.
 */
export const addAudienceToGroupAction = (
  audience: Audience
): AudienceGroupAction => ({ type: ADD_AUDIENCE_TO_GROUP, audience });

/**
 * Action structure to be passed into an immutibility helper to add multiple audience segment to a selected group.
 */
export const bulkAddAudienceToGroupAction = (
  audienceList: Array<Audience>
): AudienceGroupAction => ({
  type: BULK_ADD_AUDIENCE_TO_GROUP,
  audienceList,
});

/**
 * Action structure to be passed into an immutibility helper to remove an audience to a selected group.
 */
export const removeAudienceFromGroupAction = (
  segmentToRemove: SegmentGroupIndex
): AudienceGroupAction => ({
  type: REMOVE_AUDIENCE_FROM_GROUP,
  segmentToRemove,
});

/**
 * Action structure to be passed into an immutibility helper to add a new audience group
 */
export const addAudienceGroupAction = (
  indexToAddGroup: TargetingGroupIndex
): AudienceGroupAction => ({
  type: ADD_AUDIENCE_GROUP,
  indexToAddGroup,
});

/**
 * Action structure to be passed into an immutibility helper to remove a specified audience group by its index
 */
export const removeAudienceGroupAction = (
  indexToRemoveGroup: AudienceGroupIndex
): AudienceGroupAction => ({
  type: REMOVE_AUDIENCE_GROUP,
  indexToRemoveGroup,
});

/**
 * Action structure to be passed into an immutibility helper to mark a specified group as "selected" by its index
 */
export const selectAudienceGroupAction = (
  indexToSelectGroup: AudienceGroupIndex
): AudienceGroupAction => ({
  type: SELECT_AUDIENCE_GROUP,
  indexToSelectGroup,
});

/**
 * Action structure to be passed into an immutibility helper to change the interset operator of a group by its index
 */
export const changeIntersetAction = (
  term: string,
  audienceToUpdate: AudienceGroupIndex
): AudienceGroupAction => ({
  type: CHANGE_INTERSET,
  term,
  audienceToUpdate,
});

/**
 * Action structure to be passed into an immutibility helper to change the intraset operator of a group by its index
 */
export const changeIntrasetAction = (
  term: string,
  audienceToUpdate: AudienceGroupIndex
): AudienceGroupAction => ({
  type: CHANGE_INTRASET,
  term,
  audienceToUpdate,
});
