import { AudiencePickerState } from '../state/audienceTargetingReducer';
import { Audience, AudienceGroup } from '../models';

/**
 * @function
 * Converts the state of the audience targeting selections into a list of audienceGroups for easier data manipulation.
 * @param {AudiencePickerState} state - the current data saved in the audience picker
 * @returns {AudienceGroup[]} The same state data represented as a list of audience groups.
 */
export const convertStateToAudienceGroupList = (
  state: AudiencePickerState
): AudienceGroup[] => {
  const include = state?.audienceTargeting?.targetingGroups[0]?.include ?? [];
  const exclude = state?.audienceTargeting?.targetingGroups[0]?.exclude ?? [];
  return [...include, ...exclude];
};

/**
 * @function
 * Converts the state of the audience targeting selections into a lits of audienceGroups for easier data manipulation.
 * @param {AudiencePickerState} state - the current data saved in the audience picker
 * @returns {Audience[]} The same state data represented as a list of audiences/segments
 */
export const convertStateToSegmentList = (
  state: AudiencePickerState
): Audience[] => {
  const groups = convertStateToAudienceGroupList(state);
  const segments: Audience[] = [];
  groups.forEach(audienceGroup => {
    audienceGroup.segments.forEach(segment => {
      segments.push(segment);
    });
  });

  return segments;
};

/**
 * @function
 * Converts only the included audiences of the audience targeting selections into a list of audiences for easier data manipulation.
 * @param {AudiencePickerState} state - the current data saved in the audience picker
 * @returns {Audience[]} The same state data represented as a list of audiences/segments
 */
export const convertIncludeStateToSegmentList = (
  state: AudiencePickerState
): Audience[] => {
  const segments: Audience[] = [];
  const groups = state?.audienceTargeting?.targetingGroups[0]?.include ?? [];
  groups.forEach(audienceGroup => {
    audienceGroup.segments.forEach(segment => {
      if (!segment.not) segments.push(segment);
    });
  });

  return segments;
};

export const convertAudienceObjectToInclude = (audience: any) => {
  // The targeted audience segment saved in the line item does not have some properties,
  // this object consists of required fields needed for displaying fields in the UI.
  // Comparing this with audiences (shown in the table) that are fetched from public API
  // it's visible that some fields like description, forecasts, createDate, updateDate and status are not present in this object.
  // So we are deleting these fields
  // And there are some additional fields like providerId, subCategory which are not present in public API.
  // Due to these fields the previously saved audience object and new audience object fields do not match even for same audience
  // We are adding these fields and defaulting them to null.
  const propertiesToDelete = [
    'description',
    'forecasts',
    'createDate',
    'updateDate',
    'status',
  ];
  propertiesToDelete.forEach((property: string) => delete audience?.[property]);

  audience.providerId = audience.providerId || null;
  audience.subCategory = audience.subCategory || null;
};
