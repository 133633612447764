export const CUSTOM_ATTRIBUTES = {
  advertiserId: 'advertiserId',
  clientId: 'clientId',
  csrfToken: 'csrfToken',
  entityId: 'entityId',
  audienceDiscoveryEndpoint: 'audienceDiscoveryEndpoint',
  audienceDiscoveryUrl: 'audienceDiscoveryUrl',
  audienceTaxonomyEndpoint: 'audienceTaxonomyEndpoint',
  segmentTargeting: 'segmentTargeting',
  marketplaceId: 'marketplaceId',
  locale: 'locale',
  isInternalUser: 'isInternalUser',
  isEntityAllowListed: 'isEntityAllowListed',
  isMigrationCompatible: 'isMigrationCompatible',
  currencyOfPreference: 'currencyOfPreference',
  fxConversionRate: 'fxConversionRate',
  isEntitySuggestionsAllowListed: 'isEntityAudienceSuggestionsAllowlisted',
  personaBuilderUrl: 'personaBuilderUrl',
  personaBuilderTopOverlappingEndpoint: 'personaBuilderTopOverlappingEndpoint',
  lineItemId: 'lineItemId',
  campaignId: 'campaignId',
  isInclusionDisabled: 'isInclusionDisabled',
  inclusionDisabledTooltip: 'inclusionDisabledTooltip',
  country: 'country',
  funnelStage: 'funnelStage',
  audienceTargetingMatchType: 'audienceTargetingMatchType',
  automatedTactic: 'automatedTactic',
  isAudienceTargetingMatchTypeAllowListed:
    'isAudienceTargetingMatchTypeAllowListed',
};
