import { useContext } from "react";
import { FeeContext } from "src/context/FeeContext";
import { MISSING_FEE_STORE_CONTEXT } from "src/constants";
import { useStore } from "zustand";
import { formatPercentDisplayFee } from "src/utils/FeeUtil";

export default function useTechnologyFee() {
    const feeStore = useContext(FeeContext);

    if (!feeStore) {
        throw new Error(MISSING_FEE_STORE_CONTEXT);
    }

    const observedTechnologyFee =
        useStore(feeStore, (state) => state.technologyFee) || undefined;

    return {
        observedTechnologyFee: formatPercentDisplayFee(observedTechnologyFee)
    }

}