import React, { createContext, useState } from 'react';

const blankFunction = () => undefined;
export const TaktLoggingContext = createContext<TaktLoggingContextType>({
  suggestedSegments: [],
  setSuggestedSegments: blankFunction,
  addToSuggestedSegments: blankFunction,
  removeFromSuggestedSegments: blankFunction,
});

export type SegmentInsights = {
  canonicalId: string;
  overlapPercentage: number;
  affinity: number;
  forecastDailyReach: { lowerBound: number; upperBound: number };
};

export type TaktLoggingContextType = {
  entityId?: string;
  lineItemId?: string;
  advertiserId?: string;
  campaignId?: string;
  suggestedSegments: SegmentInsights[];
  setSuggestedSegments: React.Dispatch<React.SetStateAction<SegmentInsights[]>>;
  addToSuggestedSegments: (segment: SegmentInsights) => void;
  removeFromSuggestedSegments: (canonicalId: string) => void;
};

export const TaktLoggingContextProvider = ({
  children,
  initialState,
}: {
  children: React.ReactNode;
  initialState: {
    entityId: string | undefined;
    lineItemId: string | undefined;
    advertiserId: string | undefined;
    campaignId: string | undefined;
    suggestedSegments: SegmentInsights[];
  };
}) => {
  const [suggestedSegments, setSuggestedSegments] = useState<SegmentInsights[]>(
    initialState?.suggestedSegments || []
  );

  const addToSuggestedSegments = (segment: SegmentInsights) => {
    setSuggestedSegments(prevSegments => {
      // Check if a segment with the same canonicalId already exists
      const segmentExists = prevSegments.some(
        s => s.canonicalId === segment.canonicalId
      );

      // If the segment does not exist, add it to the array
      if (!segmentExists) return [...prevSegments, segment];

      // If the segment already exists, return the previous segments without modification
      return prevSegments;
    });
  };

  const removeFromSuggestedSegments = (canonicalId: string) => {
    setSuggestedSegments(
      prevSegments => prevSegments.filter(s => s.canonicalId !== canonicalId) // Filter based on canonicalId
    );
  };

  const contextValue: TaktLoggingContextType = {
    ...initialState,
    suggestedSegments,
    setSuggestedSegments,
    addToSuggestedSegments,
    removeFromSuggestedSegments,
  };

  return (
    <TaktLoggingContext.Provider value={contextValue}>
      {children}
    </TaktLoggingContext.Provider>
  );
};
