import styled from 'styled-components';
import { HeaderPanelContainer } from '@amzn/unified-data-table-components/core';
import { defaultTheme } from '@amzn/storm-ui';
import { MARGIN_BETWEEN_TABLES } from '../../constants/suggestion';

export const HeaderContainer = styled.div`
  padding: 15px 20px;
  border: 1px solid ${defaultTheme.palette.gray[100]};
  background: white;
  margin-top: ${MARGIN_BETWEEN_TABLES}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TableHeaderContainer = styled(HeaderPanelContainer)`
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const HeaderPanel = styled(HeaderPanelContainer)`
  border-top: 0.25px solid #e7e7e7;
  flex: 1;
  width: 100%;
  padding: 10px 0;
`;

export const FilterContainer = styled.div`
  padding: 0 10px;
`;

export const DisabledBtn = styled.div`
  color: ${defaultTheme.palette.gray[500]};
  cursor: not-allowed;
`;
