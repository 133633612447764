import React from 'react';
import { Divider } from '@amzn/storm-ui';
// @ts-ignore
import { TargetingGroupComponentStyled } from '../../../components/AudienceTargeting/TargetingGroup/TargetingGroupComponent.styled';
import IncludeExcludeGroupComponent from '../../../components/AudienceTargeting/IncludeExcludeGroup/IncludeExcludeGroupComponent';
import { EXCLUDE, INCLUDE } from '../../../constants';

type TargetingGroupComponentProps = {
  /**
   * List of feeSupplyType(s) that dictate which fee to use from a given audience segment
   */
  feeSupplyType?: string[];
  /**
   * Whether to show the "legacy" logic version of the picker, or the "include/exclude" logic version of the picker.
   */
  useLegacy?: boolean;
  /**
   * The maximum number of segments that can be added to a group before an error is shown and no more audiences can be added to that group.
   */
  maxSegmentsPerGroup?: number;
  /**
   * The maximum number of groups that can be added before the "add" button disappears.
   */
  maxIncludeGroups?: number;
  /**
   * If `useLegacy` is set to false and `hideExcludeGroup` is set to true, the legacy logic will remain but the exclude group will not show up.
   */
  hideExcludeGroup?: boolean;
};

/**
 * Container for the "Include" and "Exclude" groups that contain the audience groups - if useLegacy is active, only the "Include" group is shown.
 */
const TargetingGroupComponent = ({
  feeSupplyType,
  useLegacy,
  maxSegmentsPerGroup,
  maxIncludeGroups,
  hideExcludeGroup,
}: TargetingGroupComponentProps) => (
  <TargetingGroupComponentStyled.Container
    data-testid={'targeting-groups-component'}
  >
    <TargetingGroupComponentStyled.GroupsContainer>
      <IncludeExcludeGroupComponent
        useLegacy={useLegacy}
        feeSupplyType={feeSupplyType}
        maxIncludeGroups={maxIncludeGroups}
        maxSegmentsPerGroup={maxSegmentsPerGroup}
        targetingGroupIndex={{
          targetingGroupIndex: 0,
          targetingGroupType: INCLUDE,
        }}
      />
      {!useLegacy && !hideExcludeGroup && (
        <>
          <Divider />
          <IncludeExcludeGroupComponent
            useLegacy={false}
            feeSupplyType={feeSupplyType}
            maxIncludeGroups={maxIncludeGroups}
            maxSegmentsPerGroup={maxSegmentsPerGroup}
            targetingGroupIndex={{
              targetingGroupIndex: 0,
              targetingGroupType: EXCLUDE,
            }}
          />
        </>
      )}

      {/* @ts-ignore */}
      <Divider style={{ 'margin-bottom': '0px' }} />
    </TargetingGroupComponentStyled.GroupsContainer>
  </TargetingGroupComponentStyled.Container>
);

export default TargetingGroupComponent;
