import React, { useContext } from 'react';
import styled from 'styled-components';

import {
  BasePagination,
  PaginationDropdown,
  PaginationPageStatus,
  PaginationNavGroup,
  PaginationPrevButton,
  PaginationNextButton,
  useUID,
} from '@amzn/storm-ui';
import {
  UnifiedDataTablePaginationContext,
  UnifiedDataTableApiContext,
} from '@amzn/unified-data-table-components/core';
import { getDefaultLabels } from '@amzn/unified-data-table-components/core/components/Pagination/Pagination.intl';
import { PaginationProps } from '@amzn/unified-data-table-components/core/components/Pagination/Pagination.interfaces';
const PaginationPadding = styled('div')`
  display: flex;
`;

interface PaginationCompProps extends PaginationProps {
  /**
   * whether audiences have been requested and we are waiting for them to show up in the table.
   */
  currentlyLoading: boolean;
}

/**
 * Component to show the table pagination and handle the UDC table grid API for it.
 */
export const Pagination: React.FC<PaginationCompProps> = (
  props: PaginationCompProps
) => {
  const apiContext = useContext(UnifiedDataTableApiContext);
  const paginationContext = useContext(UnifiedDataTablePaginationContext);
  const dropdownUid = useUID('pagination-dropdown');
  const prevUid = useUID('pagination-prev');
  const nextUid = useUID('pagination-next');

  const { api, udcApi } = apiContext;
  const { pageSize, currentPage, totalPages, rowCount } = paginationContext;

  if (!udcApi) return null;

  const {
    pageSizes = [50, 100, 200],
    labels = getDefaultLabels(udcApi),
  } = props;

  if (
    !api ||
    currentPage === undefined ||
    pageSize === undefined ||
    rowCount === undefined ||
    totalPages === undefined ||
    !labels.renderCurrentPageLabel ||
    !labels.prevPageLabel ||
    !labels.nextPageLabel ||
    !labels.renderPaginationDropdownLabel
  )
    return null;

  const rowFrom = currentPage * pageSize + 1;
  const rowTo = Math.min((currentPage + 1) * pageSize, rowCount);

  const goToPage = (pageNumber: number): void => {
    api.paginationGoToPage(pageNumber);
  };

  const onSetPageSize = (newPageSize: number): void => {
    api.paginationSetPageSize(newPageSize);
    goToPage(0);
  };

  if (rowCount <= 0) return null;

  return (
    <BasePagination>
      <PaginationDropdown
        id={dropdownUid}
        currentSize={pageSize}
        pageSizes={pageSizes}
        onSetPageSize={onSetPageSize}
        renderPaginationDropdownLabel={labels.renderPaginationDropdownLabel}
      />
      <PaginationPadding>
        <PaginationPageStatus
          currentPageLabel={labels.renderCurrentPageLabel(
            pageSize,
            currentPage,
            totalPages,
            rowFrom,
            rowTo,
            rowCount
          )}
        />
      </PaginationPadding>
      <PaginationNavGroup>
        <PaginationPrevButton
          id={prevUid}
          ariaLabel={labels.prevPageLabel}
          onClick={() => goToPage(currentPage - 1)}
          disabled={currentPage === 0 || totalPages === 0}
        />
        <PaginationNextButton
          id={nextUid}
          ariaLabel={labels.nextPageLabel}
          onClick={() => goToPage(currentPage + 1)}
          disabled={
            currentPage === totalPages - 1 ||
            totalPages === 0 ||
            props.currentlyLoading
          }
        />
      </PaginationNavGroup>
    </BasePagination>
  );
};
