import { Fee, ThirdPartyAudienceDisplayFee } from "src/model";
import { THIRD_PARTY_CATEGORIES } from "@amzn/d16g-pricing-shared-client-library";
import { videoSubFeeTypes, FeeTypes } from "src/utils/supplyTypeUtils";

export function buildThirdPartyAudienceDisplayFee (
    oldAudience: ThirdPartyAudienceDisplayFee,
    newFee: Fee
) {
    return {
        ...oldAudience,
        value: newFee.value,
        range: newFee.range,
        currency: newFee.currency,
        isLoading: newFee.isLoading,
        thirdPartyFees: newFee,
    }
}

export function setRangeForThirdPartyFees (feeValue) {
    return {
        min: {
            amount: feeValue
        },
        max: {
            amount: feeValue
        }
    }
}

export function calculateThirdPartyAudienceFee (
    audience: ThirdPartyAudienceDisplayFee,
    storedImpressionSupplyType: FeeTypes | null | undefined
): ThirdPartyAudienceDisplayFee {

    const fees = audience?.audience?.fees;

    if (!storedImpressionSupplyType || !fees || !fees.length) {
        return buildThirdPartyAudienceDisplayFee(audience, {value: "0", currency: "", isLoading: false, range: setRangeForThirdPartyFees("0")})
    }

    if (audience.audience.not && THIRD_PARTY_CATEGORIES.includes(audience.audience.category)) {
        return buildThirdPartyAudienceDisplayFee(audience, {value: "0", currency: audience?.currency, isLoading: false, range: setRangeForThirdPartyFees("0")})
    }

    const impressionTypeIndex = fees.findIndex(
        (fee: any) => fee.impressionSupplyType === storedImpressionSupplyType
    );

    if (impressionTypeIndex > -1) {
        return buildThirdPartyAudienceDisplayFee(audience, {
            value: (fees[impressionTypeIndex].amount / fees[impressionTypeIndex].scale).toString(),
            currency: fees[impressionTypeIndex]?.currency,
            isLoading: false,
            range: setRangeForThirdPartyFees((fees[impressionTypeIndex].amount / fees[impressionTypeIndex].scale).toString())
        })
        //@ts-ignore
    } else if (videoSubFeeTypes.includes(storedImpressionSupplyType as FeeTypes)) {
        const videoIndex = fees.findIndex(
            (fee: any) => fee.impressionSupplyType === FeeTypes.VIDEO
        );
        if (videoIndex > -1) {
            return buildThirdPartyAudienceDisplayFee(audience, {
            value: (fees[videoIndex].amount / fees[videoIndex].scale).toString(),
            currency: fees[videoIndex]?.currency,
            isLoading: false,
            range: setRangeForThirdPartyFees((fees[videoIndex].amount / fees[videoIndex].scale).toString())
            })
        }
    } else {
        const webImpressionTypeIndex = fees.findIndex(
            (fee: any) => fee.impressionSupplyType === FeeTypes.WEB
        );
        if (webImpressionTypeIndex > -1) {
            return buildThirdPartyAudienceDisplayFee(audience, {
                value: (fees[webImpressionTypeIndex].amount / fees[webImpressionTypeIndex].scale).toString(),
                currency: fees[webImpressionTypeIndex]?.currency,
                isLoading: false,
                range: setRangeForThirdPartyFees((fees[webImpressionTypeIndex].amount / fees[webImpressionTypeIndex].scale).toString())
            })
        }
    }

    console.log("repricing did not occur, no matching impression supply type found");

    return buildThirdPartyAudienceDisplayFee(audience, {value: "0", currency: fees[0]?.currency, isLoading: false, range: setRangeForThirdPartyFees("0")})
}