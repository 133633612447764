import { useCallback, useEffect, useMemo, useRef } from "react";
import { FeeStore } from "src/store/FeeStore";
import { MFEBootstrap } from "@mfe/state-management";
import { Observable } from "@mfe/state-management-interfaces";
import { MISSING_PAGE_STATE_ERROR_MSG } from "src/constants";
import { PricingApiHelper } from "src/utils/PricingApiHelper";
import { useStore } from "zustand";
import usePrevious from "src/hooks/internal/usePrevious";
import { flattenSelectedAudiences } from "src/utils/AudienceFeeUtil";
import debounce from "lodash-es/debounce";
import { invalidateAllFees } from "src/store/selectors/selectors";

export default function  usePageStateFeeManager(pageState:  MFEBootstrap.PageState<any>, feeStore: FeeStore) {
    const pricingApiHelper = useRef(PricingApiHelper.getInstance()).current;
    const selectedAudiences = useRef(flattenSelectedAudiences(pageState?.getCurrentPageState?.()));
    // TODO: use shallow?
    const audienceIndexSize: number = useStore(feeStore, (state) => state.audienceFeeIndex?.size) ?? 0;
    const previousAudienceIndexSize = usePrevious(audienceIndexSize);

    const requestPricing = useCallback(debounce((newPageState) => {
        const lineItemState = newPageState?.lineItemV1 || newPageState?.proposalV1;
        selectedAudiences.current = lineItemState?.segmentTargeting?.builder?.flatMap((group) => group.segments);
        pricingApiHelper.requestPricingForLineItemPageState(newPageState);
    }, 50), []);

    useEffect(() => {
        if (!pageState?.readablePageState) {
            throw new Error(MISSING_PAGE_STATE_ERROR_MSG);
        }

        const unsubscribable: Observable.Unsubscribable | undefined = pageState?.readablePageState?.subscribe({
            next: (newPageState) => {
                requestPricing(newPageState);
            },
        });

        return () => {
            if (unsubscribable) {
                unsubscribable.unsubscribe();
            }
        };
    }, []);

    // memoized function so that use effect is not needed
    useMemo(() => {
        // if the audience index size has increased, request pricing for the new audiences
        // Do not request pricing if this is the first render, any prices in the index should be priced already
        if (audienceIndexSize > 0 && previousAudienceIndexSize !== undefined) {
            const currentPageState = pageState?.getCurrentPageState?.();

            // Selected audiences should always be in the index, so if index is the same size as selected audiences
            // we know no new audiences have been added and requested and therefore do not need requested again
            if (currentPageState && (audienceIndexSize > (selectedAudiences.current?.length || 0))) {
                pricingApiHelper.requestPricingForLineItemPageState(currentPageState);
            }
        }
    }, [audienceIndexSize]);
}