import React, { useContext } from 'react';
import { Text } from '@amzn/storm-ui';

import { TranslationContext } from '../../../state/translationContext';
import {
  NEW_AUDIENCE,
  NEW_AUDIENCE_ALERT_MESSAGE,
} from '../../../constants/translations';

import { NewAudienceAlertStyled } from './NewAudienceAlert.styled';

const NewAudienceAlert = () => {
  const getTranslation = useContext(TranslationContext);
  const TRANS_NEW_AUDIENCE = getTranslation(NEW_AUDIENCE);
  const TRANS_NEW_AUDIENCE_ALERT_MESSAGE = getTranslation(
    NEW_AUDIENCE_ALERT_MESSAGE
  );

  return (
    <NewAudienceAlertStyled.Container>
      <Text type="h5"> {TRANS_NEW_AUDIENCE}</Text>
      <NewAudienceAlertStyled.AlertContainer>
        <NewAudienceAlertStyled.AlertIcon
          type="exclamation-triangle"
          size="xs"
          blockSize={true}
        />
        <NewAudienceAlertStyled.AlertText fontSize="small" textColor="tertiary">
          {TRANS_NEW_AUDIENCE_ALERT_MESSAGE}
        </NewAudienceAlertStyled.AlertText>
      </NewAudienceAlertStyled.AlertContainer>
    </NewAudienceAlertStyled.Container>
  );
};

export default NewAudienceAlert;
