export const ACTION_COL_ID = 'action';
export const NAME_COL_ID = 'audienceName';
export const CATEGORY_COL_ID = 'category';
export const OVERLAP_COL_ID = 'overlap';
export const AFFINITY_COL_ID = 'affinity';
export const REACH_COL_ID = 'forecastedDailyReach';
export const FILTER_DROPDOWN_VIRT_COL_ID = 'FilterCategoryDropdown';

export const MARGIN_BETWEEN_TABLES = 7;
export const MAX_SUGGESTIONS = 30;
// Height of the table when it is to be dispalyed on full page
export const FULL_TABLE_HEIGHT = 700;
// Height of the table when table only occupies half page
export const HALF_TABLE_HEIGHT = 350;
export const NOTIFICATION_TIMEOUT = 3000;

export const CATEGORY_FILTERS = [
  'CUSTOM_BUILT',
  'IN_MARKET',
  'LIFESTYLE',
  'LIFE_EVENT',
  'INTEREST',
  'THIRD_PARTY',
  'ADVERTISER_AUDIENCES',
  'LOOKALIKE',
];

export const CATEGORY_FILTERS_VALUE_LABEL = [
  { label: 'custom-built', value: 'CUSTOM_BUILT' },
  { label: 'in-market', value: 'IN_MARKET' },
  { label: 'lifestyle', value: 'LIFESTYLE' },
  { label: 'life-event', value: 'LIFE_EVENT' },
  { label: 'interest', value: 'INTEREST' },
  { label: 'third-party', value: 'THIRD_PARTY' },
  { label: 'advertiser-audiences', value: 'ADVERTISER_AUDIENCES' },
  { label: 'lookalike', value: 'LOOKALIKE' },
];

export interface requestBodyType {
  categoryFilter: Array<string>;
}
