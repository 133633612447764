
import {
    FormPageStateV1,
    LineItemV1,
} from '@amzn/d16g-state-management-interfaces';
import { Common, MFEState, Validation } from '@mfe/state-management-interfaces';

/**
 * Type of page (Line Item vs. Proposal)
 */
export enum PageType {
    LINE_ITEM,
    PROPOSAL,
    UNKNOWN,
}

/**
 * Contains the language selectors through locale
 */
interface LocalizationContext {
    /** The locale the user selected for language of preference. */
    locale: string;
    /** The default locale that is used if locale is not found. */
    defaultLocale: string;
}

/**
 * Page state model for the Line Item/Proposal Create/Edit pages.
 * Supports only a lineItemV1.
 */
export interface LineItemPageState extends MFEState.MFEComponentPageState {
    lineItemV1?: Common.DeepPartial<LineItemV1.LineItemModel>;
    lineItemValidationV1?: Validation.ValidationDeepPartial<LineItemV1.LineItemModel>;
    readonly context: Common.DeepPartial<LineItemV1.LineItemContext>;
    readonly formSubmitEvent: string;
    readonly formSubmitErrors: Common.DeepPartial<FormPageStateV1.ErrorResponse>;
    timezone: string;
    pageType?: PageType;
    isInitialized?: boolean;
    rodeoLocalizationContext?: LocalizationContext;
}

/**
 * Page state model for the Line Item/Proposal Create/Edit pages.
 * Supports both a lineItemV1 and a proposalV1.
 */
export interface LineItemProposalPageState extends LineItemPageState {
    proposalV1?: Common.DeepPartial<LineItemV1.LineItemModel>;
    proposalValidationV1?: Validation.ValidationDeepPartial<LineItemV1.LineItemModel>;
}