import murmurHash3 from "murmurhash3js";
import {PricingEngineDealType} from "@amzn/d16g-pricing-engine-api-type-script-client";

/**
 * Generates a 32-bit hash value from a given string using a simple
 * hash algorithm.
 *
 * @param {string} str - The input string to be hashed.
 * @param seed - seed for randomness
 * @returns {number} The 32-bit hash value of the input string.
 */
export function hashString(str, seed = 0x9747b28c) {
    const hash128 = murmurHash3.x86.hash128(str, seed);
    return `${hash128.toString(16).padStart(32, 0)}`;
}

/**
 * Converts a deal type string to the corresponding PricingEngineDealType.
 *
 * NOTE: This will not identify OPEN_AUCTION Deals, tbd if they are needed.
 *
 * @param dealType - The deal type string to be converted.
 * @returns The corresponding PricingEngineDealType if the input is valid, otherwise undefined.
 */
export function dealTypeToPengDealType(dealType: string): PricingEngineDealType | undefined {
    const dealTypeValue = PricingEngineDealType[dealType];

    if (dealTypeValue !== undefined) {
        return dealTypeValue;
    } else if (dealType === "PREFERRED") {
        return PricingEngineDealType.PREFERRED_DEAL;
    }

    return undefined;
}
