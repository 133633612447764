import React, { useEffect, useState } from 'react';
import { Icon, Text } from '@amzn/storm-ui';
import { Audience } from '../../../models';
import styled from 'styled-components';
import { calculateFee, getFeeValue } from '../../../utils/FeeCalculationUtil';
import { COMBINED_AUDIENCES_CATEGORY } from '../../../constants/combined-audiences';
import {
  CombinedAudienceClient,
  CustomElementSegment,
  useAudienceFee,
} from '@amzn/d16g-pricing-react-utils';
import { isWeblabActive, Weblabs } from '../../../utils/weblabUtil';
import { compareAndPrintForShadowMode } from '../../../utils/pricingUtil';

type FeeRendererProps = {
  /**
   * segment data for this row.
   */
  data: Audience;
  /**
   * List of feeSupplyType(s) that dictate which fee to use from a given audience segment
   */
  feeSupplyType: string[];
  /**
   *  User's Currency of preference
   */
  currencyOfPreference?: string;
  /**
   *  Conversion rate(multiplication factor) from base currency to the currency of preference.
   */
  fxConversionRate?: number;
};

const ZERO = 0;
const NULL_CURRENCY_TEXT = '--';

const FeeContainer = styled(Text)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

/**
 * @function
 * Given the feeSupplyType(s), returns the currency, and human-readable amount for a segment
 * @param {number[]} fees - list of fee amounts.
 * @param {string} currency - currency to show the fee amount in.
 */
export const formatAudienceFee = (
  fees: number[],
  currency: string | undefined
): string => {
  const allEqual = (arr: number[]) => arr.every(v => v === arr[0]);
  if (!currency) return NULL_CURRENCY_TEXT;
  const updatedFees = allEqual(fees) ? [fees[ZERO] || 0] : fees;
  const feeStrings = updatedFees.map(fee =>
    fee.toLocaleString(undefined, { style: 'currency', currency })
  );
  return feeStrings.join(' - ');
};

/**
 * Column with the formatted audience fees for a specific segment.
 */
export const FeeRenderer = ({
  data,
  feeSupplyType,
  currencyOfPreference,
  fxConversionRate,
}: FeeRendererProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [cpm, setCpm] = useState<number[]>([]);
  const [currency, setCurrency] = useState('');

  const { observedAudienceFee } = useAudienceFee(
    (data as unknown) as CustomElementSegment
  );

  useEffect(() => {
    if (!data) return;

    let isCancelled = false;
    let cancelFn: () => void;

    if (data.category === COMBINED_AUDIENCES_CATEGORY) {
      const {
        promise,
        cancel,
      } = CombinedAudienceClient.getInstance().getUnderlyingAudiences(
        data,
        false
      );

      promise
        .then(underlyingAudiences => {
          if (isCancelled) return;
          const fees = calculateFee(
            underlyingAudiences as Audience[],
            feeSupplyType
          );
          setCpm(fees.feeTotal);
          setCurrency(fees.currencyType);
          setIsLoading(false);
        })
        .catch(() => {});

      cancelFn = cancel;
    } else {
      const { cpm: newCpm, currency: newCurrency } = getFeeValue(
        data,
        feeSupplyType
      );
      setCpm(newCpm);
      setCurrency(newCurrency);
      setIsLoading(false);
    }

    return () => {
      if (data.category === COMBINED_AUDIENCES_CATEGORY) cancelFn();
      isCancelled = true;
    };
  }, [data, feeSupplyType]);

  if (!data) return null;

  const isCop: boolean = !!currencyOfPreference;
  const isfxRate: boolean = !!fxConversionRate;
  const shouldDisplayCop: boolean = isCop && isfxRate;

  let fxCpm: number[] = [];
  let legacyFeeWithCOP: any;
  if (fxConversionRate) {
    fxCpm = cpm.map(fee => fee * fxConversionRate);
    legacyFeeWithCOP = formatAudienceFee(fxCpm, currencyOfPreference);
  }

  const legacyAudienceFee = formatAudienceFee(cpm, currency);
  if (isWeblabActive(Weblabs.ADSP_PRICING_UI_REFACTOR))
    setTimeout(
      () =>
        compareAndPrintForShadowMode(
          data,
          legacyAudienceFee,
          observedAudienceFee,
          legacyFeeWithCOP
        ),
      200
    );

  const isUIRefactorWeblabOn = isWeblabActive(
    Weblabs.ADSP_PRICING_UI_REFACTOR,
    'T2'
  );
  const isAudienceFeeLoading = isUIRefactorWeblabOn
    ? observedAudienceFee?.isLoading
    : isLoading;
  const audienceFee = isUIRefactorWeblabOn
    ? observedAudienceFee?.displayValue
    : legacyAudienceFee;
  const copAudienceFee = isUIRefactorWeblabOn
    ? observedAudienceFee?.copDisplayValue
    : legacyFeeWithCOP;

  return (
    <FeeContainer data-testid="fee-renderer-component">
      {isAudienceFeeLoading ? (
        <Icon type={'spinner'} size="sm" style={{ opacity: '0.5' }} />
      ) : (
        <>
          {audienceFee}
          {shouldDisplayCop && (
            <Text
              data-testid="fee-renderer-cop-component"
              textColor="tertiary"
              fontSize="mini"
            >
              {`(${copAudienceFee})`}
            </Text>
          )}
        </>
      )}
    </FeeContainer>
  );
};
