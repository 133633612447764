import { Fee } from "src/model/Fee.model";
import { EMPTY_FEE } from "src/constants";

export interface TechnologyFeeProps {
    technologyFee: Fee | null | undefined,
}

export interface TechnologyFeeState extends TechnologyFeeProps {
    setTechnologyFee: (fee: Fee) => void,
    invalidateTechnologyFee: () => void,
    setTechnologyFeeLoadingState: (isLoading: boolean) => void
}

export const createTechnologyFeeSlice = (set): TechnologyFeeState => ({
    technologyFee: null,
    setTechnologyFee: (fee: Fee) => set(() => ({technologyFee: fee})),
    setTechnologyFeeLoadingState:  (isLoading: boolean) =>
        set((state) => ({technologyFee: {...state.technologyFee, isLoading}})),
    invalidateTechnologyFee: () => set(() => ({technologyFee: EMPTY_FEE})),
})