// @ts-nocheck
// eslint-disable-next-line no-shadow
export enum Weblabs {
  ADSP_PRICING_3P_FEE_CALCULATION = 'ADSP_PRICING_3P_FEE_CALCULATION_881151',
  D16G_RODEO_AUDIENCE_TARGETING_MATCH_TYPE_CHECKBOX = 'D16G_RODEO_AUDIENCE_TARGETING_MATCH_TYPE_CHECKBOX_986776',
  APM_VIDEO_PRICING_AUDIENCE_PICKER_DEAL_BASED_1P_AUDIENCES = 'APM_VIDEO_PRICING_AUDIENCE_PICKER_DEAL_BASED_1P_AUDIENCES_1025823',
  ADSP_PRICING_UI_REFACTOR = 'ADSP_PRICING_AUDIENCE_PICKER_REFACTOR_998528',
}

const excludedLineItems = ['AUDIO_GUARANTEED', 'OTT_VIDEO'];

/**
 * Check the active state of weblab whose state has been cached in the browser.
 * @param weblab - weblab to check
 * @param treatment - level of treatment weblab should be applied
 * @returns boolean representing the active state of the weblab.
 */

export function isWeblabActive(weblab: Weblabs, treatment = 'T1') {
  return (
    window.weblabTreatments && window.weblabTreatments[weblab] === treatment
  );
}
