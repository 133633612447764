import styled from 'styled-components';

export const FilterItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FilterItemContainer = styled.div`
  padding: 5px 2px;
`;
