// @ts-nocheck
import { useEffect, useState } from 'react';

export function useResizeObservable<T>(element: T, cb: any) {
  const [observer, setObserver] = useState<ResizeObserver>();

  useEffect(() => {
    const obs = new ResizeObserver(cb);
    setObserver(obs);
  }, [cb]);

  useEffect(() => {
    if (!observer) return;
    observer?.observe((element as unknown) as HTMLElement);
    return () => {
      if (observer) observer?.disconnect();
    };
  }, [observer, element]);
}
