import { useContext, useMemo } from 'react';
import {AudienceDisplayFee, CustomElementSegment, DisplayFee} from "src/model";
import { FeeContext } from "src/context/FeeContext";
import { useStore } from "zustand";
import { MISSING_FEE_STORE_CONTEXT } from "src/constants";
import { formatDisplayFee } from "src/utils/FeeUtil";

//TODO: Depricate this once we know we will not need it.
export default function useAudienceFees(audiences: CustomElementSegment[]) {
    const feeStore = useContext(FeeContext);

    if (!feeStore) {
        throw new Error(MISSING_FEE_STORE_CONTEXT);
    }

    const audienceFees: AudienceDisplayFee[] = useStore(feeStore, (state) => {
        if (!audiences || audiences.length === 0 || !state) {
            return [];
        }
        return audiences.map(audience => {
            const id = audience.audienceId || audience.canonicalId;
            return state.audienceFeeIndex.get(id);
        }).filter(fee => fee !== undefined) as AudienceDisplayFee[];
    })

    const observeAudiencesForPricing = useStore(feeStore, (state) => state.observeAudiencesForPricing);

    const observedAudienceFee: DisplayFee | undefined = useMemo(() => {
        if (audienceFees?.length > 0) {
            let audienceCalculatedFee = audienceFees?.reduce((accumulator, currentValue) => ({
                value: Math.max(Number(accumulator.range?.max?.amount), Number(currentValue.range?.max?.amount)).toFixed(2),
                currency: currentValue.currency,
                range: {
                    min: {
                        amount: Math.min(Number(accumulator.range?.min?.amount), Number(currentValue.range?.min?.amount)).toFixed(2),
                    },
                    max: {
                        amount: Math.max(Number(accumulator.range?.max?.amount), Number(currentValue.range?.max?.amount)).toFixed(2),
                    },
                },
                isLoading: accumulator.isLoading || currentValue.isLoading,
                audience: accumulator.audience  // not needed for displaying condensed audience fees
            }));
            return formatDisplayFee(audienceCalculatedFee);
        }
        return undefined;
    }, [audienceFees])

    return {
        observeAudiencesForPricing,
        observedAudienceFee
    };
}