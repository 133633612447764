import React, { Dispatch, useState } from 'react';
import { Icon, RadioButton, RadioGroup, Text } from '@amzn/storm-ui';
import { AudienceDataClient, FilterResponse } from '../../../../../api/Client';
import { FilterItemHeader, FilterItemContainer } from './FilterTreeItem.styled';

const ALL_SUBCATEGORIES_TEXT = 'All sub-categories';
const SUB_FILTERS_GROUP = 'sub-filters-group';
const LOADING_TEXT = 'Loading...';

type FilterTreeItemProps = {
  /**
   * The high-level filter associated with an item.
   */
  highLevel: string;
  /**
   * An instance of the Client.tsx client for making API calls.
   */
  audienceDataClient: AudienceDataClient;
  /**
   * Cached filters that have already been requested from the API
   */
  savedFilters: any;
  /**
   * Whether the filter's subCategory dropdown is open or closed.
   */
  isExpanded: boolean;
  /**
   * Method to cache a filter that was just requested from the API
   */
  setSavedFilters: Dispatch<any>;
  /**
   * method to set the current high-level filter as open.
   */
  setExpanded: (filter: string) => void;
  /**
   * Method to mark a filter as "selected"
   */
  setFilter: (filter: string) => void;
  /**
   * the selected filter that is being used to set the audiences available in the table.
   */
  loadedFilter: string;
  /**
   * Whether the filter is still waiting for data back from the API
   */
  currentlyLoading: boolean;
};

/**
 * a dropdown item, one of the high-level filters in the filterTree
 */
export const FilterTreeItem = ({
  highLevel,
  audienceDataClient,
  savedFilters,
  setSavedFilters,
  isExpanded,
  setExpanded,
  setFilter,
  loadedFilter,
  currentlyLoading,
}: FilterTreeItemProps) => {
  const [secondLevelCategories, setSecondLevelCategories] = useState<string[]>(
    []
  );
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('');
  const loadedHighLevel = loadedFilter.split('/')[0];
  const arrayToUse =
    highLevel === loadedHighLevel
      ? savedFilters[highLevel]
      : secondLevelCategories;

  const getSecondLevel = async () => {
    setExpanded(isExpanded ? '' : highLevel);
    if (!isExpanded) {
      setShowLoadingSpinner(true);
      // @ts-ignore
      if (!(highLevel in savedFilters)) {
        const response = await audienceDataClient.getFilterTaxonomy(highLevel);
        const options = response.map((cat: FilterResponse) => cat.category);
        setSecondLevelCategories(options);
        // @ts-ignore
        setSavedFilters({ ...savedFilters, [highLevel]: options });
      } else setSecondLevelCategories(savedFilters[highLevel]);

      setShowLoadingSpinner(false);
    } else setSecondLevelCategories([]);
  };

  const selectFilter = (value: string) => {
    setSelectedFilter(value);
    setFilter(value);
  };

  return (
    <FilterItemContainer data-testid={'filter-tree-item-component'}>
      <FilterItemHeader>
        <Text type={'h5'}>{highLevel}</Text>
        <Icon
          style={{ cursor: 'pointer' }}
          onClick={getSecondLevel}
          type={isExpanded ? 'angle-up' : 'angle-down'}
        />
      </FilterItemHeader>
      {showLoadingSpinner && <Text>{LOADING_TEXT}</Text>}
      {isExpanded && (
        <RadioGroup
          name={SUB_FILTERS_GROUP}
          selectedValue={selectedFilter || loadedFilter}
          onChange={selectFilter}
        >
          {isExpanded && !showLoadingSpinner && (
            <RadioButton
              id={highLevel}
              label={ALL_SUBCATEGORIES_TEXT}
              value={highLevel}
              disabled={currentlyLoading}
            />
          )}
          {arrayToUse.map((secondLevel: string) => (
            <RadioButton
              id={`${highLevel}/${secondLevel}`}
              label={secondLevel}
              value={`${highLevel}/${secondLevel}`}
              disabled={currentlyLoading}
            />
          ))}
        </RadioGroup>
      )}
    </FilterItemContainer>
  );
};

export default FilterTreeItem;
