import styled from 'styled-components';
import { defaultTheme, Text } from '@amzn/storm-ui';

export const NoSuggestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: ${defaultTheme.palette.black};
`;

export const NoSuggestionsText = styled(Text)`
  margin-bottom: 5px;
`;
