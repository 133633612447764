import { PricingApiContext, PricingApiData } from "src/model/ApiData.model";
import { PageState } from "@mfe/state-management/dist/MFEBootstrap";
import { LineItemPageState } from "src/model";
import {FeeProps} from "src/store/FeeStore";

/**
 * Builds the PricingApiData object from the given PageState and PricingApiContext.
 *
 * @param {PageState<LineItemPageState>} pageState - The current page state.
 * @param {PricingApiContext} pricingApiContext - The pricing API context.
 * @returns {PricingApiData} The constructed PricingApiData object.
 */
export const PricingApiDataBuilder = (pageState: PageState<LineItemPageState>, pricingApiContext: PricingApiContext): PricingApiData => {
    return {
        ...pricingApiContext,
        baseURL: pricingApiContext.baseUrl,
        entityId: pageState?.getCurrentPageState?.()?.context?.entity?.value,
        advertiserId: pageState?.getCurrentPageState?.()?.context?.advertiser?.value,
        clientId: 'amzn.advertising.789c1589c111c03008805682468deebf58e307ee806a62c8e04aa321c9cca32287fed673375af4ebb597d6fc0192630c90',
    }
}

/**
 * Builds the initial fee store properties for the pricing API.
 *
 * @param {Partial<FeeProps>} [initialStoreProps] - Optional partial object containing initial store properties.
 * @param {PricingApiContext} [pricingApiContext] - Optional pricing API context object.
 * @returns {Partial<FeeProps>} An object containing the initial fee store properties.
 */
export const InitialStorePropsBuilder = (initialStoreProps?: Partial<FeeProps>, pricingApiContext?: PricingApiContext) : Partial<FeeProps> => {
    return {
        currencyOfMarketplace: pricingApiContext?.currencyOfMarketplace,
        currencyOfPreference: pricingApiContext?.currencyOfPreference,
        fxConversionRate: pricingApiContext?.fxConversionRate,
        ...initialStoreProps,
    }
}