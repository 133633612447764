import React, { useEffect, useState, useContext } from 'react';
import { Text, Dropdown, Divider } from '@amzn/storm-ui';
import { DropdownContainer, FilterCategoryHeader } from './FilterTree.styled';
import { FilterTreeItem } from './FilterTreeItem/FilterTreeItem';
import { AudienceDataClient } from '../../../../api';
import {
  TranslationContext,
  capitalize,
} from '../../../../state/translationContext';
import {
  FILTER,
  AUDIENCE_CATEGORY,
  AMAZON,
  OTHER_SOURCES,
  COMBINED,
} from '../../../../constants/translations';
import { UnifiedDataTableApiContext } from '@amzn/unified-data-table-components/core';

type FilterTreeProps = {
  /**
   * An instance of the Client.tsx client for making API calls.
   */
  audienceDataClient: AudienceDataClient;
  /**
   * List of filters with no categoryPath that have been requested from the API
   */
  highLevelFilters: string[];
  /**
   * method to indicate the table needs to be refreshed with the filter data based on the selected filter
   */
  filterTable: (filter: string) => void;
  /**
   * Method to mark that a filter has been selected even after component unmount and remount
   */
  setLoadedFilter: (filter: string) => void;
  /**
   * the selected filter that is being used to set the audiences available in the table.
   */
  loadedFilter: string;
  /**
   * Whether the filter is still waiting for data back from the API
   */
  currentlyLoading: boolean;
};

const OtherSources = ['Lookalike', 'Third-party', 'Advertiser audiences'];
const Combined = ['Combined audiences'];

/**
 * Component to show a dropdown with all the filters available from the filter taxonomy API endpoint, and select them to filter the results from the table.
 */
export const FilterTree = ({
  audienceDataClient,
  highLevelFilters,
  filterTable,
  setLoadedFilter,
  loadedFilter,
  currentlyLoading,
}: FilterTreeProps) => {
  const [savedFilters, setSavedFilters] = useState<any>({});
  const [currentlyExpanded, setExpanded] = useState<string>('');
  const apiContext = useContext(UnifiedDataTableApiContext);
  const { api } = apiContext;

  const getTranslation = useContext(TranslationContext);
  const AmazonFilters = highLevelFilters.filter(
    filter => !OtherSources.includes(filter) && !Combined.includes(filter)
  );
  const OtherFilters = highLevelFilters.filter(filter =>
    OtherSources.includes(filter)
  );
  const CombinedFilters = highLevelFilters.filter(filter =>
    Combined.includes(filter)
  );

  useEffect(() => {
    setExpanded(loadedFilter.split('/')[0]);
  }, [loadedFilter]);

  const setFilter = (filter: string) => {
    api?.paginationGoToFirstPage();
    setLoadedFilter(filter);
    filterTable(filter);
  };

  const filterClosed = () => {
    const loadedHighLevel = loadedFilter.split('/')[0];
    if (loadedHighLevel !== currentlyExpanded) setExpanded('');
  };

  const displayFilterDropdowns = (filter: string) => (
    <FilterTreeItem
      isExpanded={currentlyExpanded === filter}
      setExpanded={setExpanded}
      setSavedFilters={setSavedFilters}
      setFilter={setFilter}
      loadedFilter={loadedFilter}
      savedFilters={savedFilters}
      audienceDataClient={audienceDataClient}
      highLevel={filter}
      currentlyLoading={currentlyLoading}
    />
  );

  return (
    <Dropdown
      data-testid={'filter-tree-component'}
      onOverrideLabel={() => capitalize(getTranslation(FILTER))}
      transparentButton={true}
      onClose={filterClosed}
    >
      <DropdownContainer>
        <Text type={'h4'}>{getTranslation(AUDIENCE_CATEGORY)}</Text>

        {!!AmazonFilters.length && (
          <>
            <Divider />
            <FilterCategoryHeader data-testid={'amazon-header'}>
              {getTranslation(AMAZON)}
            </FilterCategoryHeader>
            {AmazonFilters.map(filter => displayFilterDropdowns(filter))}
          </>
        )}
        {!!OtherFilters.length && (
          <>
            <Divider />
            <FilterCategoryHeader data-testid={'other-sources-header'}>
              {getTranslation(OTHER_SOURCES)}
            </FilterCategoryHeader>
            {OtherFilters.map(filter => displayFilterDropdowns(filter))}
          </>
        )}
        {!!CombinedFilters.length && (
          <>
            <Divider />
            <FilterCategoryHeader data-testid={'combined-header'}>
              {getTranslation(COMBINED)}
            </FilterCategoryHeader>
            {CombinedFilters.map(filter => displayFilterDropdowns(filter))}
          </>
        )}
      </DropdownContainer>
    </Dropdown>
  );
};

export default FilterTree;
