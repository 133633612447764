import { AudienceTargetingGroup } from 'src/MFE/lineItem/model/AudienceTargetingGroup';
import { TARGETED_AUDIENCE_IDS } from 'src/utils/MFEConstants';

export const findCommonElements = (list1: string[], list2: string[]) =>
  list1.filter((item) => list2.includes(item));

export const getTargetedCanonicalIds = (
  audienceSegments: AudienceTargetingGroup[]
): string[] => {
  let result: string[] = [];
  let intrasetOperator = '';
  for (const group of audienceSegments) {
    let allIds = group.segments
      .filter(({ not }) => !not)
      .map((seg) => seg.canonicalId);
    if (intrasetOperator !== 'AND') {
      allIds = group.segments.map((seg) => seg.canonicalId);
      result = [...new Set([...result, ...allIds])];
    } else result = findCommonElements(result, allIds);

    intrasetOperator = group.intrasetOperator;
  }
  return result;
};

export const isTargetedAudience = (targetedSegments: string[]): boolean =>
  targetedSegments.some((id) => TARGETED_AUDIENCE_IDS.includes(id));
