import * as React from 'react';
import { getTranslationConfig, TranslatorString } from './LocalizationConfig';
import { useBundle } from '@amzn/react-arb-tools';
import { MessageBundle } from '@amzn/arb-tools';

interface Props {
  translatorString?: TranslatorString;
}

const PLACEHOLDER = 'PLACEHOLDER_STRING';

export const LocalizedText: React.FC<Props> = ({ translatorString }) => {
  const [bundle, isBundleLoading] = useBundle('strings');
  if (!translatorString || isBundleLoading) return null;

  const translatedText = translateText(bundle, translatorString);
  return <span dangerouslySetInnerHTML={{ __html: translatedText }}></span>;
};

export function translateText(
  bundle: MessageBundle,
  translatorString?: TranslatorString,
  args?: [string, string]
): string {
  if (!translatorString) return PLACEHOLDER;

  const translationConfig = getTranslationConfig(translatorString);
  if (args) {
    const filledTemplate = {} as any;
    const templateString = args[0];
    const templateValue = args[1];
    filledTemplate[templateString] = templateValue;

    try {
      return bundle.formatMessage(translationConfig?.stringId!, filledTemplate);
    } catch {
      console.warn(
        `Could not find translation for translator '${translatorString}'`
      );
    }
  } else
    try {
      return bundle.getMessage(translationConfig?.stringId);
    } catch {
      console.warn(
        `Could not find translation for translator '${translatorString}'`
      );
    }
  return translationConfig.fallback;
}

export function translateArrayToDictionary(
  bundle: MessageBundle,
  translatorStrings: Array<TranslatorString | undefined>
): Map<TranslatorString, string> {
  return translatorStrings.reduce(
    (map: Map<TranslatorString, string>, translatorString) => {
      if (translatorString)
        map.set(translatorString, translateText(bundle, translatorString));

      return map;
    },
    new Map<TranslatorString, string>()
  );
}
