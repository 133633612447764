import { MISSING_FEE_STORE_CONTEXT } from "src/constants";
import { PricingApiHelper } from "src/utils/PricingApiHelper";
import { useStore } from "zustand";
import { DisplayFee, Fee } from "src/model";
import {
    PricingEngineContextualTactic
} from "@amzn/d16g-pricing-engine-api-type-script-client/dist-cjs/models/models_0";
import { getMaxFeeReducer } from "src/utils/AudienceFeeUtil";
import { formatDisplayFee } from "src/utils/FeeUtil";
import {useMemo} from "react";

interface ContextualFees {
    observedContextualProductFee: DisplayFee,
    observedContextualCategoryFee: DisplayFee,
    observedContextualRelatedProductFee: DisplayFee,
    observedContextualKeywordFee: DisplayFee,
    observedContextualEntertainmentFee: DisplayFee,
    observedTotalContextualFee: DisplayFee
}

export default function useContextualFees(selectedTactics?: PricingEngineContextualTactic[] | undefined): ContextualFees {
    const feeStore = PricingApiHelper?.getInstance()?.getFeeStore();

    if (!feeStore) {
        throw new Error(MISSING_FEE_STORE_CONTEXT);
    }

    const contextualProductFee = useStore(feeStore, (state) => state.contextualProductFee) as Fee;
    const contextualCategoryFee = useStore(feeStore, (state) => state.contextualCategoryFee) as Fee;
    const contextualRelatedProductFee = useStore(feeStore, (state) => state.contextualRelatedProductFee) as Fee;
    const contextualKeywordFee = useStore(feeStore, (state) => state.contextualKeywordFee) as Fee;
    const contextualEntertainmentFee = useStore(feeStore, (state) => state.contextualEntertainmentFee) as Fee;

    const totalContextualFee = useMemo(() => {

        const emptyTotalFee = {
            isLoading: false,
            value: "0",
            currency: feeStore.getState().currencyOfMarketplace,
            range: null,
        }

        return selectedTactics && selectedTactics.length > 0
            ? selectedTactics.map((tactic) => {
                switch(tactic) {
                    case PricingEngineContextualTactic.PRODUCT:
                        return contextualProductFee;
                    case PricingEngineContextualTactic.CATEGORY:
                        return contextualCategoryFee;
                    case PricingEngineContextualTactic.RELATED_PRODUCT:
                        return contextualRelatedProductFee;
                    case PricingEngineContextualTactic.KEYWORD:
                        return contextualKeywordFee;
                    case PricingEngineContextualTactic.ENTERTAINMENT:
                        return contextualEntertainmentFee;
                    default:
                        throw new Error(`Unknown tactic: ${tactic}`);
                }
            }).reduce(getMaxFeeReducer)
            : emptyTotalFee;

    }, [selectedTactics]);

    let observedTotalContextualFee = formatDisplayFee(totalContextualFee);

    return {
        observedContextualProductFee: formatDisplayFee(contextualProductFee),
        observedContextualCategoryFee: formatDisplayFee(contextualCategoryFee),
        observedContextualRelatedProductFee: formatDisplayFee(contextualRelatedProductFee),
        observedContextualKeywordFee: formatDisplayFee(contextualKeywordFee),
        observedContextualEntertainmentFee: formatDisplayFee(contextualEntertainmentFee),
        observedTotalContextualFee
    }
}