import { useEffect, useState } from 'react';

/**
 * Checks for whether a certain DOM element is rendered and visible on the window.
 * @params {HTMLElement} ref - the HTML element that is being compared against for visibility
 * @returns {boolean} isIntersecting - whether the ref is visible or not
 */
export const useVisibility = (ref: any) => {
  if (!ref) console.error('No ref was passed to the useVisibility hook');

  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    );

    if (ref) observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
};
