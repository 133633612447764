import { Common, MFEState } from '@mfe/state-management-interfaces';
import { LineItemV1 } from '@amzn/d16g-state-management-interfaces';

import {
  LineItemPageState,
  LineItemProposalPageState,
} from 'src/model/LineItemPageState';
import { ComponentIds } from '../utils/MFEConstants';

// Following D16GStateManagementInterfaces: https://tiny.amazon.com/1fbw36z44
const mockStandardDisplayLineItemModel: Common.DeepPartial<LineItemV1.LineItemModel> =
  {
    type: 'AAP_CPM',
    platformTargetingView: {
      // available for 'AAP_CPM'
      platformType: 'PLATFORM_MOBILE',
      mobileOS: 'OS_ALL',
    },
    targetingMode: 'BASIC',
    classificationDetails: {
      productCategories: [
        4026, 2038, 2205, 2206, 2207, 2208, 2005, 2010, 2029, 2361,
      ],
    },
    minBidCPMView: {
      currency: 'USD',
      millivalue: 0.1,
    },
    // third party fees
    automotiveFeeView: {
      currency: 'USD',
      millivalue: undefined,
    },
    bidCPMView: {
      currency: 'USD',
      millivalue: 1,
    },
    startDateView: undefined,
    endDateView: undefined,
    maxBidCPMView: {
      currency: 'USD',
      millivalue: 3,
    },
    targetingDetails: {
      automatedTactic: 'REMARKETING', // Remove to disable tactic audience identifier
      contentCategories: [],
      targetingUserLocation: 'US',
      preBidTargeting: {
        amazon: {
          viewability: {
            mrc: {
              targeting: 'GT70',
            },
            includeUnmeasurableImpressions: false,
          },
        },
        ias: {
          viewability: {
            groupM: {
              targeting: 'GT50',
            },
          },
          brandSafety: {
            avoidUnrated: true,
            antiTargeting: {
              unsafeContentCategories: [
                'alcohol_high',
                'gambling_high',
                'hate_speech_high',
                'illegal_downloads_high',
                'illegal_drugs_high',
                'offensive_language_high',
                'violence_high',
                'adult_moderate',
              ],
            },
          },
          invalidTraffic: {
            level: 'EXCLUDE_MODERATE_RISK',
            unknownContent: true,
          },
        },
        doubleVerify: {
          viewability: {
            mrc: {
              targeting: 'GT40',
            },
            brandExposure: {
              targeting: 'GT15',
            },
            includeUnmeasurableImpressions: true,
          },
          antiTargeting: {
            web: {
              contentCategories: [
                2001, 2003, 2004, 2005, 2007, 2016, 2017, 2018, 2019, 2020,
                3001, 3014, 3005, 3009, 3010, 3011, 3012, 3013, 3023, 3022,
                3002, 3015, 3003, 1006, 2006, 1007, 2,
              ],
            },
            app: {
              blockInsufficientData: false,
            },
            traffic: {
              identifiers: ['DEVICE_TYPE'],
              level: 'GTE50',
              blockInsufficientData: true,
              unknownContent: true,
            },
          },
          customSegments: {
            requiredSegments: ['51243412'],
          },
          customContextual: {
            requiredSegments: ['52243412'],
          },
        },
        oracle: {
          viewability: {
            mrc: {
              targeting: 'GT20',
            },
          },
          brandSafety: {
            targeting: {
              safe: false,
            },
            antiTargeting: {
              contentCategories: [
                'gv_arms',
                'gv_crime',
                'gv_death_injury',
                'gv_download',
                'gv_drugs',
                'gv_military',
                'gv_hatespeech',
                'gv_terrorism',
              ],
            },
          },
          customSegments: {
            requiredSegments: ['25500075'],
          },
          customPredicts: {
            requiredSegments: ['25900075'],
          },
          invalidTraffic: {
            level: 'EXCLUDE_HIGH_RISK',
          },
          standardPredicts: {
            segments: [
              20900075, 20900001, 20900007, 20900000, 20900008, 20900042,
            ],
          },
        },
        pixalate: {
          invalidTraffic: {
            ipAndUA: true,
            deviceAndOTT: true,
            appsAndDomains: true,
            defasedApps: true,
          },
        },
      },
    },
    segmentTargeting: {
      builder: null,
    },
    siteLanguageTargetingView: {
      enableSiteLanguageTargeting: true,
      language: 'en',
    },
    contextualTargetingIntendEnabled: false,
    // console fee
    aapDetails: {
      bidStrategy: 'DYNAMIC',
      marginRatio: undefined,
      inheritCampaignBidStrategy: false,
    },
    deliveryCapView: [
      {
        budget: undefined,
      },
    ],
    frequencyCapTypeView: 'SPECIFIED',
    frequencyCap: {
      maxCount: 0,
      periodCount: 0,
      periodType: 'DAYS',
    },
    dealSectionView: {
      deals: [
        {
          dealId: 'YWR4Ojc1NTA4MDM3NjIwOTExOTI2',
          dealName:
            'AccuenCA / VerticalScope PMP - AutoGuide.com Group - Luxury Auto_0011MZ_VS_PA_ADX',
          dealType: 'Private auction',
          displayPrice: 'C$8.00',
          exchange: 'AdX',
          filtered: true,
          price: 800000,
          priceType: 'floor rate',
        },
        {
          dealId: 'YWR4OjMxNzQ1MTEwODg0MjAyMTU1NA',
          dealName: 'CA_AccuenCanadawithAmazon_Amazon_LB_ROS',
          dealType: 'Private auction',
          displayPrice: 'C$2.25',
          exchange: 'AdX',
          filtered: true,
          price: 225000,
          priceType: 'floor rate',
        },
      ],
    },
    budget: {
      spendingPolicy: 'SPREAD_CATCHUP',
      catchupBoost: 0,
      impressionDeliveryBuffer: 10,
    },
    budgetView: {
      budgetOptimizationEnabled: true,
      totalBudgetView: {
        currency: 'USD',
        millivalue: 35,
      },
      thirdPartyDataFeeView: [
        {
          allocationType: 'ABSORB',
          dataFeeCpm: '0.00045',
          dataProvider: 1,
        },
      ],
    },
    dataFeeView: {
      currency: 'USD',
      millivalue: undefined,
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    externalSupplySourceView: {
      amazonOOSites: {
        // @ts-ignore
        'OO Site 1': true,
        'OO Site 2': false,
        'OO Site 3': true,
      },
      includeDirect: true,
      supplySources: [
        { id: '3p-source-1', selected: false },
        { id: '3p-source-2', selected: true },
        { id: '3p-source-3', selected: true },
      ],
      amazonOORestrictions: {
        options: {
          RESTRICT_AMAZON: true,
          RESTRICT_GATEWAY: false,
          RESTRICT_GATEWAY_BILLBOARD: false,
          RESTRICT_THANKYOU: false,
          RESTRICT_SELLER_CENTRAL: true,
        },
      },
    },
  };

const mockEntityID = 'ENTITY_ID';
const mockAdvertiserID = 'ADVERTISER_ID';
const mockCustomerID = 'CUSTOMER_ID';
const mockCampaignID = 'CAMPAIGN_ID';

export const mockPageStateForProposal: LineItemProposalPageState = {
  mfeComponentState: {
    [ComponentIds.AUDIENCE_TARGETING]: 'MOUNTED' as MFEState.ComponentState,
  },
  proposalV1: mockStandardDisplayLineItemModel,
  proposalValidationV1: {
    maxBidCPMView: {},
    bidCPMView: {},
    daypartTargetingView: {
      daypartHours: [],
    },
    budgetView: {
      totalBudgetView: {},
    },
    classificationDetails: {},
    budget: {},
  },
  context: {
    entity: {
      value: mockEntityID,
      type: 'ENTITYID',
    },
    advertiser: {
      value: mockAdvertiserID,
      type: 'CFID',
    },
    user: {
      value: mockCustomerID,
      type: 'OBFUSCATED_CUSTOMER_ID',
    },
    campaign: {
      value: mockCampaignID,
      type: 'CFID',
    },
  },
  timezone: 'UTC',
  formSubmitEvent: '',
  formSubmitErrors: null,
};

export const mockPageStateForLineItemStandardDisplay: LineItemPageState = {
  mfeComponentState: {
    [ComponentIds.AUDIENCE_TARGETING]: 'MOUNTED' as MFEState.ComponentState,
  },
  lineItemV1: mockStandardDisplayLineItemModel,
  lineItemValidationV1: {
    maxBidCPMView: {},
    bidCPMView: {},
    daypartTargetingView: {
      daypartHours: [],
    },
    budgetView: {
      totalBudgetView: {},
    },
    classificationDetails: {},
    budget: {},
  },
  context: {
    entity: {
      value: mockEntityID,
      type: 'ENTITYID',
    },
    advertiser: {
      value: mockAdvertiserID,
      type: 'CFID',
    },
    user: {
      value: mockCustomerID,
      type: 'OBFUSCATED_CUSTOMER_ID',
    },
    campaign: {
      value: mockCampaignID,
      type: 'CFID',
    },
  },
  timezone: 'UTC',
  formSubmitEvent: '',
  formSubmitErrors: null,
};
