import styled, { css } from 'styled-components';
import { Card, defaultTheme, Text, TextButton } from '@amzn/storm-ui';

const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 0 !important;
  ${props =>
    props.isSelected
      ? css`
          box-shadow: 0 0 0 2px ${defaultTheme.palette.blue[900]};
          background-color: ${defaultTheme.palette.blue[50]};
        `
      : css`
          cursor: pointer;
        `}
`;

const ActionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  margin-top: -10px;
  text-transform: capitalize;
`;

const MessageRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IntersetDivider = styled(Text)`
  border-radius: 6px;
  width: fit-content;
  padding: 0 5px;
  margin: 10px 0px !important;
  display: flex;
  align-items: center;
`;

const AndDivider = styled(IntersetDivider)`
  background-color: ${defaultTheme.palette.gray[100]};
`;

const RemoveButton = styled(TextButton)`
  margin-left: auto;
`;

const AlignedText = styled(Text)`
  padding-right: 5px;
`;

export const AudienceGroupStyled = {
  Container,
  ActionRow,
  MessageRow,
  IntersetDivider,
  AndDivider,
  RemoveButton,
  AlignedText

};


