import { MediaTypes } from '@amzn/d16g-pricing-shared-client-library/src/constants';
import {
  ApiData as PricingApiData,
  AudiencePriceRequesterV2,
  AudiencePricingContext,
  PricingResponseV2,
} from '@amzn/d16g-pricing-shared-client-library';
import ApiData from 'src/MFE/lineItem/model/api';
import {
  CustomElementSegment,
  getFeeValueInMilli,
  LegacyAudienceGroup,
} from '../utils/AudienceTargetingConverters';
import {
  Audience,
  AudienceSegment,
  Fee,
  FeeTypes,
} from '@amzn/d16g-audience-picker-component';
import { AdTypes, ImpressionSupplyTypes } from 'src/utils/ImpressionSupplyType';
import { DEFAULT_SCALE, flattenLegacySegments } from 'src/utils/PricingUtils';
import { THIRD_PARTY_CATEGORIES } from 'src/utils/MFEConstants';

/**
 * @class
 * A singleton class for requesting prices on existing audiences when the MFE first inits.
 * Due to the destruction/rebuild of the web component react state is not a safe place to store data.
 */
export class PricingHelper {
  pricingResponse: any;
  public requestCount: number = 0;
  private static _instance: PricingHelper;

  public static getInstance() {
    return this._instance || (this._instance = new this());
  }

  public static resetInstance() {
    this._instance = null;
  }

  /**
   * @function
   * Request pricing for audiences selected in the legacy format only once. If a request has been initiated already will return
   * the {segmentTargeting} argument.
   * Note: We want to prevent future hydration triggered by re-renders in the react component.
   * @param {LegacyAudienceGroup[]} segmentTargeting - Selected audiences in legacy format
   * @param {ApiData} apiData - api data used for calling the public api.
   * @return {Promise<LegacyAudienceGroup[]>} - returns a promise that contains the selected audiences with hydrated prices from PENG
   */
  public async requestPricingForLegacyAudiences(
    segmentTargeting: LegacyAudienceGroup[],
    apiData: ApiData,
    impressionSupplyType: ImpressionSupplyTypes
  ): Promise<LegacyAudienceGroup[]> {
    if (this.requestCount) return segmentTargeting;

    this.requestCount++;

    const flattenedSegments: Audience[] =
      flattenLegacySegments(segmentTargeting);

    // If no audience segments found return early
    if (!flattenedSegments.length) return segmentTargeting;

    const pricingContext: AudiencePricingContext = {
      mediaTypes: [
        MediaTypes.WEB_DISPLAY,
        MediaTypes.VIDEO,
        MediaTypes.OTT_VIDEO,
      ],
      advertiserId: apiData.advertiserId,
      orderId: apiData.orderId,
      entityId: apiData.entityId,
      country: apiData.country,
      lineItemId: apiData.lineItemId,
      audiences: flattenedSegments,
    };

    const pricingApiData: PricingApiData = {
      ...apiData,
      baseUrl: apiData.baseURL,
    } as PricingApiData;

    try {
      const pricingClient: AudiencePriceRequesterV2 =
        new AudiencePriceRequesterV2(pricingApiData);
      const data: PricingResponseV2 = await pricingClient.getPricingResponse(
        pricingContext
      );
      this.pricingResponse = this._hydratePrices(
        segmentTargeting,
        data,
        impressionSupplyType
      );
      return this.pricingResponse;
    } catch (e) {
      console.error(e);
      return segmentTargeting;
    }
  }

  /**
   * @function
   * Removes all pricing amounts from the passed audiences. Except third-party fees.
   * @param {LegacyAudienceGroup[]} segmentTargeting - Selected audiences in legacy format.
   * @return {LegacyAudienceGroup[]} - returns a copy of the passed audiences with removed pricing amounts.
   */
  public static nullAllLegacyAudienceFees(
    segmentTargeting: LegacyAudienceGroup[]
  ): LegacyAudienceGroup[] {
    try {
      return segmentTargeting.map((segmentGroup: LegacyAudienceGroup) => ({
        ...segmentGroup,
        segments: segmentGroup.segments.map(
          (segment: CustomElementSegment) => ({
            ...segment,
            fees: segment.fees.map((fee: any) => ({
              ...fee,
              amount: THIRD_PARTY_CATEGORIES.includes(segment.category)
                ? fee.amount
                : undefined,
            })),
          })
        ),
      }));
    } catch (e) {
      console.error('Error nulling all audience fees.', e);
      return segmentTargeting;
    }
  }

  /**
   * @function
   * hydrates prices onto the legacy audiences with pricing returned from PENG.
   * @param {LegacyAudienceGroup[]} segmentTargeting - Selected audiences in legacy format
   * @param {PricingResponseV2} data - response from the PENG pricing request.
   * @return {LegacyAudienceGroup[]} - returns a copy of the passed audiences with hydrated pricing amounts and currency.
   */
  _hydratePrices(
    segmentTargeting: LegacyAudienceGroup[],
    data: PricingResponseV2,
    impressionSupplyType: ImpressionSupplyTypes
  ): LegacyAudienceGroup[] {
    const { positiveSegmentsMap, negativeSegmentsMap } =
      this._buildAudiencePriceLookup(data);
    try {
      return segmentTargeting.map((segmentGroup: LegacyAudienceGroup) => ({
        ...segmentGroup,
        segments: segmentGroup.segments.map((segment: CustomElementSegment) => {
          const foundFees = segment.not
            ? negativeSegmentsMap?.[segment.canonicalId]
            : positiveSegmentsMap?.[segment.canonicalId];

          // let updatedFees = [];
          if (!foundFees)
            return {
              ...segment,
              feeMilliCents: getFeeValueInMilli(
                segment as unknown as Audience,
                impressionSupplyType
              ),
            };

          const updatedFees: Fee[] = Object.keys(foundFees).map(
            (feeType): Fee => {
              const fee = foundFees[feeType];
              return {
                amount: Number(fee.max.amount) * DEFAULT_SCALE,
                currency: fee.max.currency,
                scale: DEFAULT_SCALE,
                feeCalculationType: 'CPM',
                impressionSupplyType: feeType as FeeTypes,
              };
            }
          );

          return {
            ...segment,
            fees: updatedFees,
            feeMilliCents: getFeeValueInMilli(
              { ...segment, fees: updatedFees } as unknown as Audience,
              impressionSupplyType
            ),
          };
        }),
      }));
    } catch (e) {
      console.error('Error hydrating prices', e);
      return segmentTargeting;
    }
  }

  /**
   * @function
   * builds an audience lookup table used to hydrate prices onto the
   * @param {LegacyAudienceGroup[]} legacyAudienceGroups - Selected audiences in legacy format.
   * @return {LegacyAudienceGroup[]} - returns an array of API compliant audiences.
   */
  _buildAudiencePriceLookup(response: PricingResponseV2) {
    const negativeSegmentsMap = {};
    const positiveSegmentsMap = {};

    response?.pricingScenarios?.forEach((itemToPrice) => {
      try {
        if (itemToPrice?.audience?.negativeTarget) {
          negativeSegmentsMap[itemToPrice.audience.id] =
            negativeSegmentsMap[itemToPrice?.audience.id] || {};
          negativeSegmentsMap[itemToPrice.audience.id][
            this._mapMediaTypeToImpressionType(
              itemToPrice.mediaTypes[0],
              itemToPrice.supplySourceTypes
            )
          ] = itemToPrice.priceRange;
        } else if (!itemToPrice?.audience?.negativeTarget) {
          positiveSegmentsMap[itemToPrice.audience.id] =
            positiveSegmentsMap[itemToPrice.audience.id] || {};
          positiveSegmentsMap[itemToPrice.audience.id][
            this._mapMediaTypeToImpressionType(
              itemToPrice.mediaTypes[0],
              itemToPrice.supplySourceTypes
            )
          ] = itemToPrice.priceRange;
        }
      } catch (e) {
        console.error('Audience price lookup could not be built.', e);
      }
    });
    return { positiveSegmentsMap, negativeSegmentsMap };
  }

  /**
   * @function
   * Given the mediaType, return the Fee type that can be put into the fees array of a segment
   * @param {string} mediaType - The media type enum value
   * @param {string} supplyTypes - The supply type from peng
   */
  _mapMediaTypeToImpressionType(
    mediaType: string,
    supplyTypes?: string[]
  ): string {
    if (!mediaType) return;

    switch (mediaType) {
      case AdTypes.OTT_VIDEO:
        return FeeTypes.OTT_VIDEO;
      case MediaTypes.STREAMING_AUDIO:
        return FeeTypes.STREAMING_AUDIO;
      case MediaTypes.VIDEO:
        if (supplyTypes?.includes('STV')) return FeeTypes.STV;
        else if (supplyTypes?.includes('PVA')) return FeeTypes.PVA;
        return FeeTypes.VIDEO;
      default:
        return FeeTypes.WEB;
    }
  }
}

export default PricingHelper;
