import React, { useContext } from 'react';
import { HeaderLabelWithHelpTip } from '@amzn/unified-data-table-components/core';

import { TranslationContext } from '../../../state/translationContext';
import {
  AFFINITY_TOOLTIP,
  OVERLAP_PERCENTAGE,
  OVERLAP_TOOLTIP,
  REACH_TOOLTIP,
  SUGGESTION_HEADER_AFFINITY,
  SUGGESTION_HEADER_OVERLAP,
  SUGGESTION_HEADER_REACH,
} from '../../../constants/translations';
import { HelpTip, Text } from '@amzn/storm-ui';

interface HeaderWithTooltipProps {
  headerName: string;
  tooltipTitle: string;
  tooltip: string;
}

const HeaderWithTooltip = ({
  headerName,
  tooltipTitle,
  tooltip,
}: HeaderWithTooltipProps) => (
  <HeaderLabelWithHelpTip
    headerName={headerName}
    helpTipElement={
      <HelpTip>
        <Text fontSize="large">{tooltipTitle}</Text>
        {tooltip}
      </HelpTip>
    }
  />
);

export const OverlapHeader = () => {
  const getTranslation = useContext(TranslationContext);
  return (
    <HeaderWithTooltip
      headerName={getTranslation(SUGGESTION_HEADER_OVERLAP)}
      tooltipTitle={getTranslation(OVERLAP_PERCENTAGE)}
      tooltip={getTranslation(OVERLAP_TOOLTIP)}
    />
  );
};

export const ReachHeader = () => {
  const getTranslation = useContext(TranslationContext);
  return (
    <HeaderWithTooltip
      headerName={getTranslation(SUGGESTION_HEADER_REACH)}
      tooltipTitle={getTranslation(SUGGESTION_HEADER_REACH)}
      tooltip={getTranslation(REACH_TOOLTIP)}
    />
  );
};

export const AffinityHeader = () => {
  const getTranslation = useContext(TranslationContext);
  return (
    <HeaderWithTooltip
      headerName={getTranslation(SUGGESTION_HEADER_AFFINITY)}
      tooltipTitle={getTranslation(SUGGESTION_HEADER_AFFINITY)}
      tooltip={getTranslation(AFFINITY_TOOLTIP)}
    />
  );
};
