import React, { FunctionComponent } from 'react';
import { GroupType, TargetButtonProps } from '../Props';
import { TextButton, ThemeProvider } from '@amzn/storm-ui';
import { ButtonContainer } from './PredicateMode.styled';

enum ButtonText {
  Target = 'Target',
  Targeted = 'Targeted',
  Exclude = 'Exclude',
  Excluded = 'Excluded',
}

export const TargetButton: FunctionComponent<TargetButtonProps> = ({
  onClick,
  disabled,
  detailedTargetedNodes,
  detailedExcludedNodes,
  selectedGroup,
  node,
}) => {
  const isTargetedGroup = selectedGroup === GroupType.Targeted;
  const hasNode = isTargetedGroup
    ? detailedTargetedNodes.has(node.audienceId)
    : detailedExcludedNodes.has(node.audienceId);

  const buttonText = isTargetedGroup
    ? hasNode && disabled
      ? ButtonText.Targeted
      : ButtonText.Target
    : hasNode && disabled
    ? ButtonText.Excluded
    : ButtonText.Exclude;

  return (
    <ButtonContainer>
      <ThemeProvider>
        <TextButton onClick={onClick} disabled={disabled}>
          {buttonText}
        </TextButton>
      </ThemeProvider>
    </ButtonContainer>
  );
};
